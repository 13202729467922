import React from 'react';
import {
  IconCritereOffXL,
  IconCritereOnXL,
  IconDisponibilityOffXL,
  IconDisponibilityOnXL,
  IconDossierOffXL,
  IconDossierOnXL,
  IconEtablissementOffXL,
  IconEtablissementOnXL,
  IconInformationsOffXL,
  IconInformationsOnXL,
  IconReferentOffXL,
  IconReferentOnXL,
} from '../assets';
import appText from '../assets/intl/fr';
import {ProfileItem} from '../interfaces/ProfileItem';

export const profileStudentItems: ProfileItem[] = [
  {
    name: 'Mes informations',
    link: '/profile/student/informations',
    iconOff: <IconInformationsOffXL className="h-8.5 w-8.5" />,
    iconOn: <IconInformationsOnXL className="h-8.5 w-8.5" />,
  },
  {
    name: "Critères d'éligibilité",
    link: '/profile/student/eligibility-criteria',
    iconOff: <IconCritereOffXL className="h-8.5 w-8.5" />,
    iconOn: <IconCritereOnXL className="h-8.5 w-8.5" />,
  },
  {
    name: appText.profile.responsable.parent,
    link: '/profile/student/referent',
    iconOff: <IconReferentOffXL className="h-8.5 w-8.5" />,
    iconOn: <IconReferentOnXL className="h-8.5 w-8.5" />,
  },
  {
    name: 'Mon établissement',
    link: '/profile/student/etablisment',
    iconOff: <IconEtablissementOffXL className="h-8.5 w-8.5" />,
    iconOn: <IconEtablissementOnXL className="h-8.5 w-8.5" />,
  },
  {
    name: appText.menu.availability,
    link: '/profile/student/availability',
    iconOff: <IconDisponibilityOffXL className="h-8.5 w-8.5" />,
    iconOn: <IconDisponibilityOnXL className="h-8.5 w-8.5" />,
  },
  {
    name: 'Mes documents',
    link: '/profile/student/documents',
    iconOff: <IconDossierOffXL className="h-8.5 w-8.5" />,
    iconOn: <IconDossierOnXL className="h-8.5 w-8.5" />,
  },
];
