import React from 'react';
import {Provider} from 'react-redux';
import {Router, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {VolunteerRegistrationMainForm} from './pages/VolunteerRegistrationMainForm';
import {VolunteerRegistrationPage} from './pages/VolunteerRegistrationPage';
import {StudentRegistrationMainForm} from './pages/StudentRegistrationMainForm';
import {StudentRegistrationPage} from './pages/StudentRegistrationPage';
import {HomePage} from './pages/HomePage';
import {Login} from './pages/Login';
import {ForgotPassword} from './pages/ForgotPassword';
import {store} from './store/store';
import {MeetingInformationPage} from './pages/MeetingInformationPage';
import {DashboardPage} from './pages/DashboardPage';
import {Role} from './enums/Role';
import {StudentProfilePage} from './pages/StudentProfilePage';
import {VolunteerProfilePage} from './pages/VolunteerProfilePage';
import {ContactPage} from './pages/ContactPage';
import {ResetPassword} from './pages/ResetPassword';
import axios from 'axios';
import {volunteerInfoActions} from './store/reducers/volunteerInfoSlice';
import {userActions} from './store/reducers/userSlice';
import {studentInfoActions} from './store/reducers/studentInfoSlice';
import {PrivateRoute} from './PrivateRoute';
import {AuthenticatedPage} from './pages/AuthenticatedPage';
import {NotFoundPage} from './pages/NotFoundPage';
import {FormPage} from './pages/FormPage';

export const history = createBrowserHistory();

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      try {
        localStorage.clear();

        if (store.getState().volunteerInfo) {
          store.dispatch(volunteerInfoActions.resetVolunteerInfoState());
        }
        if (store.getState().user) {
          store.dispatch(userActions.resetUserState());
        }
        if (store.getState().studentInfo) {
          store.dispatch(studentInfoActions.resetStudentInfoState());
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    throw error;
  },
);

const App = (): React.ReactElement => (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/meeting/:meetingType">
          <MeetingInformationPage />
        </Route>
        <Route exact path="/meeting/volunteer/:meetingType">
          <MeetingInformationPage />
        </Route>
        <Route exact path="/registration/student">
          <StudentRegistrationPage />
        </Route>
        <Route exact path="/registration/volunteer">
          <VolunteerRegistrationPage />
        </Route>
        <Route exact path="/registration/student/form">
          <StudentRegistrationMainForm />
        </Route>
        <Route exact path="/registration/volunteer/form">
          <VolunteerRegistrationMainForm />
        </Route>
        <PrivateRoute exact path="/dashboard/student">
          <DashboardPage role={Role.STUDENT}></DashboardPage>
        </PrivateRoute>
        <PrivateRoute exact path="/dashboard/volunteer">
          <DashboardPage role={Role.VOLUNTEER}></DashboardPage>
        </PrivateRoute>
        <PrivateRoute exact path="/contact/volunteer">
          <ContactPage role={Role.VOLUNTEER}></ContactPage>
        </PrivateRoute>
        <PrivateRoute exact path="/compteRendu/volunteer">
          <FormPage role={Role.VOLUNTEER}></FormPage>
        </PrivateRoute>
        <PrivateRoute exact path="/dashboard/meeting/:meetingType">
          <AuthenticatedPage role={Role.VOLUNTEER}>
            <MeetingInformationPage />
          </AuthenticatedPage>
        </PrivateRoute>
        <PrivateRoute exact path="/contact/student">
          <ContactPage role={Role.STUDENT}></ContactPage>
        </PrivateRoute>
        <PrivateRoute exact path="/profile/student/:tabId">
          <StudentProfilePage />
        </PrivateRoute>
        <PrivateRoute exact path="/profile/volunteer/:tabId">
          <VolunteerProfilePage />
        </PrivateRoute>
        <Route path="*" exact={true} component={NotFoundPage} />
      </Switch>
    </Router>
  </Provider>
);

export default App;
