const appText = {
  menu: {
    availability: 'Mes disponibilités',
  },
  registration: {
    device_check: 'Matériel informatique',
  },
  registrationVolunteer: {
    reunion: `Avez-vous déjà participé à une réunion d'information`,
    availableTitle: 'Quelles sont vos disponibilités ?',
    accessToMyAccount: `Accéder à mon compte.`,
    cgv: `En remplissant ce formulaire, j'accepte la les conditions générales et la politique de confidentialité de HomeClasse. `,
    registrationValidatedTitle: `Votre inscription a bien été prise en compte.`,
    registrationValidatedDescription: `Vous pouvez consulter votre boîte mail et récupérer vos informations afin de vous connecter à votre compte.`,
    informations: {
      error: {
        firstNameEmpty: `Veuillez remplir le prénom`,
        lastNameEmpty: `Veuillez remplir le nom`,
        genderEmpty: `Veuillez remplir le sexe`,
        ageNotInInterval: `L'âge de tuteur doit être entre 16 et 85 ans.`,
        ageabovethemax: `L'âge est antérieur a 85 ans.`,
        ageEmpty: `Veuillez remplir l'âge`,
        ageNotValid: `Le format de la date de naissance n’est pas valide. Il doit respecter le format en chiffre de type XX/XX/XXXX`,
        phoneNotValid: `Le numéro de téléphone n'est pas valide.`,
        emailNotValid: `Veuillez remplir l'adresse e-mail`,
        situationEmpty: `Le champ situation n'est pas rempli.`,
        cguNotAccept: `Vous devez accepter les conditions générales.`,
        adressEmpty: `Veuillez remplir l'adresse.`,
        postCodeEmpty: `Veuillez remplir le code postal`,
        cityEmpty: `Le champ ville n'est pas rempli.`,
        reunionEmpty: `Veuillez remplir la participation à une réunion d'information`,
        referentProfessionalCategoryParentEmpty: `Veuillez remplir la catégorie socio-profesionnelle du parent`,
        enqueteMentoratEmpty: `Ce champ est obligatoire`,
      },
    },
  },
  registrationStudent: {
    eligibility: {
      title: `Conditions d'inscription`,
      description: `Ce dispositif est réservé aux élèves en difficulté scolaire et répondant à certains critères sociaux. Les documents justificatifs vous seront demandés.`,
      caf: 'Allocataire CAF *',
      StudentMotiver: 'Votre enfant est motivé et souhaite être accompagné 1h par semaine en ligne',
      noteQuestion: `Quelle est la moyenne générale de votre enfant ? Le dernier bulletin scolaire vous sera demandé.`,
      freeTutoring: `Vous avez besoin d'un soutien scolaire gratuit`,
      cameraAndAudioCheck: `Vous-avez un ordinateur avec une caméra et une connexion internet pour suivre des cours en ligne`,
    },
    informations: {
      title: `Informations élève`,
      description: `Vous souhaitez que votre enfant bénéficie gratuitement de soutien à distance ? Remplissez ce formulaire et nous reviendrons vers vous au plus vite. Un email vous sera envoyé pour vous présenter les prochaines étapes.`,
      error: {
        firstNameEmpty: `Veuillez remplir le prénom`,
        situationEmpty: `Le champ situation n'est pas rempli.`,
        lastNameEmpty: `Veuillez remplir le nom`,
        genderEmpty: `Veuillez remplir le sexe`,
        schoolNameEmpty: `Veuillez remplir le nom de l'établissement scolaire`,
        schoolNameIncomplete: `Merci d'écrire plus de 8 caractères sinon continuez à appuyer sur la touche "espace" pour que l'on vous affiche une liste des établissements`,
        postalCodeEmpty: `Veuillez remplir le code postal`,
        cityNameEmpty: `Veuillez remplir la ville`,
        studentClassNbEmpty: `Veuillez remplir la classe`,
        subClassNameEmpty: `Veuillez choisir une spécialité`,
        ageNotInInterval: `L'âge de l'élève doit être entre 6 et 22 ans.`,
        ageEmpty: `Veuillez remplir l'âge`,
        ageNotValid: `Le format de la date de naissance n’est pas valide. Il doit respecter le format en chiffre de type XX/XX/XXXX`,
        phoneNotValid: `Veuillez remplir le téléphone`,
        incompletePhoneNum: `*Exemple: 0766666666`,
        emailNotValid: `Veuillez remplir l'adresse e-mail`,
        emailStudentNotValid: `Veuillez remplir l'adresse e-mail de l'élève ou parent`,
        cguNotAccept: `Veuillez accepter les conditions générales de confidentialité de Homeclasse`,
        studentNotAcceptOffer: `Vous devez accepter les offres de nos partenaires`,
        HasScolarShipEmpty: `Le champ enfant boursier n'est pas rempli.`,
        hasCAFEmpty: `Le champ allocataire CAF n'est pas rempli.`,
        hasSocialMinimaBenefitEmpty: `Le champ bénéficiaire des minimas sociaux n'est pas rempli.`,
        isFollowHomeClasseFullYearEmpty: `Ce champ n'est pas rempli.`,
        gradePointAverageEmpty: `Le champ moyenne générale n'est pas rempli.`,
        champsEmpty: `Ce champ est obligatoire`,
        knownHomeClasseByEmpty: `Veuillez choisir un élément dans la liste`,
        enqueteMentoratEmpty: `Ce champ est obligatoire`,
      },
    },
    referent: {
      title: `Responsable de l'élève`,
    },
    accessToMyAccount: `Accéder à mon compte`,
    cgv: `En remplissant ce formulaire, j'accepte les conditions générales et la politique de confidentialité de HomeClasse. `,
    registrationValidatedTitle: `Votre inscription a bien été prise en compte.`,
    registrationValidatedDescription: `Vous pouvez consulter votre boîte mail et récupérer vos informations afin de vous connecter à votre compte.`,
  },
  profile: {
    availability: {
      title: 'Disponibilités',
      student: {
        description1: `Le tutorat est un rendez-vous en ligne d'1h par semaine tout au long de l'année scolaire. 
        Indiquez nous plusieurs créneaux des disponibilités de l'élèves de 8h à 20h. Ils nous serviront à lui trouver un tuteur.`,
        description2: `Vous devez être disponible sur le même créneau horaire d'une heure chaque semaine.`,
        hasMateriel: `L'élève dispose de matériel informatique complet (PC, micro, casque, connexion internet)`,
        disponibiliteSubTitle: `Vous souhaitez accompagner un ou plusieurs élèves (1h hebdomadaire par élève) ?`,
        disponibiliteQuestion: `Quelles sont vos disponibilités ?`,
      },
      volunteer: {
        description: `Attention, le tutorat se déroule le plus souvent en dehors des heures de cours des élèves. Merci de choisir un ou plusieurs créneaux de 15 à 20 heures les soirs de semaine.`,
        description1: `Votre tutorat sera un rendez-vous en visio avec votre élève pendant 1h par semaine tout au long de l'année scolaire.`,
        description2: `Vous pouvez indiquer plusieurs disponibilités dans des créneaux de 8h à 20h. Ils nous serviront à trouver votre élève.`,
      },
    },
    responsable: {
      parent: 'Parent',
    },
    documents: {
      criminelRecord: 'Extrait de casier judiciaire b3',
      otherDocument: 'Autres documents',
      bulletinNote: 'Bulletin(s) de notes',
      eligibilityJustificatif: `Justificatifs d'éligibilité`,
      bourseAndCaf: `Notification de bourses, attestation quotient familial CAF`,
    },
  },
  resetPassword: {
    passwordUpdated: `Votre mot de passe a été mis à jour.`,
  },
  volunteerDashboard: {
    welcomeMessage: {
      title: `Bienvenue sur ton Espace HomeClasse`,
      sentence1: `Afin de pouvoir valider ton dossier de canditature, nous avons besoin que tu complètes ton dossier :`,
      sentence2: ` - Valide tes informations `,
      sentence3: ` - et télécharge directement les documents demandés.`,
      sentence4: ` Si tu n'as pas encore participé à une réunion d'information par visio, prends un rendez vous.`,
    },
  },
  studentDashboard: {
    notEligibleTexte: `Votre enfant ne semble pas réunir toutes les conditions qui lui permettent d'être accompagné par HomeClasse`,
    engagement: {
      sentence1: `Avoir un ordinateur avec une caméra et une connexion internet pour suivre des cours en ligne`,
      sentence2: `Être motivé et souhaiter être accompagné 1h par semaine en ligne`,
      sentence3: `Avoir besoin d'un soutien scolaire gratuit`,
    },
    welcomeMessage: {
      title: `Bienvenue sur ton Espace HomeClasse`,
      sentence1: `Afin de pouvoir profiter de l'offre HomeClasse, nous avons besoin que tu complètes ton dossier :  `,
      sentence2: ` - Valide tes informations `,
      sentence3: ` - et télécharge directement les documents demandés.`,
      sentence4MeetingInformation: ` Si tu n'as pas encore participé à une réunion d'information par visio, prends un rendez vous.`,
    },
  },
  emailInput: {
    error: "L'adresse email n'est pas valide. Doit respecter le format username@server.domain",
  },
  phoneInput: {
    error: "Le numéro téléphone n'est pas valide. Doit respecter le format 0X XX XX XX XX",
  },
};

export default appText;
