import React, {ReactNode} from 'react';
import {Role} from '../enums/Role';
import {Link} from 'react-router-dom';
import {useMediaQuery} from '../hooks/Hook';

export const Homelink = (): JSX.Element => {
  const isabove500 = useMediaQuery('(max-width: 500px)');
  const role = localStorage.getItem('role');

  return (
    <div style={styles.container(isabove500)}>
      <Link
        className="flex flex-row items-center justify-center text-mainPurple"
        to={role === Role.STUDENT ? '/dashboard/student/' : '/dashboard/volunteer/'}>
        <p className="text-mainPurple text-base font-semibold pr-2">retour à Mon espace HomeClasse</p>
      </Link>
    </div>
  );
};

const styles = {
  container: (isabove: any) => ({
    margin: isabove ? '1rem 0' : '0',
    display: isabove ? 'block' : 'none',
  }),
};
