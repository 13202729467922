import React from 'react';
import {Spinner} from '../components/Spinner';
import {Role} from '../enums/Role';
import {useAppSelector} from '../store/hooks';
import {studentPendingSelector} from '../store/reducers/studentInfoSlice';
import {volunteerPendingSelector} from '../store/reducers/volunteerInfoSlice';
import {AuthenticatedPage} from './AuthenticatedPage';
import {DashboardStudent} from './DashboardStudent';
import {DashboardVolunteer} from './DashboardVolunteer';

const renderDashboardByRole = (role: Role, isLoading: boolean): JSX.Element => {
  if (isLoading) {
    return <Spinner className="mx-auto" />;
  }

  return role === Role.STUDENT ? <DashboardStudent /> : <DashboardVolunteer />;
};

export interface DashboardPageProps {
  role: Role;
}

export const DashboardPage = ({role}: DashboardPageProps): JSX.Element => {
  const isLoadingGetStudentProfile = useAppSelector(studentPendingSelector)['getStudentProfile'] || false;
  const isLoadingGetVolunteerProfile = useAppSelector(volunteerPendingSelector)['getVolunteerProfile'] || false;
  const isLoading = role === Role.STUDENT ? isLoadingGetStudentProfile : isLoadingGetVolunteerProfile;

  return <AuthenticatedPage role={role}>{renderDashboardByRole(role, isLoading)}</AuthenticatedPage>;
};
