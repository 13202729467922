import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {cloneDeep} from 'lodash';
import {Role} from '../../enums/Role';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  getStudentProfile,
  studentDocumentsSelector,
  studentErrorSelector,
  studentIdSelector,
  studentInfoActions,
  studentPendingSelector,
  studentStatusSelector,
  studentSuccessSelector,
  uploadStudentDocument,
} from '../../store/reducers/studentInfoSlice';
import {
  volunteerDocumentsSelector,
  volunteerIdSelector,
  volunteerInfoActions,
  volunteerSuccessSelector,
  uploadVolunteerDocument,
  volunteerPendingSelector,
  volunteerErrorSelector,
  volunteerStatusSelector,
  getVolunteerProfile,
} from '../../store/reducers/volunteerInfoSlice';
import {Button} from '../Button';
import {IconAdd, IconDelete} from '../../assets';
import '../../styles/DocumentForm.scss';
import {AirtableFileResponse} from '../../interfaces/api/AirtableFileResponse';
import {Spinner} from '../Spinner';
import {Link} from 'react-router-dom';
import {StudentDocumentType} from '../../enums/StudentDocumentType';
import {VolunteerDocumentType} from '../../enums/VolunteerDocumentType';
import {formatFileName} from '../../utils/utils';
import appText from '../../assets/intl/fr';
import {Homelink} from '../Homelink';

export interface DocumentFormProps {
  role: Role;
}

const renderFile = (file: AirtableFileResponse, onClickDelete: () => void): JSX.Element => (
  <div className="mb-4 mr-4 w-27.5 flex flex-col justify-center break-all">
    <Link
      className="mb-2 p-2 h-40 flex flex-col justify-center items-center bg-greyLight rounded-4px cursor-pointer"
      {...(file.url ? {to: {pathname: file.url}, target: '_blank', download: true} : {to: '#'})}>
      <div
        className="h-6 w-6 flex items-center justify-center bg-clDeleteError rounded-full"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClickDelete();
        }}>
        <IconDelete className="icon-delete" />
      </div>
    </Link>
    <p>{file.filename}</p>
  </div>
);

export const DocumentForm = ({role}: DocumentFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const studentId = useAppSelector(studentIdSelector);
  const volunteerId = useAppSelector(volunteerIdSelector);
  const studentDocuments = useAppSelector(studentDocumentsSelector);
  const volunteerDocuments = useAppSelector(volunteerDocumentsSelector);
  const volunteerStatus = useAppSelector(volunteerStatusSelector);
  const studentStatus = useAppSelector(studentStatusSelector);
  const isStudent = role === Role.STUDENT;

  const [documentsFormData, setDocumentsFormData] = useState<Record<string, FormData[]>>(
    isStudent
      ? {
          gradeBooks: [],
          eligibilityProof: [],
          otherFiles: [],
        }
      : {
          criminalRecords: [],
          otherFiles: [],
        },
  );

  const uploadApiName = isStudent ? 'uploadStudentDocument' : 'uploadVolunteerDocument';
  const getProfileApiName = isStudent ? 'getStudentProfile' : 'getVolunteerProfile';
  const isSuccessUpload: boolean =
    useAppSelector(isStudent ? studentSuccessSelector : volunteerSuccessSelector)[uploadApiName] || false;
  const isSuccessGetProfile: boolean =
    useAppSelector(isStudent ? studentSuccessSelector : volunteerSuccessSelector)[getProfileApiName] || false;
  const isLoading: boolean =
    useAppSelector(isStudent ? studentPendingSelector : volunteerPendingSelector)[uploadApiName] || false;
  const errorMsg: string =
    useAppSelector(isStudent ? studentErrorSelector : volunteerErrorSelector)[uploadApiName] || '';

  useEffect(() => {
    if (isStudent) {
      if (!isSuccessUpload) {
        dispatch(studentInfoActions.resetRequestApiState());
      } else if (!isSuccessGetProfile) {
        dispatch(getStudentProfile());
      }
    } else {
      if (!isSuccessUpload) {
        dispatch(volunteerInfoActions.resetRequestApiState());
      } else if (!isSuccessGetProfile) {
        dispatch(getVolunteerProfile());
      }
    }
  }, [dispatch, isStudent, isSuccessUpload, isSuccessGetProfile]);

  const onClickDeleteByRole = (idx: number, name: string) => () => {
    const newDocuments = cloneDeep(isStudent ? studentDocuments : volunteerDocuments);
    newDocuments[name].splice(idx, 1);

    if (isStudent) {
      dispatch(studentInfoActions.saveStudentDocuments(newDocuments));
      dispatch(studentInfoActions.resetRequestApiState());
    } else {
      dispatch(volunteerInfoActions.saveVolunteerDocuments(newDocuments));
      dispatch(volunteerInfoActions.resetRequestApiState());
    }
  };

  const renderDocumentsByRole = (name: string): JSX.Element => (
    <>
      {isStudent
        ? studentDocuments[name] &&
          studentDocuments[name].map((file, idx) => (
            <div key={idx}>{renderFile(file, onClickDeleteByRole(idx, name))}</div>
          ))
        : volunteerDocuments[name] &&
          volunteerDocuments[name].map((file, idx) => (
            <div key={idx}>{renderFile(file, onClickDeleteByRole(idx, name))}</div>
          ))}
    </>
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const documentType = event.target.name;
      const arrayFiles = Array.from(event.target.files);
      const newAirtableFile: AirtableFileResponse[] = arrayFiles.map((file) => ({
        id: '',
        url: '',
        filename: file.name,
        size: file.size,
        type: file.type,
        thumbnails: {
          small: {
            url: '',
            width: 0,
            height: 0,
          },
          large: {
            url: '',
            width: 0,
            height: 0,
          },
        },
      }));
      // con
      const formDataList: FormData[] = arrayFiles.map((file) => {
        const formData = new FormData();
        const fileName = formatFileName(file.name);
        formData.append('file', file, fileName);

        return formData;
      });

      setDocumentsFormData({
        ...documentsFormData,
        [documentType]: [...documentsFormData[documentType], ...formDataList],
      });

      if (isStudent) {
        const newStudentDocuments = {
          ...studentDocuments,
          [documentType]: [...studentDocuments[documentType], ...newAirtableFile],
        };

        dispatch(studentInfoActions.saveStudentDocuments(newStudentDocuments));
        dispatch(studentInfoActions.resetRequestApiState());
      } else {
        const newVolunteerDocuments = {
          ...volunteerDocuments,
          [documentType]: [...volunteerDocuments[documentType], ...newAirtableFile],
        };

        dispatch(volunteerInfoActions.saveVolunteerDocuments(newVolunteerDocuments));
        dispatch(volunteerInfoActions.resetRequestApiState());
      }
    }
  };

  const [err, seterr] = useState('');
  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    if (canContinue()) {
      if (isStudent) {
        dispatch(
          uploadStudentDocument({documentsFormData, studentDocuments, studentId, currentStudentStatus: studentStatus}),
        );
      } else {
        dispatch(
          uploadVolunteerDocument({
            documentsFormData,
            volunteerDocuments,
            volunteerId,
            currentVolunteerStatus: volunteerStatus,
          }),
        );
      }
    }
  };

  const canContinue = (): boolean => {
    if (role == Role.VOLUNTEER && !volunteerDocuments['criminalRecords'].length) {
      seterr('Vous devez remplir les documents');
      return false;
    }
    if (
      role == Role.STUDENT &&
      (!studentDocuments['eligibilityProof'].length || !studentDocuments['gradeBooks'].length)
    ) {
      seterr('Vous devez remplir les documents');
      return false;
    }
    seterr('');
    return true;
  };

  const btnColor = errorMsg.length > 0 ? 'clDeleteError' : isSuccessUpload ? 'clValidate' : 'mainOrange';
  const btnTitle = isSuccessUpload ? 'Transmis !' : 'Transmettre';

  return (
    <form className="flex-1 h-full w-85.75 lg:w-131 mx-auto flex flex-col items-center" onSubmit={handleSubmit}>
      <div className="w-full p-8 mb-21.75 bg-clWhite rounded-8px shadow-formRectangle">
        <h2 className="mb-2 text-3xl text-mainPurple font-bold">MES DOCUMENTS</h2>
        <p className="mb-7 text-2xl text-greyMedium font-bold">
          Ces documents sont nécessaires pour valider votre compte.
        </p>
        <div className="mb-6 flex flex-col">
          <label className="mb-4 text-base text-greyMedium font-bold">
            {isStudent ? appText.profile.documents.bulletinNote : appText.profile.documents.criminelRecord}
            <span className="mandatory-star">*</span>
          </label>
          <div className="flex flex-row flex-wrap">
            {renderDocumentsByRole(
              isStudent ? StudentDocumentType.GRADE_BOOKS : VolunteerDocumentType.CRIMINAL_RECORDS,
            )}
            <label
              className="mb-2 h-40 w-27.5 flex justify-center items-center bg-greyLight rounded-4px cursor-pointer"
              htmlFor={isStudent ? StudentDocumentType.GRADE_BOOKS : VolunteerDocumentType.CRIMINAL_RECORDS}>
              <IconAdd />
            </label>
            <input
              className="hidden"
              type="file"
              multiple
              accept=".pdf,.jpg,.png"
              id={isStudent ? StudentDocumentType.GRADE_BOOKS : VolunteerDocumentType.CRIMINAL_RECORDS}
              name={isStudent ? StudentDocumentType.GRADE_BOOKS : VolunteerDocumentType.CRIMINAL_RECORDS}
              onChange={handleChange}
            />
          </div>
        </div>
        {isStudent && (
          <div className="mb-6 flex flex-col">
            <label className="mb-1 text-base text-greyMedium font-bold">
              {appText.profile.documents.eligibilityJustificatif}
              <span className="mandatory-star">*</span>
            </label>
            <p className="mb-2 text-sm text-greyMedium italic">{appText.profile.documents.bourseAndCaf}</p>
            <div className="flex flex-row flex-wrap">
              {renderDocumentsByRole(StudentDocumentType.ELIGIBILITY_PROOF)}
              <label
                className="mb-2 h-40 w-27.5 flex justify-center items-center bg-greyLight rounded-4px cursor-pointer"
                htmlFor={StudentDocumentType.ELIGIBILITY_PROOF}>
                <IconAdd />
              </label>
              <input
                className="hidden"
                type="file"
                multiple
                accept=".pdf,.jpg,.png"
                id={StudentDocumentType.ELIGIBILITY_PROOF}
                name={StudentDocumentType.ELIGIBILITY_PROOF}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
        <div className="mb-6 flex flex-col">
          <label className="mb-4 text-base text-greyMedium font-bold">Autres documents</label>
          <div className="flex flex-row flex-wrap">
            {renderDocumentsByRole(isStudent ? StudentDocumentType.OTHER_FILES : VolunteerDocumentType.OTHER_FILES)}
            <label
              className="mb-2 h-40 w-27.5 flex justify-center items-center bg-greyLight rounded-4px cursor-pointer"
              htmlFor={isStudent ? StudentDocumentType.OTHER_FILES : VolunteerDocumentType.OTHER_FILES}>
              <IconAdd />
            </label>
            <input
              className="hidden"
              type="file"
              multiple
              accept=".pdf,.jpg,.png"
              id={isStudent ? StudentDocumentType.OTHER_FILES : VolunteerDocumentType.OTHER_FILES}
              name={isStudent ? StudentDocumentType.OTHER_FILES : VolunteerDocumentType.OTHER_FILES}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {errorMsg && <div className="mb-4 text-clStateError">{errorMsg}</div>}
      {err && <div className="mb-4 text-clStateError">{err}</div>}
      {!isLoading && (
        <>
          <Button className="w-85.75 lg:w-115 mt-auto" color={btnColor} type="submit">
            {btnTitle}
          </Button>
          <Homelink />
        </>
      )}
      {isLoading && <Spinner className="mt-auto" />}
    </form>
  );
};
