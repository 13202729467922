import React from 'react';
import icValidation from '../../assets/icons/icons-commons-xl-ic-check.svg';
import {Button} from '../Button';

export interface ValidationStepProps {
  title: string;
  description: string;
  description1?: string;
  textButton: string;
  onBtnClick: () => void;
}

export const ValidationStep = ({
  title,
  description,
  description1,
  textButton,
  onBtnClick,
}: ValidationStepProps): JSX.Element => {
  return (
    <div className="flex-1 h-full w-85.75 lg:w-131.25 mx-auto ">
      <div className="flex flex-col items-center w-full p-4 bg-clWhite rounded-8px shadow-formRectangle">
        <div className="mb-4 mt-7">
          <img alt="Validated" src={icValidation} className="w-11.5 h-11.5" />
        </div>
        <div className="text-greyMedium text-2xl normal-height text-center font-bold mb-4 mt-4 px-28">{title}</div>
        <div className="text-greyMedium text-xl normal-height normal-font text-center my-8">{description}</div>
        {description1 ? (
          <div className="text-greyMedium text-xl normal-height normal-font text-center my-8">{description1}</div>
        ) : (
          <></>
        )}

        <Button className="w-full mb-4" color="mainOrange" onClick={onBtnClick}>
          {textButton}
        </Button>
      </div>
    </div>
  );
};
