import React, {InputHTMLAttributes} from 'react';

export interface InputTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  displayName: string;
}

export const TextAreaInput = ({
  id,
  name,
  displayName,
  value,
  placeholder,
  min,
  max,
  className,
  required,
  onChange,
}: InputTextAreaProps): JSX.Element => {
  const defaultClassName = `mt-1 h-11.5 px-4 py-3 border-2 border-greyLight outline-none`;
  const combinedClassName = className ? `${defaultClassName} ${className}` : defaultClassName;

  return (
    <div className="flex flex-col relative">
      <label className="text-base text-greyMedium font-medium" htmlFor={id}>
        {displayName}
      </label>
      <textarea
        className={combinedClassName}
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        {...(min ? {min: min} : {})}
        {...(max ? {max: max} : {})}
        {...(required ? {required: required} : {})}
        onChange={onChange}
      />
    </div>
  );
};
