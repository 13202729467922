import React from 'react';
import {RouteProps, Route, Redirect} from 'react-router-dom';

const isAuthenticated = () => {
  return Boolean(localStorage.getItem('access_token'));
};

export const PrivateRoute = ({children, ...rest}: RouteProps): JSX.Element => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() === true ? children : <Redirect to={{pathname: '/login', state: {from: props.location}}} />
      }
    />
  );
};
