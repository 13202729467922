import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {Button} from '../Button';
import {TextInput} from '../TextInput';
import {RadioButton} from '../RadioButton';
import {OptionYesNo} from '../../enums/OptionYesNo';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  eligibilityCriteriaSelector,
  studentErrorSelector,
  studentIdSelector,
  studentInfoActions,
  studentPendingSelector,
  studentSuccessSelector,
  updateStudentInfo,
} from '../../store/reducers/studentInfoSlice';
import {EligibilityCriteria} from '../../interfaces/EligibilityCriteria';
import {StudentRegistrationFirstFormModal} from './StudentRegistrationFirstFormModal';
import {Spinner} from '../Spinner';
import appText from '../../assets/intl/fr';
import {Homelink} from '../Homelink';
import ReactModal from 'react-modal';
import {FaCheckCircle} from 'react-icons/fa';
import {Role} from '../../enums/Role';
import {userActions} from '../../store/reducers/userSlice';
import {volunteerInfoActions} from '../../store/reducers/volunteerInfoSlice';

export interface StudentEligibilityCriteriaFormProps {
  isRegistrationForm?: boolean;
  goToNextStep?: () => void;
  access_token?: string | null;
  role?: string | null;
}

const renderTitle = (isRegistrationForm?: boolean): JSX.Element => {
  if (isRegistrationForm) {
    return (
      <>
        <h2 className="pb-5 pt-3 text-2xl text-greyMedium font-bold">
          {appText.registrationStudent.eligibility.title}
        </h2>
        {/* <p className="mb-8 lg:mb-4 text-base text-greyMedium">{appText.registrationStudent.eligibility.description}</p> */}
      </>
    );
  } else {
    return (
      <>
        <h2 className="mb-8 text-3xl text-mainPurple font-bold">Conditions d&apos;inscription</h2>
      </>
    );
  }
};

export const StudentEligibilityCriteriaForm = ({
  goToNextStep,
  isRegistrationForm,
  access_token,
  role,
}: StudentEligibilityCriteriaFormProps): JSX.Element => {
  const [showModalNotEligible, setShowModalNotEligible] = useState(false);
  const dispatch = useAppDispatch();
  const studentId: string = useAppSelector(studentIdSelector);
  const eligibilityCriteria = useAppSelector(eligibilityCriteriaSelector);
  const errorMsg: string =
    useAppSelector(studentErrorSelector)[isRegistrationForm ? 'createStudent' : 'updateStudentInfo'] || '';
  const isLoading: boolean =
    useAppSelector(studentPendingSelector)[isRegistrationForm ? 'createStudent' : 'updateStudentInfo'] || false;
  const isSuccess: boolean =
    useAppSelector(studentSuccessSelector)[isRegistrationForm ? 'createStudent' : 'updateStudentInfo'] || false;

  useEffect(() => {
    dispatch(studentInfoActions.resetRequestApiState());
  }, [dispatch]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEligibilityCriteria: EligibilityCriteria = {
      ...eligibilityCriteria,
      [event.target.name]: event.target.value,
    };

    dispatch(studentInfoActions.saveEligibilityCriteria(newEligibilityCriteria));
  };

  const [close, setclose] = useState(false);
  const [tosub, settosub] = useState(false);
  const handleClick = () => {
    setclose(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem('currentStep') === null) {
      if (access_token) setclose(true);
    }
  }, [access_token]);

  const sendData = () => {
    if (isNotEmpty()) {
      settosub(false);
      setShowModalNotEligible(false);
      if (isRegistrationForm) {
        if (!isEligible()) {
          setShowModalNotEligible(true);
        } else if (goToNextStep) {
          goToNextStep();
        }
      } else if (studentId) {
        if (!isEligible()) setShowModalNotEligible(true);
        else {
          dispatch(
            updateStudentInfo({
              id: studentId,
              request: {
                requirementForReunion: eligibilityCriteria.requirementForReunion,
                motivatedChild: eligibilityCriteria.motivatedChild,
                tutoring: eligibilityCriteria.tutoring,
                // scholarship: eligibilityCriteria.requirementForReunion,
                // familyQuotaCAF: eligibilityCriteria.motivatedChild,
                // averageNote: eligibilityCriteria.tutoring,
                // minimumSocialBenefits: eligibilityCriteria.hasSocialMinimaBenefit,
                // homeClasseEngage: eligibilityCriteria.isFollowHomeClasseFullYear === OptionYesNo.OUI,
              },
            }),
          );
        }
      }
    } else settosub(true);
    setclose(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(studentInfoActions.resetStudentInfoState());
    dispatch(userActions.resetUserState());
    window.location.href = '/registration/student/form';
  };

  const handleSubmit = (event: FormEvent): void => {
    seterrorApeare(true);
    event.preventDefault();
    sendData();
    // setShowModalNotEligible(false);
    // if (isRegistrationForm) {
    //   if (isNotEligible()) {
    //     setShowModalNotEligible(true);
    //   } else if (goToNextStep) {
    //     goToNextStep();
    //   }
    // } else if (studentId) {
    //   dispatch(
    //     updateStudentInfo({
    //       id: studentId,
    //       request: {
    //         scholarship: eligibilityCriteria.hasScholarship,
    //         familyQuotaCAF: eligibilityCriteria.hasCAF,
    //         averageNote: eligibilityCriteria.gradePointAverage,
    //         minimumSocialBenefits: eligibilityCriteria.hasSocialMinimaBenefit,
    //         homeClasseEngage: eligibilityCriteria.isFollowHomeClasseFullYear === OptionYesNo.OUI,
    //       },
    //     }),
    //   );
    // }
  };

  const isEligible = (): boolean => {
    // const gradePointAverage = parseFloat(eligibilityCriteria.gradePointAverage) || -1;
    // return (
    //   (eligibilityCriteria.hasCAF === OptionYesNo.NON &&
    //     eligibilityCriteria.hasScholarship === OptionYesNo.NON &&
    //     eligibilityCriteria.hasSocialMinimaBenefit === OptionYesNo.NON) ||
    //   eligibilityCriteria.isFollowHomeClasseFullYear === OptionYesNo.NON ||
    //   gradePointAverage < 5 ||
    //   gradePointAverage > 12
    // );
    return (
      eligibilityCriteria.requirementForReunion === OptionYesNo.OUI &&
      eligibilityCriteria.motivatedChild === OptionYesNo.OUI &&
      eligibilityCriteria.tutoring === OptionYesNo.OUI
    );
  };

  const isNotEmpty = (): boolean => {
    return (
      (eligibilityCriteria.requirementForReunion === OptionYesNo.OUI ||
        eligibilityCriteria.requirementForReunion === OptionYesNo.NON) &&
      (eligibilityCriteria.motivatedChild === OptionYesNo.OUI ||
        eligibilityCriteria.motivatedChild === OptionYesNo.NON) &&
      (eligibilityCriteria.tutoring === OptionYesNo.OUI || eligibilityCriteria.tutoring === OptionYesNo.NON)
    );
  };

  const btnColor = !isRegistrationForm && isSuccess ? 'clValidate' : 'mainOrange';
  const btnTitle = isRegistrationForm ? 'Suivant' : !isRegistrationForm && isSuccess ? 'Enregistré !' : 'Enregistrer';
  const [errorApeare, seterrorApeare] = useState<boolean>(false);

  return (
    <>
      <form className="flex-1 h-full w-85.75 lg:w-131 mx-auto flex flex-col items-center" onSubmit={handleSubmit}>
        <div className="w-full p-8 mb-21.75 bg-clWhite rounded-8px shadow-formRectangle">
          {renderTitle(isRegistrationForm)}
          <div onChange={handleChange} className="pb-2">
            <label className="text-base text-greyMedium font-medium">
              {appText.registrationStudent.eligibility.cameraAndAudioCheck}
              <span className="mandatory-star">*</span>
            </label>
            <div className="mt-2 mb-4 flex flex-row items-center">
              <RadioButton
                name="requirementForReunion"
                value={OptionYesNo.OUI}
                checked={eligibilityCriteria.requirementForReunion === OptionYesNo.OUI}
                // required
              />
              <RadioButton
                className="ml-4"
                name="requirementForReunion"
                value={OptionYesNo.NON}
                checked={eligibilityCriteria.requirementForReunion === OptionYesNo.NON}
                // required
              />
            </div>
            {!eligibilityCriteria.requirementForReunion && errorApeare && (
              <div className="mb-4 text-clStateError">{appText.registrationStudent.informations.error.champsEmpty}</div>
            )}
          </div>
          <div onChange={handleChange} className="pb-2">
            <label className="text-base text-greyMedium font-medium">
              {appText.registrationStudent.eligibility.StudentMotiver}
              <span className="mandatory-star">*</span>
            </label>
            <div className="mt-2 mb-4 flex flex-row items-center">
              <RadioButton
                name="motivatedChild"
                value={OptionYesNo.OUI}
                checked={eligibilityCriteria.motivatedChild === OptionYesNo.OUI}
                // required
              />
              <RadioButton
                className="ml-4"
                name="motivatedChild"
                value={OptionYesNo.NON}
                checked={eligibilityCriteria.motivatedChild === OptionYesNo.NON}
                // required
              />
            </div>
            {!eligibilityCriteria.motivatedChild && errorApeare && (
              <div className="mb-4 text-clStateError">{appText.registrationStudent.informations.error.champsEmpty}</div>
            )}
          </div>
          <div onChange={handleChange} className="pb-2">
            <label className="text-base text-greyMedium font-medium">
              {appText.registrationStudent.eligibility.freeTutoring}
              <span className="mandatory-star">*</span>
            </label>
            <div className="mt-2 mb-4 flex flex-row items-center">
              <RadioButton
                name="tutoring"
                value={OptionYesNo.OUI}
                checked={eligibilityCriteria.tutoring === OptionYesNo.OUI}
                // required
              />
              <RadioButton
                className="ml-4"
                name="tutoring"
                value={OptionYesNo.NON}
                checked={eligibilityCriteria.tutoring === OptionYesNo.NON}
                // required
              />
            </div>
            {!eligibilityCriteria.tutoring && errorApeare && (
              <div className="mb-4 text-clStateError">{appText.registrationStudent.informations.error.champsEmpty}</div>
            )}
          </div>
        </div>
        {errorMsg && <div className="mb-4 text-clStateError">{errorMsg}</div>}
        {tosub && (
          <div className="mb-4 text-center text-clStateError mt-auto">
            Veuillez remplir tous les champs obligatoires
          </div>
        )}
        {!isLoading && (
          <>
            <Button className="w-85.75 lg:w-115 mt-auto" color={btnColor} type="submit">
              {btnTitle}
            </Button>
          </>
        )}
        {!isRegistrationForm && <Homelink />}
        {isLoading && <Spinner className="mt-auto" />}
      </form>
      {showModalNotEligible && (
        <StudentRegistrationFirstFormModal
          onModifyResponseClick={() => setShowModalNotEligible(false)}></StudentRegistrationFirstFormModal>
      )}
      <ReactModal
        className="absolute w-85.75 md:w-auto top-40 inset-x-center-modal-mobile md:inset-x-1/3 p-8 bg-clWhite rounded-8px border-none overflow-auto outline-none"
        overlayClassName="fixed inset-0 bg-greyDark bg-opacity-50 overflow-auto z-50"
        isOpen={close}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}>
        <div>
          <div className="flex flex-col text-greyMedium">
            <h3 className="mb-4 text-2xl text-greyMedium font-bold">Attention</h3>
            <h4 className="text-base text-greyMedium">Vous êtes déjà connecté.</h4>
            <h4 className="text-base text-greyMedium">
              Si vous souhaitez créer un nouveau compte veuillez sélectionner <br /> « Déconnexion ».
            </h4>
            <h4 className="mb-4 text-base text-greyMedium">
              Si vous souhaitez mettre à jour vos informations veuillez sélectionner <br /> « Continuer ».
            </h4>
            <div className="flex justify-around relative" style={{width: '100%'}}>
              <button
                className="close bg-mainOrange rounded w-85.75 lg:w-115 h-10"
                style={{padding: '0.7rem 0', color: 'white', marginRight: '5px'}}
                onClick={handleClick}>
                Continuer
              </button>
              <button
                className="close bg-mainOrange rounded w-85.75 lg:w-115 h-10"
                style={{padding: '0.7rem 0', color: 'white', marginLeft: '5px'}}
                onClick={handleLogout}>
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};
