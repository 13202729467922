import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {IconDelete, IconUserBoy, IconUserGirl, IconUserTeacherMan, IconUserTeacherWoman} from '../assets';
import {Button} from '../components/Button';
import {TextInput} from '../components/TextInput';
import {TextAreaInput} from '../components/TextAreaInput';
import {Role} from '../enums/Role';
import {Gender} from '../enums/Gender';
import {AuthenticatedPage} from './AuthenticatedPage';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {contactHomeClass, userActions, userSelector} from '../store/reducers/userSlice';
import {Spinner} from '../components/Spinner';
import {formatFileName} from '../utils/utils';
import {StudentInformation} from '../interfaces/StudentInformation';
import {VolunteerInformation} from '../interfaces/VolunteerInformation';
import {
  getStudentProfile,
  studentInformationSelector,
  studentProfileLoadedSelector,
} from '../store/reducers/studentInfoSlice';
import {
  getVolunteerMeetingInfo,
  getVolunteerProfile,
  volunteerInfoSelector,
  volunteerProfileInfoLoadedSelector,
} from '../store/reducers/volunteerInfoSlice';
import {Link} from 'react-router-dom';
import ReCaptchaV2 from 'react-google-recaptcha';

export interface ContactPageProps {
  role: Role;
}

export const ContactPage = ({role}: ContactPageProps): JSX.Element => {
  const [files, setFiles] = useState<File[]>([]);
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const dispatch = useAppDispatch();
  const userState = useAppSelector(userSelector);
  const isSuccess = userState.isSuccess;
  const studentInformation: StudentInformation = useAppSelector(studentInformationSelector);
  const volunteerInformation: VolunteerInformation = useAppSelector(volunteerInfoSelector);

  const firstName = role === Role.STUDENT ? studentInformation.studentFirstName : volunteerInformation.firstName;
  const lastName = role === Role.STUDENT ? studentInformation.studentLastName : volunteerInformation.lastName;
  const gender = role === Role.STUDENT ? studentInformation.studentGender : volunteerInformation.gender;
  const renderIconByGender =
    gender === Gender.HOMME ? (
      role === Role.STUDENT ? (
        <IconUserBoy className="icon-student" />
      ) : (
        <IconUserTeacherMan className="icon-teacher" />
      )
    ) : role === Role.STUDENT ? (
      <IconUserGirl className="icon-student" />
    ) : (
      <IconUserTeacherWoman className="icon-teacher" />
    );

  useEffect(() => {
    dispatch(userActions.resetUserState());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      setMessage('');
      setTitle('');
      setFiles([]);
    }
  }, [isSuccess, setMessage, setTitle, setFiles]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (form) {
      dispatch(userActions.resetUserState());
      dispatch(
        contactHomeClass({
          title,
          message,
          files: files.map((file) => {
            const formData = new FormData();
            const fileName = formatFileName(file.name);
            formData.append('file', file, fileName);
            return formData;
          }),
        }),
      );
    } else {
      setErrorre(true);
      setrecaptchaErr('Merci de cocher la case ci-dessus');
    }
  };
  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const lFiles = e.target.files;
    const arrayFiles = Array.from(lFiles || []);
    setFiles([...files, ...arrayFiles]);
  };

  const [form, setForm] = useState(false);
  const [Errorre, setErrorre] = useState(false);
  const [recaptchaErr, setrecaptchaErr] = useState('');

  const handleToken = (token: any) => {
    setForm(true);
    setErrorre(false);
    setrecaptchaErr('');
  };

  return (
    <AuthenticatedPage role={role}>
      <div className="flex-1 h-full w-85.75 lg:w-131 mx-auto pb-4 flex flex-row items-center">
        <div className="flex items-center h-15 w-15">{renderIconByGender}</div>
        <div className="flex flex-col justify-center">
          <p className="sm:block ml-2.5 text-mainPurple" style={{fontSize: '1.5rem', lineHeight: '3rem'}}>
            {firstName}
            <span className="ml-0.75 font-bold">{lastName}</span>
          </p>
          <p className="sm:block ml-2.5 text-greyMedium font-bold">{role === Role.STUDENT ? 'Élève' : 'Tuteur'}</p>
        </div>
      </div>
      <form className="flex-1 h-full w-85.75 lg:w-131 mx-auto pb-4 flex flex-col items-center" onSubmit={handleSubmit}>
        <div className="w-full p-8 bg-clWhite rounded-8px shadow-formRectangle">
          <div className="flex flex-col">
            <span className="text-3xl font-bold	text-mainPurple">Contacter HomeClasse</span>
            <div className="mt-8">
              <TextInput
                type="text"
                min="1"
                max="20"
                name="titre"
                displayName="Titre"
                id="titre"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                required
                className="rounded border-solid border-2 border-greyLight"
              />
            </div>
            <div className="mt-6">
              <TextAreaInput
                min="1"
                max="20"
                name="message"
                displayName="Message"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                id="message"
                required
                className="h-37.5 rounded border-solid border-2 border-greyLight"
              />
            </div>
            <div className="flex flex-col relative mt-6 mb-8">
              <div className="text-base text-greyMedium font-medium">Pièce jointe</div>
              <div className="flex flex-col mt-4">
                <label className="border-3 p-2 bg-greyLight w-36">
                  <input type="file" id="file" name="file" className="hidden" onChange={handleFileSelected} />
                  <span className="text-greyMedium">{'Choisir un fichier'}</span>
                </label>
                <div className="flex flex-col">
                  {files.map((file, ids) => {
                    return (
                      <>
                        <div className="flex flex-row">
                          <p className="text-greyMedium" style={{fontSize: 'small', padding: '0.2rem 0 0 0'}}>
                            {file.name}
                          </p>
                          <IconDelete
                            key={ids}
                            onClick={() => setFiles(files.filter((item) => item.name !== file.name))}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <ReCaptchaV2
            onChange={handleToken}
            // onExpired={handleExpire}
            // size="invisible"
            sitekey={process.env.REACT_APP_SITE_KEY ? process.env.REACT_APP_SITE_KEY : '123'}
          />
        </div>
        {Errorre && <div className="mt-4 text-clStateError">{recaptchaErr}</div>}
        <div className="flex flex-col mt-auto">
          {userState.errorMsg && <div className="mt-2 text-center text-clStateError">{userState.errorMsg}</div>}
          <Button className="w-85.75 lg:w-115 mt-8" color={isSuccess ? 'clValidate' : 'mainOrange'} type="submit">
            {isSuccess ? 'Message envoyé' : 'Envoyer'}
          </Button>

          {userState.isPending && <Spinner className="m-auto" />}
        </div>
      </form>
    </AuthenticatedPage>
  );
};
