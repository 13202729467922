import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {IconDelete, IconUserTeacherMan, IconUserTeacherWoman} from '../assets';
import {Button} from '../components/Button';
import {TextInput} from '../components/TextInput';
import {StarRating} from '../components/StarRating';
import {TextAreaInput} from '../components/TextAreaInput';
import {Role} from '../enums/Role';
import {Gender} from '../enums/Gender';
import {AuthenticatedPage} from './AuthenticatedPage';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {compteRenduForm, userActions, userSelector} from '../store/reducers/userSlice';
import {Spinner} from '../components/Spinner';
import {formatFileName} from '../utils/utils';
import {StudentInformation} from '../interfaces/StudentInformation';
import {VolunteerInformation} from '../interfaces/VolunteerInformation';
import {StudentAssigned} from '../interfaces/api/StudentAssigned';
import {studentInformationSelector} from '../store/reducers/studentInfoSlice';
import {volunteerInfoSelector, volunteerStudentsAssignedSelector} from '../store/reducers/volunteerInfoSlice';
import {SelectInput} from '../components/SelectInput';
import {SelectInputKeyValue} from '../components/SelectInputKeyValue';

export interface FormPageProps {
  role: Role;
}

export const FormPage = ({role}: FormPageProps): JSX.Element => {
  const [files, setFiles] = useState<File[]>([]);
  const [date, setDate] = useState<string>(Date());
  const [message, setMessage] = useState<string>('');
  const [implicationEleve, setImplicationEleve] = useState<string>('');
  const [noteDeLaSeance, setNoteDeLaSeance] = useState<string>('');
  const [students, setStudents] = useState<StudentAssigned[]>([]);
  const [studentSelected, setSelectedStudent] = useState<string>('');
  const dispatch = useAppDispatch();
  const userState = useAppSelector(userSelector);
  const isSuccess = userState.isSuccess;
  const studentInformation: StudentInformation = useAppSelector(studentInformationSelector);
  const volunteerInformation: VolunteerInformation = useAppSelector(volunteerInfoSelector);
  const volunteerStudentsAssigned = useAppSelector(volunteerStudentsAssignedSelector);

  const firstName = volunteerInformation.firstName;
  const lastName = volunteerInformation.lastName;
  const gender = volunteerInformation.gender;
  const renderIconByGender =
    gender === Gender.HOMME ? (
      <IconUserTeacherMan className="icon-teacher" />
    ) : (
      <IconUserTeacherWoman className="icon-teacher" />
    );

  useEffect(() => {
    dispatch(userActions.resetUserState());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      setMessage('');
      setFiles([]);
    }
  }, [isSuccess, setMessage, setFiles]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(userActions.resetUserState());
    dispatch(
      compteRenduForm({
        date,
        message,
        student: studentSelected,
        implicationEleve: implicationEleve,
        noteDeLaSeance: noteDeLaSeance,
        files: files.map((file) => {
          const formData = new FormData();
          const fileName = formatFileName(file.name);
          formData.append('file', file, fileName);
          return formData;
        }),
      }),
    );
  };
  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const lFiles = e.target.files;
    const arrayFiles = Array.from(lFiles || []);
    setFiles([...files, ...arrayFiles]);
  };
  const customOptions: string[][] = [];
  volunteerStudentsAssigned.forEach((student, idx) =>
    customOptions.push([student.airtableId, student.firstName + ' ' + student.lastName + ' - ' + student.className]),
  );

  return (
    <AuthenticatedPage role={role}>
      <div className="flex-1 h-full w-85.75 width-form-cr mx-auto pb-4 flex flex-row items-center">
        <div className="flex items-center h-15 w-15">{renderIconByGender}</div>
        <div className="flex flex-col justify-center">
          <p className="sm:block ml-2.5 text-mainPurple" style={{fontSize: '1.5rem', lineHeight: '3rem'}}>
            {firstName}
            <span className="ml-0.75 font-bold">{lastName}</span>
          </p>
          <p className="sm:block ml-2.5 text-greyMedium font-bold">{role === Role.STUDENT ? 'Élève' : 'Tuteur'}</p>
        </div>
      </div>
      <form
        className="flex-1 h-full w-85.75 width-form-cr mx-auto pb-4 flex flex-col items-center"
        onSubmit={handleSubmit}>
        <div className="w-full p-8 bg-clWhite rounded-8px shadow-formRectangle">
          <div className="flex flex-col">
            <span className="text-3xl font-bold	text-mainPurple">Compte rendu</span>
            <span className="text-base text-greyMedium font-medium mt-3">
              Ce compte rendu est à destination de l&#39;équipe ZUPdeCO uniquement, l&#39;élève n&#39;est pas
              destinataire
            </span>
            <div className="mt-8">
              <TextInput
                displayName="Date de la séance"
                className="w-50 lg:w-55.5 mx-1"
                type="date"
                mandatory="True"
                id="date"
                name="date"
                value={date}
                onChange={(event) => setDate(event.target.value)}
                required
              />
            </div>
            <div className="mt-8 flex flex-col h-full justify-left text-left">
              <div className="flex flex-col mb-6">
                {students ? (
                  <>
                    <SelectInputKeyValue
                      options={customOptions}
                      defaultEmptyValueTitle="--Choisissez un de vos élèves--"
                      required
                      label="Élève concerné"
                      name="Student"
                      id="student"
                      mandatory="True"
                      className="w-full"
                      onChange={(event) => setSelectedStudent(event.target.value)}
                    />
                  </>
                ) : (
                  <p className="text-base text-greyMedium font-medium">Vous n&apos;avez pas encore d&apos;élève</p>
                )}
              </div>
              <div className="flex flex-col mb-6">
                <StarRating
                  displayName="Note de la séance"
                  onChange={(event) => setNoteDeLaSeance(event.target.value)}
                  rate={3}
                  interval={1}
                  name="noteDeLaSeance"
                  checkedValue={noteDeLaSeance}
                  mandatory={'True'}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-6">
            <div className="text-base text-greyMedium font-medium mb-6">
              <StarRating
                displayName="Implication de lélève"
                onChange={(event) => setImplicationEleve(event.target.value)}
                rate={3}
                interval={1}
                name="implicationEleve"
                checkedValue={implicationEleve}
                mandatory={'True'}
                required
              />
            </div>
            <div className="text-base text-greyMedium font-medium">
              Compte rendu de la séance
              <span className="mandatory-star">*</span>
              <TextAreaInput
                min="1"
                max="20"
                name="message"
                displayName=""
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                id="message"
                required
                className="h-37.5 rounded border-solid border-2 border-greyLight"
              />
            </div>
            <div className="flex flex-col relative mt-6 mb-8">
              <div className="text-base text-greyMedium font-medium">Pièce jointe</div>
              <div className="flex flex-col mt-4">
                <label className="border-3 p-2 bg-greyLight w-36">
                  <input type="file" id="file" name="file" className="hidden" onChange={handleFileSelected} />
                  <span className="text-greyMedium">{'Choisir un fichier'}</span>
                </label>
                <div className="flex flex-col">
                  {files.map((file, ids) => {
                    return (
                      <>
                        <div className="flex flex-row">
                          <p className="text-greyMedium" style={{fontSize: 'small', padding: '0.2rem 0 0 0'}}>
                            {file.name}
                          </p>
                          <IconDelete
                            key={ids}
                            onClick={() => setFiles(files.filter((item) => item.name !== file.name))}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-auto">
          {userState.errorMsg && <div className="mt-2 text-center text-clStateError">{userState.errorMsg}</div>}
          <Button className="w-85.75 lg:w-115 mt-8" color={isSuccess ? 'clValidate' : 'mainOrange'} type="submit">
            {isSuccess ? 'Merci pour votre compte rendu' : 'Envoyer'}
          </Button>

          {userState.isPending && <Spinner className="m-auto" />}
        </div>
      </form>
    </AuthenticatedPage>
  );
};
