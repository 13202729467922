import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {ReferentStatus} from '../../enums/ReferentStatus';
import {ReferentProfessionalCategoryParent} from '../../enums/ReferentProfessionalCategoryParent';
import {StudentReferent} from '../../interfaces/StudentReferent';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  studentReferentSelector,
  studentErrorSelector,
  studentIdSelector,
  studentInfoActions,
  studentPendingSelector,
  studentSuccessSelector,
  updateStudentInfo,
} from '../../store/reducers/studentInfoSlice';
import {Button} from '../Button';
import {TextInput} from '../TextInput';
import {SelectInput} from '../SelectInput';
import {Spinner} from '../Spinner';
import {isEmail, isPhoneNumber} from '../../utils/utils';
import {ZipCodeInput} from '../ZipCodeInput';
import {EmailInput} from '../EmailInput';
import {PhoneInput} from '../PhoneInput';
import appText from '../../assets/intl/fr';
import {Homelink} from '../Homelink';
import {useMediaQuery} from '../../hooks/Hook';
import {userActions} from '../../store/reducers/userSlice';
import ReactModal from 'react-modal';

export interface StudentReferentFormProps {
  isRegistrationForm?: boolean;
  goToNextStep?: () => void;
  goToPreviousStep?: () => void;
  access_token?: string | null;
}

const isNotDefaultReferentStatus = (status: string): boolean => {
  return Object.values(ReferentStatus).filter((refStatus) => refStatus.toString() === status).length === 0;
};

const renderTitle = (isRegistrationForm?: boolean): JSX.Element => {
  if (isRegistrationForm) {
    return (
      <>
        <h2 className="mb-8 text-2xl text-greyMedium font-bold">{appText.registrationStudent.referent.title}</h2>
      </>
    );
  } else {
    return (
      <>
        <h2 className="mb-8 lg:mb-6.5 text-3xl text-mainPurple font-bold">
          {appText.profile.responsable.parent.toUpperCase()}
        </h2>
      </>
    );
  }
};

export const StudentReferentForm = ({
  isRegistrationForm,
  goToNextStep,
  goToPreviousStep,
  access_token,
}: StudentReferentFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const referent = useAppSelector(studentReferentSelector);
  const studentId: string = useAppSelector(studentIdSelector);
  const errorMsg: string = useAppSelector(studentErrorSelector)['updateStudentInfo'] || '';
  const isLoading: boolean = useAppSelector(studentPendingSelector)['updateStudentInfo'] || false;
  const isSuccess: boolean = useAppSelector(studentSuccessSelector)['updateStudentInfo'] || false;
  const [otherStatus, setOtherStatus] = useState('');
  const [errorApeare, seterrorApeare] = useState<boolean>(false);

  useEffect(() => {
    dispatch(studentInfoActions.resetRequestApiState());
  }, [dispatch]);

  useEffect(() => {
    if (isRegistrationForm && isSuccess && goToNextStep) {
      dispatch(studentInfoActions.resetRequestApiState());
      goToNextStep();
    }
  }, [dispatch, isRegistrationForm, isSuccess, goToNextStep]);

  useEffect(() => {
    if (isNotDefaultReferentStatus(referent.referentStatus) && Boolean(referent.referentStatus)) {
      dispatch(
        studentInfoActions.saveStudentReferent({
          ...referent,
          referentStatus: ReferentStatus.AUTRE,
        }),
      );
      setOtherStatus(referent.referentStatus);
    }
  }, [referent, referent.referentStatus, setOtherStatus, dispatch]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name !== 'otherStatus') {
      const newReferent: StudentReferent = {
        ...referent,
        [event.target.name]: event.target.value,
      };

      dispatch(studentInfoActions.saveStudentReferent(newReferent));
    } else {
      setOtherStatus(event.target.value);
    }
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newReferent: StudentReferent = {
      ...referent,
      referentStatus: event.target.value as ReferentStatus,
    };
    dispatch(studentInfoActions.saveStudentReferent(newReferent));
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const handleSelectCategoryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newReferent: StudentReferent = {
      ...referent,
      referentProfessionalCategoryParent: event.target.value as ReferentProfessionalCategoryParent,
    };
    dispatch(studentInfoActions.saveStudentReferent(newReferent));
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(studentInfoActions.resetStudentInfoState());
    dispatch(userActions.resetUserState());
    window.location.href = '/registration/student/form';
  };

  const [close, setclose] = useState(false);

  const sendData = () => {
    if (canContinue()) {
      dispatch(studentInfoActions.resetRequestApiState());
      dispatch(
        updateStudentInfo({
          id: studentId,
          request: {
            referentFirstName: referent.referentFirstName,
            referentLastName: referent.referentLastName,
            referentStatus: referent.referentStatus !== ReferentStatus.AUTRE ? referent.referentStatus : otherStatus,
            referentAddress: referent.referentAddress,
            referentPhoneNumber: referent.referentPhone,
            referentPostalCode: referent.referentPostCode,
            referentCity: referent.referentCity,
            referentEmail: referent.referentEmail,
            referentProfessionalCategoryParent: referent.referentProfessionalCategoryParent,
          },
        }),
      );
    }
    setclose(false);
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    seterrorApeare(true);
    sendData();
  };
  const isabove500 = useMediaQuery('(max-width: 500px)');
  const canContinue = (): boolean => {
    let hasReferentStatus = Boolean(referent.referentStatus);

    if (referent.referentStatus === ReferentStatus.AUTRE) {
      hasReferentStatus = Boolean(otherStatus);
    }

    return (
      Boolean(referent.referentEmail) &&
      isEmail(referent.referentEmail) &&
      Boolean(referent.referentFirstName) &&
      Boolean(referent.referentLastName) &&
      Boolean(referent.referentPhone) &&
      Boolean(referent.referentAddress) &&
      Boolean(referent.referentPostCode) &&
      Boolean(referent.referentCity) &&
      isPhoneNumber(referent.referentPhone) &&
      Boolean(referent.referentProfessionalCategoryParent) &&
      hasReferentStatus
    );
  };

  const btnColor = !isRegistrationForm && isSuccess ? 'clValidate' : 'mainOrange';
  const btnTitle = isRegistrationForm ? 'Continuer' : !isRegistrationForm && isSuccess ? 'Enregistré !' : 'Enregistrer';
  const isabove1024 = useMediaQuery('(max-width: 1024px)');

  return (
    <form className="flex-1 h-full w-85.75 lg:w-131 mx-auto flex flex-col items-center" onSubmit={handleSubmit}>
      <div className="w-full p-8 mb-21.75 bg-clWhite rounded-8px shadow-formRectangle">
        {renderTitle(isRegistrationForm)}
        <div className="flex flex-col lg:flex-row mb-6">
          <TextInput
            type="text"
            name="referentFirstName"
            displayName="Prénom"
            mandatory="True"
            id="referentFirstName"
            value={referent.referentFirstName}
            onChange={handleChange}
            required
            className="w-full lg:w-55.5 mb-6 lg:mb-0 mr-0 lg:mr-4"
          />
          <TextInput
            type="text"
            name="referentLastName"
            displayName="Nom"
            mandatory="True"
            id="referentLastName"
            value={referent.referentLastName}
            onChange={handleChange}
            required
            className="w-full lg:w-55.5"
          />
        </div>
        <SelectInput
          label="Statut"
          mandatory="True"
          name="referentStatus"
          id="referentStatus"
          defaultEmptyValueTitle={'--Choisir un statut suivant--'}
          value={referent.referentStatus}
          options={Object.values(ReferentStatus)}
          onChange={handleSelectChange}
          required
          className="w-full lg:w-55 mb-6"
        />
        {referent.referentStatus === ReferentStatus.AUTRE && (
          <TextInput
            type="text"
            name="otherStatus"
            displayName="Merci de préciser"
            id="otherStatus"
            value={otherStatus}
            onChange={handleChange}
            className="w-full lg:w-55 mb-6"
          />
        )}
        <TextInput
          type="text"
          name="referentAddress"
          displayName="Adresse"
          mandatory="True"
          id="referentAddress"
          value={referent.referentAddress}
          onChange={handleChange}
          className="w-full mb-6"
        />
        {!referent.referentAddress && errorApeare && (
          <div className="mt-2 text-clStateError">{appText.registrationVolunteer.informations.error.adressEmpty}</div>
        )}
        <div className="flex flex-col lg:flex-row mb-6 lg:mb-6.5">
          <div className="mb-6 lg:mb-0 mr-0 lg:mr-4">
            <div className="flex flex-col">
              <ZipCodeInput
                name="referentPostCode"
                displayName="Code postal"
                mandatory="True"
                id="referentPostCode"
                value={referent.referentPostCode}
                onChange={handleChange}
                className="w-27.5"
              />
            </div>
            {!referent.referentPostCode && errorApeare && (
              <div className="mt-2 text-clStateError">
                {appText.registrationVolunteer.informations.error.postCodeEmpty}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <TextInput
              type="text"
              name="referentCity"
              displayName="Ville"
              mandatory="True"
              id="referentCity"
              value={referent.referentCity}
              onChange={handleChange}
              className="w-full lg:w-83.5"
            />
            {!referent.referentCity && errorApeare && (
              <div className="mt-2 text-clStateError">{appText.registrationVolunteer.informations.error.cityEmpty}</div>
            )}
          </div>
        </div>
        <div className="mb-6">
          <EmailInput
            name="referentEmail"
            displayName="Adresse e-mail"
            mandatory="True"
            id="referentEmail"
            value={referent.referentEmail}
            onChange={handleChange}
            required
            className="w-full"
            errorApeare={errorApeare}
          />
        </div>
        <div className="mb-6">
          <PhoneInput
            name="referentPhone"
            displayName="Téléphone"
            mandatory="True"
            id="referentPhone"
            value={referent.referentPhone}
            onChange={handleChange}
            required
            className="w-full"
            errorApeare={errorApeare}
          />
        </div>
        <div className="mb-6">
          <SelectInput
            label="Catégorie socio-professionnelle du parent référent"
            mandatory="True"
            name="referentProfessionalCategoryParent"
            id="referentProfessionalCategoryParent"
            defaultEmptyValueTitle={'-- Choisir une catégorie --'}
            value={referent.referentProfessionalCategoryParent}
            options={Object.values(ReferentProfessionalCategoryParent)}
            onChange={handleSelectCategoryChange}
            className="w-full lg:w-55"
          />
          {!referent.referentProfessionalCategoryParent && errorApeare && (
            <div className="mt-2 text-clStateError">
              {appText.registrationVolunteer.informations.error.referentProfessionalCategoryParentEmpty}
            </div>
          )}
        </div>
      </div>
      {errorMsg && <div className="mb-4 text-center text-clStateError">{errorMsg}</div>}
      <div className="flex justify-around" style={{width: '100%'}}>
        {isRegistrationForm && isabove500 && (
          <Button className="w-27.5 lg:w-50 mt-auto" onClick={goToPreviousStep} color={btnColor} isRegistrationForm>
            Retour
          </Button>
        )}
        {!isLoading && (
          <>
            <Button
              className={isRegistrationForm && isabove500 ? 'w-27.5 lg:w-50 mt-auto' : 'w-85.75 lg:w-115 mt-auto'}
              color={btnColor}
              type="submit"
              isRegistrationForm>
              {btnTitle}
            </Button>
          </>
        )}
      </div>
      {!isRegistrationForm && <Homelink />}
      {isLoading && <Spinner className="mt-auto" />}
      <ReactModal
        className="absolute w-85.75 md:w-auto top-40 inset-x-center-modal-mobile md:inset-x-1/3 p-8 bg-clWhite rounded-8px border-none overflow-auto outline-none"
        overlayClassName="fixed inset-0 bg-greyDark bg-opacity-50 overflow-auto z-50"
        isOpen={close}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}>
        <div>
          <div className="flex flex-col text-greyMedium" style={{alignItems: 'center'}}>
            <h3 className="my-2 text-clStateError" style={{fontSize: 'revert'}}>
              Attention
            </h3>
            <h4 className="mt-2 mb-4 text-greyMedium" style={{fontSize: 'revert'}}>
              vous êtes déjà connecté si vous souhaitez créer un nouveau compte veuillez sélectionner déconnexion si
              vous souhaitez mettre à jour vos informations veuillez sélectionner continuer
            </h4>
            <div className="flex justify-around relative" style={{width: '100%'}}>
              <button
                className="close bg-mainOrange rounded w-27.5"
                style={{padding: '0.7rem 0', color: 'white'}}
                onClick={sendData}>
                Continuer
              </button>
              <button
                className="close bg-mainOrange rounded w-27.5"
                style={{padding: '0.7rem 0', color: 'white'}}
                onClick={handleLogout}>
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </form>
  );
};
