import React, {ReactNode} from 'react';
import internal from 'stream';
import {Footer} from '../components/Footer';
import {HeaderGeneral} from '../components/HeaderGeneral';

interface GeneralPageProps {
  children: ReactNode;
  bgSrc: string;
  hideLoginButton?: boolean;
}

export const GeneralPage = ({children, bgSrc, hideLoginButton}: GeneralPageProps): JSX.Element => (
  <>
    <HeaderGeneral hideLoginButton={hideLoginButton} />
    <div className="h-full flex flex-col">
      <div
        className="h-full flex flex-col relative"
        style={{
          backgroundImage: `url(${bgSrc})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        {children}
      </div>
    </div>
    <Footer />
  </>
);
