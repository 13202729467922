import React, {FormEvent, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {GeneralPage} from './GeneralPage';
import imgInscription from '../assets/img/img_inscription.png';
import {Button} from '../components/Button';
import {useSelector} from 'react-redux';
import {forgotPassword, userActions, userSelector} from '../store/reducers/userSlice';
import {useAppDispatch} from '../store/hooks';
import {useMediaQuery} from '../hooks/Hook';
import {ModalProvider} from 'react-simple-modal-provider';
import Popup from 'reactjs-popup';
import {FaCheckCircle} from 'react-icons/fa';
import {AiFillCloseCircle} from 'react-icons/ai';
import 'reactjs-popup/dist/index.css';
import {padEnd} from 'lodash';
import ReactModal from 'react-modal';
import {Checkbox} from '../components/Checkbox';
import {Spinner} from '../components/Spinner';
type textAlign = 'center' | undefined;

export const ForgotPassword = (): JSX.Element => {
  const [close, setclose] = useState(false);
  const [idOrEmail, setIdOrEmail] = useState('');
  const {errorMsg, isSuccess} = useSelector(userSelector);
  const dispatch = useAppDispatch();
  const isabove500 = useMediaQuery('(max-width: 500px)');

  useEffect(() => {
    dispatch(userActions.resetUserState());
  }, [dispatch]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (canSend()) {
      dispatch(userActions.resetUserState());
      dispatch(
        forgotPassword({
          login: idOrEmail,
        }),
      );
    }
  };

  useEffect(() => {
    if (isSuccess) setclose(true);
  }, [isSuccess]);
  const handleClick = () => {
    setclose(false);
  };
  const canSend = (): boolean => {
    return !!idOrEmail;
  };

  return (
    <GeneralPage bgSrc={imgInscription}>
      <form
        className="absolute left-15% top-32 flex flex-col w-127 p-6 bg-clWhite"
        style={styles.container(isabove500)}
        onSubmit={handleSubmit}>
        <h1 className="mb-6 text-3xl text-greyMedium font-medium">MOT DE PASSE OUBLIÉ</h1>
        <input
          className="w-full h-18.75 px-4 py-6 mb-4 text-base text-greyMedium bg-greySecondMedium"
          type="text"
          name="idOrEmail"
          value={idOrEmail}
          placeholder="Adresse e-mail ou Login"
          onChange={(e) => setIdOrEmail(e.target.value)}
          required
        />
        <Link to="/login" className="mb-6 text-base text-greyMedium">
          Connexion
        </Link>
        <Button className="w-full" color="mainOrange" type="submit">
          Envoyer
        </Button>
        <ReactModal
          className="absolute w-85.75 md:w-auto top-40 inset-x-center-modal-mobile md:inset-x-1/3 p-8 bg-clWhite rounded-8px border-none overflow-auto"
          overlayClassName="fixed inset-0 bg-greyDark bg-opacity-50 overflow-auto z-50"
          isOpen={close}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}>
          <div>
            <div className="flex flex-col text-greyMedium" style={{alignItems: 'center'}}>
              <FaCheckCircle style={{color: 'green', margin: '1.5rem', width: '2rem', height: '2rem'}} />
              <h1 className="mb-4 text-2xl text-greyMedium font-bold text-center">
                Votre demande de changement de mot de passe a bien été prise en compte
              </h1>
              <h1 className="mb-8 text-base text-xl text-greyMedium text-center">
                Vous pouvez consulter votre boîte mail afin de continuer la procédure de récupération de mot de passe.
              </h1>
              <button
                className="w-full close bg-mainOrange rounded"
                style={{width: '40%', padding: '0.7rem 0', color: 'white'}}
                onClick={handleClick}>
                J&apos;ai compris
              </button>
            </div>
          </div>
        </ReactModal>
        {/* {isSuccess && (
          <div className="mt-4 text-clValidate text-base">
            Un email pour la réinitialisation de votre mot de passe vient de vous être envoyé sur {idOrEmail}
          </div>
        )} */}
        {errorMsg && <div className="mt-4 text-clStateError">{errorMsg}</div>}
      </form>
    </GeneralPage>
  );
};

const styles = {
  container: (isabove: any) => ({
    margin: isabove ? '5rem .5rem' : '0',
    left: isabove ? '0' : '10%',
    top: isabove ? '0' : '20%',
    width: isabove ? '95%' : '31.75rem',
  }),
  popup: (isabove: any) => ({
    width: isabove ? '95%' : '30rem',
    textAlign: 'center' as const,
    left: isabove ? '0.5rem' : '0',
  }),
};
