import React, {ChangeEvent, InputHTMLAttributes, useState} from 'react';
import {TextInput} from './TextInput';

export interface ZipCodeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  displayName: string;
  mandatory?: string;
  errorApeare?: boolean;
  seterrc?: (val: boolean) => void;
}

export const ZipCodeInput = ({onChange, errorApeare, seterrc, ...rest}: ZipCodeInputProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const strInput = event.target.value;

    if (!isFinite(+strInput)) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (event.target.value.length == 5 || strInput.length == 0) {
      setErrorMsg('');
    } else {
      if (errorApeare) {
        if (seterrc) {
          seterrc(false);
        }
        setErrorMsg("Le code postal n'est pas valide.");
      }
    }
    if (onChange) {
      onChange(event);
    }
  };
  // pattern="^\d{5}$"
  return (
    <div className="flex flex-col">
      <TextInput type="text" inputMode="numeric" maxLength={5} onChange={handleChange} {...rest} />
      {errorMsg && <div className="mt-2 text-clStateError">{errorMsg}</div>}
    </div>
  );
};
