export enum VolunteerEducationLevel {
  BacPlus5 = 'Sortie avec un diplôme de niveau Bac +5 ou plus',
  BacPlus3Ou4 = 'Sortie avec un diplôme de niveau Bac +3 ou 4',
  BacPlus2 = 'Sortie avec un diplôme de niveau Bac +2 ou plus',
  TerminalAvecBac = 'Sortie de terminale avec le Bac, ou abandon des études supérieures',
  TerminalSansBac = 'Sortie en cours de terminale ou Bac non validé',
  SecondPremiereSansBac = 'Sortie en cours de seconde ou première',
  CapBepValide = 'CAP-BEP validé',
  CapBepSansDiplom = 'Sortie en cours dernière année de CAP-BEP , sans valider de diplôme',
}
