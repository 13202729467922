import * as React from 'react';
import {Wrapper, DropdownList, Styledlink} from './style';
import {volunteerInfoActions} from '../../store/reducers/volunteerInfoSlice';
import {Role} from '../../enums/Role';
import {useAppDispatch} from '../../store/hooks';
import {studentInfoActions} from '../../store/reducers/studentInfoSlice';
import {userActions} from '../../store/reducers/userSlice';

interface IDropdownItem {
  id: number;
  url: string;
  text: string;
}

interface linkstyled {
  id: number;
  url: string;
  text: string;
}

interface IProps {
  activatorText?: string;
  items?: IDropdownItem[];
  children?: React.ReactNode;
  role?: Role;
}

const dropdownItems = [
  {
    id: 1,
    url: 'myLink',
    text: 'Déconnexion',
  },
];

const Dropdown = ({children, role, activatorText = '', items = dropdownItems}: IProps) => {
  const activatorRef = React.useRef<HTMLButtonElement | null>(null);
  const listRef = React.useRef<HTMLUListElement | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [ishover, setIsHover] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    localStorage.clear();

    if (role === Role.STUDENT) {
      dispatch(volunteerInfoActions.resetVolunteerInfoState());
    } else {
      dispatch(studentInfoActions.resetStudentInfoState());
    }

    dispatch(userActions.resetUserState());
    window.location.href = 'https://zupdeco.homeclasse.org/';
  };

  const handlehover = () => {
    setIsOpen(true);
  };
  const handleNhover = () => {
    setIsOpen(false);
  };
  const handleisHover = () => {
    setIsOpen(true);
    setIsHover(true);
  };
  const handleisNHover = () => {
    setIsOpen(false);
    setIsHover(false);
  };
  const keyHandler = (event: React.KeyboardEvent) => {
    if (isOpen) {
      switch (event.key) {
        case 'Escape': {
          setIsOpen(false);
          break;
        }
        case 'ArrowDown': {
          const nodeList = listRef.current!.querySelectorAll('a');
          if (activeIndex === items.length - 1) return;
          nodeList[activeIndex + 1].focus();
          break;
        }
        case 'ArrowUp': {
          const nodeList2 = listRef.current!.querySelectorAll('a');
          if (activeIndex === 0) return;
          nodeList2[activeIndex - 1].focus();
          break;
        }
      }
    }
  };

  const handleClickOutside = (event: any) => {
    if (listRef.current!.contains(event.target) || activatorRef.current!.contains(event.target)) {
      return;
    }
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (isOpen) {
      listRef.current!.querySelector('a')!.focus();
      document.addEventListener('mouseup', handleClickOutside);
    } else {
      document.removeEventListener('mouseup', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [isOpen]);

  React.useEffect(() => {
    if (!isOpen) {
      setActiveIndex(-1);
    }
  }, [isOpen]);

  const focusHandler = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <Wrapper onKeyUp={keyHandler}>
      <button
        className="flex flex-row items-center"
        aria-haspopup="true"
        aria-controls="dropdown1"
        onMouseEnter={handlehover}
        onMouseLeave={handleNhover}
        ref={activatorRef}
        onFocus={() => setActiveIndex(-1)}>
        {children}
      </button>
      <DropdownList id="dropdown1" ref={listRef} active={isOpen} role="list">
        {items.map((item, index) => (
          <li key={item.id}>
            <Styledlink
              className="text-base text-greyMedium font-bold"
              onClick={handleLogout}
              onFocus={() => focusHandler(index)}
              onMouseEnter={handleisHover}
              onMouseLeave={handleisNHover}
              active={ishover}
              style={{backgroundColor: 'white', borderRadius: '.2rem'}}>
              {item.text}
            </Styledlink>
          </li>
        ))}
      </DropdownList>
    </Wrapper>
  );
};

export default Dropdown;
