import React, {ChangeEvent, InputHTMLAttributes, useEffect, useState} from 'react';
import appText from '../assets/intl/fr';
import {isEmail, isEmailEmpty} from '../utils/utils';
import {TextInput} from './TextInput';

export interface EmailInputProps extends InputHTMLAttributes<HTMLInputElement> {
  displayName: string;
  errorApeare?: boolean;
  mandatory?: string;
  seterr?: (err: boolean) => void;
}

export const EmailInput = ({onChange, errorApeare, seterr, ...rest}: EmailInputProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (rest.required && !rest.value) {
      setErrorMsg(appText.emailInput.error);
    }
  }, [setErrorMsg, rest.required, rest.value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const strInput = event.target.value;
    if (seterr) seterr(false);
    if (isEmail(strInput)) {
      setErrorMsg('');
    } else if (!isEmail(strInput) || !isEmailEmpty(strInput)) {
      setErrorMsg(appText.registrationStudent.informations.error.emailNotValid);
    }

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="flex flex-col">
      <TextInput /*type="email"*/ onChange={handleChange} {...rest} />
      {errorApeare !== undefined ? (
        errorMsg && errorApeare ? (
          <div className="mt-2 text-clStateError">{errorMsg}</div>
        ) : (
          <></>
        )
      ) : (
        errorMsg && <div className="mt-2 text-clStateError">{errorMsg}</div>
      )}
    </div>
  );
};
