import React from 'react';
import {Link} from 'react-router-dom';
import {MenuItem} from '../interfaces/MenuItem';

export interface MenuProps {
  menuItems: MenuItem[];
  isHighlighted?: boolean;
  currentItemIndex?: number;
}

export const Menu = ({menuItems, isHighlighted, currentItemIndex}: MenuProps): JSX.Element => (
  <ul className="w-full flex flex-row items-center justify-between">
    {menuItems.map((menuItem, idx) => (
      <li className="pr-5.5" key={idx}>
        <Link
          className={`${
            !isHighlighted || (isHighlighted && currentItemIndex === idx)
              ? 'text-mainPurple'
              : 'text-greyMedium opacity-50'
          } text-base font-semibold`}
          to={{pathname: menuItem.link}}
          target="_parent">
          {menuItem.name}
        </Link>
      </li>
    ))}
  </ul>
);
