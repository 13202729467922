import React from 'react';
import {Link} from 'react-router-dom';
import {useMediaQuery} from '../hooks/Hook';
import {MenuItem} from '../interfaces/MenuItem';
type FlexDirection =
  | 'column'
  | 'inherit'
  | '-moz-initial'
  | 'initial'
  | 'revert'
  | 'unset'
  | 'column-reverse'
  | 'row'
  | 'row-reverse'
  | undefined;

const homeClasseItems: MenuItem[] = [
  {
    name: 'ACCUEIL',
    link: 'https://zupdeco.homeclasse.org/',
  },
  {
    name: 'ÉLÈVE',
    link: 'https://zupdeco.homeclasse.org/eleve/',
  },
  {
    name: 'TUTEUR',
    link: 'https://zupdeco.homeclasse.org/tuteur/',
  },
  {
    name: 'NOTRE OFFRE',
    link: 'https://zupdeco.homeclasse.org/notre-offre/',
  },
  {
    name: 'LE PROJET',
    link: 'https://zupdeco.homeclasse.org/le-projet/',
  },
  {
    name: 'ENTREPRISE',
    link: 'https://zupdeco.homeclasse.org/entreprise/',
  },
  {
    name: 'DONATEUR',
    link: 'https://zupdeco.homeclasse.org/donateur/',
  },
  {
    name: 'CONTACT',
    link: 'https://zupdeco.homeclasse.org/contact/',
  },
];

const associationItems: MenuItem[] = [
  {
    name: 'ZUPDECO',
    link: 'https://zupdeco.org/',
  },
  {
    name: 'MÉDIAS',
    link: 'https://zupdeco.org/medias',
  },
  {
    name: 'MENTIONS LÉGALES',
    link: 'https://zupdeco.homeclasse.org/mentions-legales/',
  },
  {
    name: 'CONFIDENTIALITÉ',
    link: 'https://zupdeco.homeclasse.org/confidentialite/',
  },
];

export const Footer = (): JSX.Element => {
  const isabove768 = useMediaQuery('(max-width: 768px)');
  return (
    <footer
      className="flex flex-shrink-0 justify-center mt-auto py-24 bg-greyDark text-clWhite"
      style={styles.container(isabove768)}>
      <div className="flex flex-col mr-16">
        <h3 className="mb-8 text-2xl font-bold">CONTACT</h3>
        <p className="mb-6 text-base font-bold">ZUPdeCO</p>
        <p className="mb-6 text-base">Association loi 1901 reconnue d&apos;intérêt général</p>
        <p className="mb-6 text-base">8 rue du Faubourg Poissonnière 75010 Paris - France</p>
        <p className="mb-6 text-base">+33 (0)9 80 86 50 14</p>
        <p className="text-base">HomeClasse@zupdeco.org</p>
      </div>
      <div className="flex flex-col mr-50">
        <h3 className="mb-8 text-2xl font-bold">HOMECLASSE</h3>
        <ul className="pl-5 list-disc">
          {homeClasseItems.map((homeClasseItem, idx) => (
            <li key={idx} className="mb-6 text-base text-mainOrange">
              <Link to={{pathname: homeClasseItem.link}} target="_parent" className="text-clWhite">
                {homeClasseItem.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col">
        <h3 className="mb-8 text-2xl font-bold">L&apos;ASSOCIATION</h3>
        <ul className="pl-5 list-disc">
          {associationItems.map((associationItem, idx) => (
            <li key={idx} className="mb-6 text-base text-mainOrange">
              <Link to={{pathname: associationItem.link}} target="_parent" className="text-clWhite">
                {associationItem.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

const styles = {
  container: (isabove: any) => ({
    flexDirection: isabove ? ('column' as const) : ('row' as const),
    padding: isabove ? '6rem 1rem' : '6rem 0',
    margin: 'auto',
  }),
};
