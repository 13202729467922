import DetectRTC from 'detectrtc';
import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import appText from '../../assets/intl/fr';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {Button} from '../Button';
import {
  studentAvailableSelector,
  studentErrorSelector,
  studentIdSelector,
  studentInfoActions,
  studentPendingSelector,
  studentSuccessSelector,
  updateStudentInfo,
} from '../../store/reducers/studentInfoSlice';
import {
  updateVolunteerInfo,
  volunteerAvailabilitySelector,
  volunteerErrorSelector,
  volunteerIdSelector,
  volunteerInfoActions,
  volunteerInfoSelector,
  volunteerPendingSelector,
  volunteerResponsabilitySelector,
  volunteerSuccessSelector,
} from '../../store/reducers/volunteerInfoSlice';
import useRtc from '../../utils/rtcUtils';
import {IconErrorXL, IconCheckXL, ButtonLecteur, ButtonLecteurRunning} from '../../assets';

export interface CheckDevicesProps {
  isStudent?: boolean;
  isRegistrationForm?: boolean;
  goToNextStep?: () => void;
  goToPreviousStep?: () => void;
  access_token?: string | null;
}

const renderTitle = (isRegistrationForm?: boolean): JSX.Element => {
  if (isRegistrationForm) {
    return (
      <>
        <h2 className="text-2xl text-greyMedium font-bold">{appText.registration.device_check}</h2>
      </>
    );
  }
  return <></>;
};

const renderIcon = (isOk?: boolean): JSX.Element => {
  return <>{isOk ? <IconCheckXL /> : <IconErrorXL />}</>;
};

export const CheckDevices = ({
  isStudent,
  isRegistrationForm,
  goToNextStep,
  goToPreviousStep,
  access_token,
}: CheckDevicesProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const apiName = isStudent ? 'updateStudentInfo' : 'updateVolunteerInfo';
  const errorMsg: string = useAppSelector(isStudent ? studentErrorSelector : volunteerErrorSelector)[apiName] || '';
  const isLoading: boolean =
    useAppSelector(isStudent ? studentPendingSelector : volunteerPendingSelector)[apiName] || false;
  const isSuccess: boolean =
    useAppSelector(isStudent ? studentSuccessSelector : volunteerSuccessSelector)[apiName] || false;

  const [close, setclose] = useState(false);
  const [error, seterror] = useState('');
  const [errmsg, seterrormsg] = useState('');

  const {camPermission, micPermission} = useRtc();
  const [hasCamPermission, setCamPermission] = useState(camPermission);
  const [hasMicPermission, setMicPermission] = useState(micPermission);
  const [isClick, setIsClick] = useState(false);

  const [play, setPlay] = useState(false);

  useEffect(() => {
    requestAccess();
    if (isClick && hasCamPermission && hasMicPermission) {
      setIsClick(false);
      setBtnTitle(isRegistrationForm ? 'Suivant' : !isRegistrationForm && isSuccess ? 'Enregistré !' : 'Enregistrer');
    }
    if (isRegistrationForm && isSuccess && goToNextStep) {
      goToNextStep();
    }
  }, [dispatch, isRegistrationForm, isSuccess, goToNextStep, isClick, hasCamPermission, hasMicPermission]);

  const webPermission = DetectRTC.isWebRTCSupported;
  const hasCam = DetectRTC.hasWebcam;
  const hasMic = DetectRTC.hasMicrophone;
  const [btnTitle, setBtnTitle] = useState(
    isRegistrationForm ? 'Suivant' : !isRegistrationForm && isSuccess ? 'Enregistré !' : 'Enregistrer',
  );

  const btnColor = !isRegistrationForm && isSuccess ? 'clValidate' : 'mainOrange';
  const previous = 'Précédent';

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    sendData();
  };

  const requestAccess = async () => {
    try {
      if (hasCamPermission == false) {
        const videoStream = await navigator.mediaDevices.getUserMedia({video: true});
        videoStream.getTracks().forEach((track) => {
          track.stop();
        });
        setCamPermission(true);
      }
    } catch (err) {
      // Error
    }
    try {
      if (hasMicPermission == false) {
        const audioStream = await navigator.mediaDevices.getUserMedia({audio: true});
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
        setMicPermission(true);
      }
    } catch (err) {
      // Error
    }
  };

  const sendData = () => {
    seterrormsg('');
    let validCam = true;
    if (hasCam && !hasCamPermission) {
      validCam = false;
    }
    let validMic = true;
    if (hasMic && !hasMicPermission) {
      validMic = false;
    }

    if (!isClick && (!validCam || !validMic)) {
      setBtnTitle('Contactez-nous');
      setIsClick(true);
    } else {
      if (validCam && validMic && goToNextStep) {
        goToNextStep();
      } else {
        window.location.href = 'https://zupdeco.homeclasse.org/contact';
      }
    }
  };

  const playAudio = () => {
    setPlay(true);
    document.getElementsByTagName('audio')[0].play();
  };

  const stopAudio = () => {
    setPlay(false);
    document.getElementsByTagName('audio')[0].pause();
  };

  const tryAgain = (event: any) => {
    event.preventDefault();
    // Note: Firefox supports a non-standard forceGet boolean parameter for location.reload(),
    // to tell Firefox to bypass its cache and force-reload the current document.
    // However, in all other browsers,
    // any parameter you specify in a location.reload() call will be ignored and have no effect of any kind.
    window.location.reload(true);
  };

  // const {camPermission, micPermission} = useRtc();
  return (
    <form
      className="flex-1 h-full w-85.75 lg:w-131 mx-auto flex flex-col items-center max-w-full"
      onSubmit={handleSubmit}>
      <div className="w-full p-8 mb-21.75 bg-clWhite rounded-8px shadow-formRectangle">
        {renderTitle(isRegistrationForm)}
        <p className="mt-3 inline-flex w-full align-items-center">
          {renderIcon(hasMic)}
          <div className="w-10/12 pl-2">
            <span>Micro présent sur l&apos;ordinateur ?</span>
          </div>
        </p>
        <p className="mt-3 inline-flex w-full align-items-center">
          {renderIcon(hasCam)}
          <div className="w-10/12 pl-2">
            <span>Webcam présente sur l&apos;ordinateur ?</span>
          </div>
        </p>
        <p className="mt-3 inline-flex w-full align-items-center">
          {renderIcon(hasCamPermission)}
          <div className="w-10/12 pl-2">
            <span>Webcam autorisée ?</span>
          </div>
        </p>
        <>
          {hasCam && !hasCamPermission && isClick && (
            <div className="mt-3 text-clStateError">Merci d&#39;autoriser votre Webcam</div>
          )}
        </>
        <p className="mt-3 inline-flex w-full align-items-center">
          {renderIcon(hasMicPermission)}
          <div className="w-10/12 pl-2">
            <span>Micro autorisé ?</span>
          </div>
        </p>
        <>
          {hasMic && !hasMicPermission && isClick && (
            <div className="mt-3 text-clStateError">Merci d&#39;autoriser votre Micro</div>
          )}
        </>
        <p className="mt-5">
          Testez vos haut-parleurs (vous devez entendre une musique après avoir cliqué sur la flèche)
        </p>
        <audio controls hidden>
          <source
            src="https://www.auboutdufil.com/get.php?fla=https://archive.org/download/onycs-shine/Onycs-Shine.mp3"
            type="audio/mpeg"
          />
          Votre navigateur ne supporte pas la balise audio.
        </audio>
        <>{!play && <ButtonLecteur onClick={playAudio} className="mt-3" />}</>
        <>{play && <ButtonLecteurRunning onClick={stopAudio} className="mt-3" />}</>
        <button className="mt-5 px-5 py-3 text-clWhite font-medium rounded-8px bg-darkcolor" onClick={tryAgain}>
          Réessayer
        </button>
      </div>
      <>
        {((hasMic && !hasMicPermission) || (hasCam && !hasCamPermission)) && isClick && (
          <div className="mb-4 text-center text-clStateError">Pour réessayer, appuyez sur F5.</div>
        )}
      </>
      <div className="flex justify-center" style={{width: '100%'}}>
        <button
          onClick={goToPreviousStep}
          className="w-85.75 lg:w-64 mt-auto mr-1 py-3 text-xl text-clWhite text-center font-medium rounded-8px disabled:bg-greyMedium disabled:opacity-16 bg-darkcolor">
          {previous}
        </button>
        <Button className="w-85.75 lg:w-64 mt-auto ml-1 min-w-0" color={btnColor} type="submit">
          {btnTitle}
        </Button>
      </div>
    </form>
  );
};
