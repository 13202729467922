import React from 'react';
import '../styles/Spinner.scss';

interface SpinnerProps {
  className?: string;
}

export const Spinner = ({className}: SpinnerProps): JSX.Element => {
  const defaultClassName = 'lds-roller w-20 h-20 relative inline-block';
  const combinedClassName = className ? `${defaultClassName} ${className}` : defaultClassName;

  return (
    <div className={combinedClassName}>
      <div className="after:top-15.75 after:left-15.75 after:bg-red"></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
