import DetectRTC from 'detectrtc';
import {useState, useEffect} from 'react';

const useRtc = () => {
  const [rtc, setRtc] = useState<typeof DetectRTC | null>(null);

  useEffect(() => {
    DetectRTC.load(() => {
      setRtc(DetectRTC);
    });
  }, []);

  return {
    camPermission: rtc ? rtc.isWebsiteHasWebcamPermissions : false,
    micPermission: rtc ? rtc.isWebsiteHasMicrophonePermissions : false,
  };
};

export default useRtc;
