import React from 'react';
import {Header} from './Header';

export interface HeaderFormProps {
  title: string;
  currentStep: number;
  nbStep: number;
}

export const HeaderForm = ({title, currentStep, nbStep: totalStep}: HeaderFormProps): JSX.Element => {
  const widthProgress = (currentStep / totalStep) * 100;
  const widthRemaining = 100 - widthProgress;

  return (
    <>
      <Header
        elementMiddle={<h1 className="text-base text-mainPurple font-semibold">{title}</h1>}
        elementRight={
          <div className="flex flex-row items-center">
            <p className="text-base text-greyMedium font-medium">Étape</p>
            <p className="mx-1 text-base text-mainOrange font-medium">{currentStep}</p>
            <p className="text-xs text-greyMedium font-medium">/{totalStep}</p>
          </div>
        }
      />
      <div className="sticky top-11 z-50 w-full h-1 flex flex-row">
        <div className="bg-mainOrange" style={{width: `${widthProgress}%`}} />
        <div className="bg-greyLight" style={{width: `${widthRemaining}%`}} />
      </div>
    </>
  );
};
