import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const DropdownList = styled.ul<{active: boolean}>`
  background-color: #ffffff;
  color: black;
  display: ${(props) => (props.active ? 'block' : 'none')};
  margin: 0;
  width: 140px;
  padding: 0;
  position: absolute;
  border-radius: 10rem;
  filter: drop-shadow(0 0 0.2rem grey);
  right: 1rem;
  li {
    list-style: none;

    margin: 0;
    a,
    a:link {
      cursor: pointer;
      display: block;
      padding: 0.5em;
      &:hover {
        background-color: lightblue;
      }
    }
  }
`;

export const Styledlink = styled.a<{active: boolean}>`
  text-decoration: ${(props) => (props.active ? 'none' : 'underline')};
`;
