import React from 'react';
import '../styles/ToggleSwitch.scss';

export interface ToggleSwitchProps {
  isChecked?: boolean;
  onChange: (checked: boolean) => void;
  required?: boolean;
}

export const ToggleSwitch = ({isChecked, onChange, required}: ToggleSwitchProps): JSX.Element => {
  return (
    <label className="switch relative inline-block w-9.75 h-3.75">
      <input
        type="checkbox"
        checked={isChecked}
        className="opacity-0 w-0 h-0"
        onChange={() => onChange(!isChecked)}
        {...(required ? {required: required} : {})}
      />
      <span className="slider absolute cursor-pointer inset-0 bg-greyLight rounded-full duration-300 delay-300"></span>
    </label>
  );
};

export default ToggleSwitch;
