import React from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {AvailableStep} from '../components/forms/AvailableStep';
import {DocumentForm} from '../components/forms/DocumentForm';
import {StudentEligibilityCriteriaForm} from '../components/forms/StudentEligibilityCriteriaForm';
import {StudentReferentForm} from '../components/forms/StudentReferentForm';
import {StudentSchoolInfoForm} from '../components/forms/StudentSchoolInfoForm';
import {StudentInformationForm} from '../components/forms/StudentInformationForm';
import {ProfileNav} from '../components/ProfileNav';
import {Spinner} from '../components/Spinner';
import {Role} from '../enums/Role';
import {ProfileParams} from '../interfaces/ProfileParms';
import {AuthenticatedPage} from './AuthenticatedPage';
import {useAppSelector} from '../store/hooks';
import {studentPendingSelector} from '../store/reducers/studentInfoSlice';

export const StudentProfilePage = (): JSX.Element => {
  const isLoadingGetStudentProfile = useAppSelector(studentPendingSelector)['getStudentProfile'] || false;
  const {tabId} = useParams<ProfileParams>();

  const renderTabById = (currentTab: string): JSX.Element => {
    if (isLoadingGetStudentProfile) {
      return <Spinner className="mx-auto" />;
    }

    switch (currentTab) {
      case undefined:
      case 'informations':
        return <StudentInformationForm />;
      case 'eligibility-criteria':
        return <StudentEligibilityCriteriaForm />;
      case 'referent':
        return <StudentReferentForm />;
      case 'etablisment':
        return <StudentSchoolInfoForm />;
      case 'availability':
        return <AvailableStep isStudent={true} />;
      case 'documents':
        return <DocumentForm role={Role.STUDENT} />;
      default:
        return <Redirect to="/" />;
    }
  };

  return (
    <AuthenticatedPage role={Role.STUDENT}>
      <div className="h-full flex flex-1 flex-row pl-0 lg:pl-6 pb-4">
        <div className="hidden lg:block">
          <ProfileNav role={Role.STUDENT} />
        </div>
        <div className="flex flex-1 justify-center mr-0 lg:mr-48">
          <div>{renderTabById(tabId)}</div>
        </div>
      </div>
    </AuthenticatedPage>
  );
};
