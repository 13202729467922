import React, {InputHTMLAttributes} from 'react';
import '../styles/StarRating.scss';

export interface StarRatingProps extends InputHTMLAttributes<HTMLInputElement> {
  displayName: string;
  mandatory?: string;
  name: string;
  rate: number;
  interval: number;
  checked?: boolean;
  checkedValue: string;
  required?: boolean;
}

export interface StarProps {
  name: string;
  value: number;
  checked: boolean;
  required?: boolean;
}

const Star = ({name, value, checked, required}: StarProps): JSX.Element => {
  return (
    <>
      <input
        type="radio"
        id={`${name}star${value}`}
        name={name}
        value={value}
        {...(required ? {required: required} : {})}
        checked={checked}
        onChange={() => checked}
      />
      <label htmlFor={`${name}star${value}`}>{value} stars</label>
    </>
  );
};

export const StarRating = ({
  displayName,
  mandatory,
  name,
  rate,
  interval,
  checkedValue,
  required,
  onChange,
}: StarRatingProps): JSX.Element => {
  const rows = [];
  for (let index = rate; index > 0; index--) {
    const tvalue = interval * index;
    const tchecked = tvalue === parseInt(checkedValue);
    rows.push(<Star key={index} name={name} value={tvalue} checked={tchecked} required={required} />);
  }

  return (
    <div className="rate-containner">
      <div className="text-base text-greyMedium font-medium">
        {displayName}
        {mandatory == 'True' ? (
          <>
            <span className="mandatory-star">*</span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="rate" onChange={onChange}>
        {rows}
      </div>
    </div>
  );
};
