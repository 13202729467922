import {Role} from '../enums/Role';
import {MenuItem} from '../interfaces/MenuItem';

export const menuAuthenticatedItems = (role: Role): MenuItem[] => [
  {
    name: 'MON ESPACE HOMECLASSE',
    link: `/dashboard/${role.toLowerCase()}`,
  },
  {
    name: 'MON PROFIL',
    link: `/profile/${role.toLowerCase()}/informations`,
  },
  {
    name: 'CONTACT',
    link: `/contact/${role.toLowerCase()}`,
  },
];
