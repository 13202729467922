export enum AvailableDay {
  MONDAY = 'Chaque lundi',
  TUESDAY = 'Chaque mardi',
  WEDNESDAY = 'Chaque mercredi',
  THURSDAY = 'Chaque jeudi',
  FRIDAY = 'Chaque vendredi',
  SATURDAY = 'Chaque samedi',
}

export enum AvailableDays {
  MONDAY = 'Lundi',
  TUESDAY = 'Mardi',
  WEDNESDAY = 'Mercredi',
  THURSDAY = 'Jeudi',
  FRIDAY = 'Vendredi',
}

export enum AvailableHours {
  EMPTY = '',
  THREE = '15h-16h',
  FOUR = '16h-17h',
  FIVE = '17h-18h',
  SIX = '18h-19h',
  SEVEN = '19h-20h',
}
