import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import logoHeader from '../assets/img/logo-header.webp';

export interface HeaderProps {
  elementMiddle: ReactNode;
  elementRight: ReactNode;
}

export const Header = ({elementMiddle, elementRight}: HeaderProps): JSX.Element => (
  <header className="sticky top-0 z-10 w-full h-11 flex flex-row items-center justify-between px-6 bg-clWhite">
    <Link to={{pathname: process.env.REACT_APP_ZUPDECO_BASE_URL}} target="_parent">
      <img alt="Logo HomeClasse" src={logoHeader} />
    </Link>
    <div className="hidden sm:block">{elementMiddle}</div>
    <div>{elementRight}</div>
  </header>
);
