export enum VolunteerStatus {
  WAITING = 'Nouveau',
  INCOMPLET_1 = 'Incomplet relance 1',
  INCOMPLET_2 = 'Incomplet relance 2',
  PAS_SUITE = 'Ne donne pas suite',
  COMPLET = 'Complet',
  ATTENTE_1 = 'En attente élève 1',
  TROUVE = 'Elève 1 trouvé',
  SEANCE_0 = 'Séance 0 planifiée',
  EN_COURS = 'Tutorat 1 en cours',
  RENOUVELE = 'A renouveler',
  EQUIPE = 'Rentrée 23/24',
  PAUSE = 'Pause',
  ARRET = 'Arrêt',
}
/*
"Non" -> Dashboard non validé .
"Nouveau" -> Dashboard non validé .
"A matcher / Séance 0" -> Dashboard validé .
"OK" -> Dashboard validé .
"Pause" -> Dashboard en état (évolution). Pour le moment, étant donné que ce statut est une évolution , nous laisserons le Dashboard en non validé .
"Arrêt" -> Dashboard en arrêt (évolution). Pour le moment, étant donné que ce statut est une évolution , nous laisserons le Dashboard en non validé .
"A contacter" -> Dashboard en état (évolution). Pour le moment, étant donné que ce statut est une évolution , nous laisserons le Dashboard en non validé .

*/
