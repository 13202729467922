import React from 'react';
import ReactModal from 'react-modal';
import {Link} from 'react-router-dom';
import appText from '../../assets/intl/fr';
import {Button} from '../Button';

export interface StudentRegistrationFirstFormModalProps {
  onModifyResponseClick: () => void;
}

export const StudentRegistrationFirstFormModal = ({
  onModifyResponseClick,
}: StudentRegistrationFirstFormModalProps): JSX.Element => {
  const handleretourn = () => {
    window.location.href = 'https://zupdeco.homeclasse.org/';
  };
  return (
    <ReactModal
      isOpen={true}
      className="md:w-135 sm:w-full mt-16"
      overlayClassName="fixed inset-0 flex justify-center bg-backgroundModal overflow-auto z-50">
      <div className="bg-clWhite p-8 rounded-lg shadow-sm">
        <span className="mb-4 text-mainPurple text-2xl font-bold">{appText.studentDashboard.notEligibleTexte}</span>
        <ul className="my-4 list-disc pl-4 ">
          <li className="my-1 text-base text-mainOrange">
            <span className="text-greyMedium">{appText.studentDashboard.engagement.sentence1}</span>
          </li>
          <li className="my-1 text-base text-mainOrange">
            <span className="text-greyMedium">{appText.studentDashboard.engagement.sentence2}</span>
          </li>
          <li className="my-1 text-base text-mainOrange">
            <span className="text-greyMedium">{appText.studentDashboard.engagement.sentence3}</span>
          </li>
        </ul>
        <Button className="w-full text-base my-2" color="mainOrange" onClick={onModifyResponseClick}>
          Modifier mes réponses
        </Button>
        {/* <div className="flex flex-col justify-center bg-gray-200 py-8">
          <p className="mx-8 font-bold text-2xl text-greyMedium">Vous souhaitez en savoir plus ?</p> */}
        <Button
          className="w-full text-base my-2"
          color="mainPurple"
          onClick={() =>
            window.open(
              `https://zupdeco.homeclasse.org/contact/`,
              '_blank', // <- This is what makes it open in a new window.
            )
          }>
          Contacter HomeClasse
        </Button>
        {/* </div> */}
        <div className="flex justify-center mt-8">
          <a
            className="mx-8 font-bold text-base text-greyMedium text-center cursor-pointer"
            color="greyMedium"
            onClick={handleretourn}>
            Quitter et revenir à l&apos;accueil
          </a>
        </div>
      </div>
    </ReactModal>
  );
};
