import React, {useEffect} from 'react';
import {CalendlyEventListener, EventScheduledEvent, InlineWidget} from 'react-calendly';
import {dateToString} from '../utils/dateUtils';
import {Header} from '../components/Header';
import {Button} from '../components/Button';
import {useHistory, useParams} from 'react-router';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {studentInformationSelector} from '../store/reducers/studentInfoSlice';
import {
  updateVolunteerInfo,
  volunteerErrorSelector,
  volunteerIdSelector,
  volunteerInfoActions,
  volunteerInfoSelector,
  volunteerPendingSelector,
  volunteerSuccessSelector,
} from '../store/reducers/volunteerInfoSlice';
import {Role} from '../enums/Role';
import {VolunteerInformation} from '../interfaces/VolunteerInformation';
import {UpdateTeacherRequest} from '../interfaces/api/UpdateTeacherRequest';
import {MeetingType} from '../enums/MeetingType';
import {Spinner} from '../components/Spinner';
import {MeetingParams} from '../interfaces/MeetingParams';

export const MeetingInformationPage = (): JSX.Element => {
  const history = useHistory();

  const {meetingType} = useParams<MeetingParams>();
  const dispatch = useAppDispatch();

  const currentMonth = dateToString(new Date(), 'yyyy-MM');
  const studentInfo = useAppSelector(studentInformationSelector);
  const volunteerInfo = useAppSelector(volunteerInfoSelector);
  const volunteerId = useAppSelector(volunteerIdSelector);

  const apiName = 'updateVolunteerInfo';
  const errorMsg: string = useAppSelector(volunteerErrorSelector)[apiName] || '';
  const isLoading: boolean = useAppSelector(volunteerPendingSelector)[apiName] || false;
  const isSuccess: boolean = useAppSelector(volunteerSuccessSelector)[apiName] || false;

  const isAuthenticated = Boolean(localStorage.getItem('access_token'));
  const isStudent = Boolean(localStorage.getItem('role') === Role.STUDENT);
  const email = (isStudent ? studentInfo.studentEmail : volunteerInfo.email) || '';
  const name =
    (isStudent
      ? `${studentInfo.studentFirstName} ${studentInfo.studentLastName}`
      : `${volunteerInfo.firstName} ${volunteerInfo.lastName}`) || '';

  const btnColor = errorMsg.length > 0 ? 'clDeleteError' : isSuccess ? 'clValidate' : 'mainOrange';

  let calendlyUrl = process.env.REACT_APP_BASE_API_URL;
  let title = 'INSCRIPTION À LA RÉUNION D’INFORMATION PARENT/ÉLÈVE';
  switch (meetingType) {
    case MeetingType.INFORMATION:
      calendlyUrl = process.env.REACT_APP_MEETING_VOLUNTEER_INFORMATION;
      title = isStudent
        ? 'INSCRIPTION À LA RÉUNION D’INFORMATION PARENT/ÉLÈVE'
        : 'INSCRIPTION À LA RÉUNION D’INFORMATION TUTEUR';
      break;
    case MeetingType.TRAINING:
      calendlyUrl = process.env.REACT_APP_MEETING_VOLUNTEER_TRAINING;
      title = 'INSCRIPTION À LA RÉUNION DE FORMATION';
      break;
    case MeetingType.INDIVIDUAL:
      calendlyUrl = process.env.REACT_APP_MEETING_VOLUNTEER_INDIVIDUAL;
      title = 'MON ENTRETIEN INDIVIDUEL';
      break;
    case MeetingType.PARENT:
      title = 'INSCRIPTION À LA RÉUNION D’INFORMATION PARENT/ÉLÈVE';
      calendlyUrl = process.env.REACT_APP_MEETING_STUDENT_MEETING_PARENT;
      break;
    default:
      calendlyUrl = process.env.REACT_APP_MEETING_STUDENT_MEETING_PARENT;
  }

  useEffect(() => {
    dispatch(volunteerInfoActions.resetRequestApiState());
  }, [dispatch]);

  const handleEventScheduled = (e: EventScheduledEvent) => {
    if (isAuthenticated) {
      const eventUri: string = e.data.payload.event.uri;
      if (!isStudent) {
        const newVolunteerInfo: VolunteerInformation = {
          ...volunteerInfo,
          [meetingType]: eventUri,
        };

        dispatch(volunteerInfoActions.updateVolunteerInformation(newVolunteerInfo));

        const updateTeacherRequest: UpdateTeacherRequest = {
          [meetingType]: eventUri,
        };
        dispatch(
          updateVolunteerInfo({
            id: volunteerId,
            request: updateTeacherRequest,
          }),
        );
      }
    }
  };

  const handleBackToHomeClick = () =>
    history.push(
      isAuthenticated ? `/dashboard/${isStudent ? Role.STUDENT.toLowerCase() : Role.VOLUNTEER.toLowerCase()}` : '/',
    );

  return (
    <div className="bg-greyLight h-full">
      {!isAuthenticated && (
        <Header
          elementMiddle={<h1 className="text-base text-mainPurple font-semibold">{title}</h1>}
          elementRight={<></>}
        />
      )}
      <div>
        <CalendlyEventListener onEventScheduled={handleEventScheduled}>
          {isAuthenticated ? (
            <InlineWidget
              url={`${calendlyUrl}?month=${currentMonth}`}
              prefill={{
                name,
                email,
              }}
            />
          ) : (
            <InlineWidget url={`${calendlyUrl}?month=${currentMonth}`} />
          )}
        </CalendlyEventListener>
      </div>
      <div className="flex flex-col my-4 items-center	">
        {errorMsg && <div className="mb-4 text-clStateError">{errorMsg}</div>}
        {!isLoading && (
          <a
            className="w-85.75 lg:w-115 mt-auto text-black text-center"
            style={{cursor: 'pointer', textDecoration: 'underline'}}
            onClick={handleBackToHomeClick}>
            {`Retourner à mon espace HomeClasse`}
          </a>
        )}
        {isLoading && <Spinner className="mt-auto" />}
      </div>
    </div>
  );
};
