import React, {useState} from 'react';
import ReactModal from 'react-modal';
import {Link, useHistory} from 'react-router-dom';
import {IconInformationsOffXL} from '../assets';
import {Button} from './Button';
import {ButtonSmall} from './ButtonSmall';
import {Header} from './Header';
import {Menu} from './Menu';
import {menuGeneralItems} from '../constants/MenuGeneralItems';
import '../styles/HeaderGeneral.scss';
import {Role} from '../enums/Role';
import {useMediaQuery} from '../hooks/Hook';
import {FaHeart, FaUserAlt, FaRegUser} from 'react-icons/fa';

export interface HeaderGeneralProps {
  hideLoginButton?: boolean;
}

export const HeaderGeneral = ({hideLoginButton}: HeaderGeneralProps): JSX.Element => {
  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);
  const history = useHistory();
  const isabove768 = useMediaQuery('(max-width: 768px)');

  const role = localStorage.getItem('role');
  // const renderAuth = localStorage.getItem('access_token') ? (
  //   <li>
  //     <Link
  //       className="flex flex-row items-center justify-center"
  //       to={role === Role.STUDENT ? '/dashboard/student/' : '/dashboard/volunteer/'}>
  //       <div className="h-8 w-8 p-0.5 flex items-center justify-center mr-2 bg-mainPurple rounded-full">
  //         <IconInformationsOffXL className="icon-user" />
  //       </div>
  //       <p className="text-mainPurple text-base font-semibold">MON COMPTE</p>
  //     </Link>
  //   </li>
  // ) : (
  //   <>
  //     {!hideLoginButton && (
  //       <li>
  //         <ButtonSmall color="mainPurple" onClick={() => history.push('/login')}>
  //           CONNEXION
  //         </ButtonSmall>
  //       </li>
  //     )}
  //   </>
  // );

  const renderAuth = (
    <>
      {!hideLoginButton && (
        <li>
          <Link
            className="flex flex-row items-center justify-center text-mainPurple"
            style={{border: '1px solid', borderRadius: '2rem'}}
            to={role === Role.STUDENT ? '/dashboard/student/' : '/dashboard/volunteer/'}>
            {isabove768 ? (
              <div className="bg-mainPurple rounded-full">
                <IconInformationsOffXL className="icon-user text-mainPurple" style={{height: '20px', width: '20px'}} />
              </div>
            ) : (
              <>
                <div className="h-8 w-8 p-0.5 flex items-center justify-center mr-1 rounded-full">
                  <FaRegUser className="icon-user text-mainPurple" />
                </div>
                <p className="text-mainPurple text-base font-semibold pr-2">MON COMPTE</p>
              </>
            )}
          </Link>
        </li>
      )}
    </>
  );

  return (
    <>
      <Header
        elementMiddle={<Menu menuItems={menuGeneralItems} />}
        elementRight={
          <ul className="flex flex-row items-center justify-between">
            <li className="mr-5.5">
              <ButtonSmall
                color={isabove768 ? '' : 'mainOrange'}
                onClick={() => (window.location.href = 'https://www.helloasso.com/associations/zupdeco/formulaires/3')}>
                {isabove768 ? <FaHeart style={{color: '#FF692F'}} /> : <> FAIRE UN DON </>}
              </ButtonSmall>
            </li>
            {renderAuth}
          </ul>
        }
      />
      {registerModalIsOpen && (
        <ReactModal
          className="absolute top-1/3 inset-x-1/3 p-6 bg-clWhite rounded-8px overflow-auto"
          overlayClassName="fixed inset-0 bg-greyDark bg-opacity-50 z-50"
          isOpen={registerModalIsOpen}
          onRequestClose={() => setRegisterModalIsOpen(false)}
          ariaHideApp={false}>
          <div>
            <h1 className="mb-8 text-3xl text-greyMedium font-medium">S&apos;INSCRIRE</h1>
            <Button
              className="w-full mb-2"
              color="mainPurple"
              onClick={() => {
                setRegisterModalIsOpen(false);
                history.push('/registration/student');
              }}>
              M&apos;inscrire en tant qu&apos;élève
            </Button>
            <Button
              className="w-full"
              color="mainOrange"
              onClick={() => {
                setRegisterModalIsOpen(false);
                history.push('/registration/volunteer');
              }}>
              M&apos;inscrire en tant que tuteur
            </Button>
          </div>
        </ReactModal>
      )}
    </>
  );
};
