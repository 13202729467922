import React from 'react';

export interface DispoDaysProps {
  className?: string;
  checked?: boolean;
  onAvailableDayChange?: any;
  isMainColor?: boolean;
  black?: boolean;
  error?: boolean;
  selectedKey?: number;
  phone?: boolean;
}

export const DispoDaysCalendar = ({checked, onAvailableDayChange}: DispoDaysProps): JSX.Element => {
  const background = checked ? 'bg-Green' : '';
  const rec = 'Rectangle-Calendar';
  if (checked) {
    return (
      <div className={`${rec} ${background}`} onClick={onAvailableDayChange}>
        X
      </div>
    );
  } else {
    return (
      <div className={`${rec} ${background}`} onClick={onAvailableDayChange}>
        -
      </div>
    );
  }
};
