import React from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {AvailableStep} from '../components/forms/AvailableStep';
import {DocumentForm} from '../components/forms/DocumentForm';
import {VolunteerInformationForm} from '../components/forms/VolunteerInformationForm';
import {ProfileNav} from '../components/ProfileNav';
import {Spinner} from '../components/Spinner';
import {Role} from '../enums/Role';
import {ProfileParams} from '../interfaces/ProfileParms';
import {useAppSelector} from '../store/hooks';
import {volunteerPendingSelector} from '../store/reducers/volunteerInfoSlice';
import {AuthenticatedPage} from './AuthenticatedPage';

export const VolunteerProfilePage = (): JSX.Element => {
  const {tabId} = useParams<ProfileParams>();
  const isLoadingGetVolunteerProfile = useAppSelector(volunteerPendingSelector)['getVolunteerProfile'] || false;

  const renderTabById = (currentTab: string): JSX.Element => {
    if (isLoadingGetVolunteerProfile) {
      return <Spinner className="mx-auto" />;
    }

    switch (currentTab) {
      case undefined:
      case 'informations':
        return <VolunteerInformationForm />;
      case 'availability':
        return <AvailableStep isStudent={false} />;
      case 'documents':
        return <DocumentForm role={Role.VOLUNTEER} />;
      default:
        return <Redirect to="/" />;
    }
  };

  return (
    <AuthenticatedPage role={Role.VOLUNTEER}>
      <div className="h-full flex flex-1 flex-row pl-0 lg:pl-6 pb-4">
        <div className="hidden lg:block">
          <ProfileNav role={Role.VOLUNTEER} />
        </div>
        <div className="flex flex-1 justify-center mr-0 lg:mr-48">
          <div>{renderTabById(tabId)}</div>
        </div>
      </div>
    </AuthenticatedPage>
  );
};
