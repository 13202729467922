import React from 'react';
import '../styles/RadioButton.scss';

export interface RadioButtonProps {
  name: string;
  value: string;
  className?: string;
  checked?: boolean;
  required?: boolean;
}

export const RadioButton = ({name, value, className, checked, required}: RadioButtonProps): JSX.Element => {
  return (
    <div className={className}>
      <label
        htmlFor={`${name}-${value}`}
        className="h-5.5 pl-8 flex flex-row items-center relative select-none text-base text-greyMedium cursor-pointer">
        <input
          className="absolute w-0 h-0 opacity-0"
          type="radio"
          id={`${name}-${value}`}
          name={name}
          value={value}
          {...(required ? {required: required} : {})}
          checked={checked}
          onChange={() => checked}
        />
        <span className="absolute top-0 left-0 h-5.5 w-5.5 rounded-full border-solid border-2 border-greyLight bg-clWhite" />
        <span className="text-base text-greyMedium cursor-pointer">{value}</span>
      </label>
    </div>
  );
};
