import React, {FormEvent, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {GeneralPage} from './GeneralPage';
import imgInscription from '../assets/img/img_inscription.png';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {login, userSelector, userActions} from '../store/reducers/userSlice';
import {Spinner} from '../components/Spinner';
import {useMediaQuery} from '../hooks/Hook';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';

export const Login = (): JSX.Element => {
  const userState = useAppSelector(userSelector);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState('');
  const [Show, setShow] = useState(false);
  const [type, setType] = useState('password');
  const [password, setPassword] = useState('');
  const isabove500 = useMediaQuery('(max-width: 500px)');

  const handleeyehide = () => {
    setShow(false);
    setType('password');
  };
  const handleeyeshow = () => {
    setShow(true);
    setType('text');
  };

  useEffect(() => {
    dispatch(userActions.resetUserState());
  }, [dispatch]);

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    dispatch(userActions.resetUserState());
    dispatch(login({username, password}));
  };

  if (window.localStorage.getItem('role')) {
    if (window.localStorage.getItem('role') === 'Student') {
      history.push('/dashboard/student');
    } else {
      history.push('/dashboard/volunteer');
    }
  }

  return (
    <GeneralPage bgSrc={imgInscription} hideLoginButton>
      <form
        className="absolute flex flex-col p-6 bg-secondaryBlue mx-auto w-127"
        style={styles.container(isabove500)}
        onSubmit={handleSubmit}>
        <h1 className="mb-6 text-3xl text-clWhite font-medium">CONNEXION</h1>
        <input
          className="w-full h-18.75 px-4 py-6 mb-4 text-base text-greyMedium"
          type="text"
          name="username"
          value={username.toLowerCase()}
          placeholder="Login"
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <div className="flex flex-row">
          <input
            className="py-6 px-4 w-full h-18.75 mb-6 text-base text-greyMedium"
            type={type}
            name="password"
            value={password}
            placeholder="Mot de passe"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {Show ? (
            <i className="py-6 px-4 h-18.75 text-base text-greyMedium mb-6" style={{position: 'absolute', left: '80%'}}>
              <AiFillEye style={{height: '1.5rem', width: '1.5rem'}} onClick={handleeyehide} />
            </i>
          ) : (
            <i className="py-6 px-4 h-18.75 text-base text-greyMedium mb-6" style={{position: 'absolute', left: '80%'}}>
              <AiFillEyeInvisible style={{height: '1.5rem', width: '1.5rem'}} onClick={handleeyeshow} />
            </i>
          )}
        </div>
        <Link to="/forgot-password" className="mb-6 text-base text-clWhite">
          Mot de passe oublié ?
        </Link>
        {!userState.isPending && (
          <button
            className="w-full py-3 text-xl text-clWhite text-center bg-secondaryBlue border-solid border-2 border-clWhite rounded-8px"
            type="submit">
            Se connecter
          </button>
        )}
        {userState.isPending && (
          <div className="w-full text-center">
            <Spinner />
          </div>
        )}
        {userState.errorMsg && (
          <div className="italic ml-1 mt-1 text-clStateError text-xs">Login ou mot de passe incorrect.</div>
        )}
      </form>
    </GeneralPage>
  );
};

// changes
const styles = {
  container: (isabove: any) => ({
    margin: isabove ? '5rem .5rem' : '0',
    left: isabove ? '0' : '10%',
    top: isabove ? '0' : '20%',
    width: isabove ? '95%' : '31.75rem',
  }),
};
