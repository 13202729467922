import React from 'react';
import {AvailableHours} from '../enums/AvailableDay';
import '../styles/Available.scss';
import {AvailabilityCalendar} from '../interfaces/AvailabilityCalendar';
import {DispoDaysCalendar} from './DispoDaysCalendar';

export interface AvailableCalendarProps {
  title?: string;
  availabilities: AvailabilityCalendar;
  onAvailableDayChange: (value: number) => void;
}

export const AvailableCalendar = ({
  title,
  availabilities,
  onAvailableDayChange,
}: AvailableCalendarProps): JSX.Element => {
  return (
    <div className="column-disponibilite w-full text-align-center items-center">
      <div className="Rectangle-Calendar-Horaire text-greyMedium font-bold">{title}</div>
      {Object.values(AvailableHours).map((hour, id) => {
        if (id !== 0) {
          if (id === 1 && availabilities) {
            return (
              <DispoDaysCalendar
                key={id}
                checked={availabilities.three}
                onAvailableDayChange={() => onAvailableDayChange(id)}></DispoDaysCalendar>
            );
          } else if (id === 2 && availabilities) {
            return (
              <DispoDaysCalendar
                key={id}
                checked={availabilities.four}
                onAvailableDayChange={() => onAvailableDayChange(id)}></DispoDaysCalendar>
            );
          } else if (id === 3 && availabilities) {
            return (
              <DispoDaysCalendar
                key={id}
                checked={availabilities.five}
                onAvailableDayChange={() => onAvailableDayChange(id)}></DispoDaysCalendar>
            );
          } else if (id === 4 && availabilities) {
            return (
              <DispoDaysCalendar
                key={id}
                checked={availabilities.six}
                onAvailableDayChange={() => onAvailableDayChange(id)}></DispoDaysCalendar>
            );
          } else if (id === 5 && availabilities) {
            return (
              <DispoDaysCalendar
                key={id}
                checked={availabilities.seven}
                onAvailableDayChange={() => onAvailableDayChange(id)}></DispoDaysCalendar>
            );
          } else return <></>;
        }
      })}
    </div>
  );
};
