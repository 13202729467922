export enum MediaStudent {
  ETABLISSEMENT = 'Établissement scolaire',
  INTERNET = 'Recherche internet',
  RESEAUX = 'Réseaux sociaux',
  AUTRE = 'Autre média (TV, presse)',
}

export enum MediaReferent {
  AMPHI = 'Une présentation en amphi dans mon université/école',
  COMM_ECOLE = 'Une communication dans mon université/école',
  FORUM_ECOLE = 'Un forum dans mon université/école',
  SALON = 'Un salon ou évènement extérieur',
  AFFICHE_HERO = 'Via l\'affiche "Je deviens un héros"',
  AFFICHE_ORANGE = "Via l'affiche orange avec le miroir de l'engagement",
  PRES_ENTREPRISE = 'Une présentation dans mon entreprise',
  COMM_ENTREPRISE = 'Une communication dans mon entreprise',
  BOUCHE_OREILLE = 'Par du bouche-à-oreille',
  LINKEDIN_ZUPDECO = 'Par un post Linkedin ZUPdeCO',
  LINKEDIN_SALARIE = "Par un post Linkedin d'un salarié ZUPdeCO",
  INSTAGRAM_ZUPDECO = 'Par un post Instagram de ZUPdeCO',
  INSTAGRAM_INFLUEN = 'Par un(e) influenceur sur Instagram',
  PLATEFORME_JEVEUXAIDER = 'La plateforme d\'engagement "Je Veux Aider"',
  PLATEFORME_DIFFUZ = 'La plateforme d\'engagement "Diffuz"',
  PLATEFORME_WENABI = 'La plateforme d\'engagement "Wenabi"',
  PLATEFORME_VENDREDI = 'La plateforme d\'engagement "Vendredi"',
  PLATEFORME_DAYONE = 'La plateforme d\'engagement "Day One"',
  PLATEFORME_JEMENGAGE = 'La plateforme d\'engagement "Je m\'engage"',
  PLATEFORME_TOUSBENEVOLES = 'La plateforme d\'engagement "Tous Bénévoles"',
  PLATEFORME_BENEVITY = 'La plateforme d\'engagement "Benevity"',
  PLATEFORME_MICRODON = 'La plateforme d\'engagement "MicroDon"',
  SERVICE_NATIONAL = 'Via le Service National Universel',
  PRES_LYCEE = 'Une présentation dans mon lycée',
  COM_LYCEE = 'Une communication dans mon lycée',
  SITE_WEB = 'Par le site Web',
  TUTEUR = "J'étais déjà tutrice/tuteur l'année dernière",
}
