import React, {FormEvent, useEffect, useState} from 'react';
import {GeneralPage} from './GeneralPage';
import imgInscription from '../assets/img/img_inscription.png';
import {Button} from '../components/Button';
import {useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {resetPassword, userActions, userSelector} from '../store/reducers/userSlice';
import appText from '../assets/intl/fr';

export const ResetPassword = (): JSX.Element => {
  const userState = useAppSelector(userSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token') || '';
  const [newPassword, setNewPassword] = useState('');
  const {isSuccess, errorMsg} = userState;
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    dispatch(userActions.resetUserState());
  }, [dispatch]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (canSend()) {
      dispatch(
        resetPassword({
          token,
          password: newPassword,
        }),
      );
    }
  };

  const canSend = (): boolean => {
    return !!newPassword && !!confirmPassword && newPassword === confirmPassword;
  };

  return (
    <GeneralPage bgSrc={imgInscription}>
      <form className="absolute left-15% top-32 flex flex-col w-127 p-6 bg-clWhite" onSubmit={handleSubmit}>
        <h1 className="mb-6 text-3xl text-greyMedium font-medium">NOUVEAU MOT DE PASSE</h1>
        <input
          className="w-full h-18.75 px-4 py-6 mb-4 text-base text-greyMedium bg-greySecondMedium"
          type="password"
          name="newPassword"
          value={newPassword}
          placeholder="Nouveau mot de passe"
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <input
          className="w-full h-18.75 px-4 py-6 mb-4 text-base text-greyMedium bg-greySecondMedium"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          placeholder="Confirmation mot de passe"
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <Button className="w-full" color="mainOrange" type="submit">
          Valider
        </Button>
        {isSuccess && <div className="mt-4 text-clValidate text-base">{appText.resetPassword.passwordUpdated}</div>}
        {errorMsg && <div className="mt-4 text-clStateError">{errorMsg}</div>}
        {newPassword !== confirmPassword && (
          <div className="mt-4 text-clStateError">Veuillez vérifier que les mots de passe sont identiques</div>
        )}
      </form>
    </GeneralPage>
  );
};
