import React, {ChangeEvent, useEffect, useState} from 'react';
import {VolunteerInformation} from '../../interfaces/VolunteerInformation';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  updateVolunteerInfo,
  volunteerCommitmentCharterSelector,
  volunteerErrorSelector,
  volunteerIdSelector,
  volunteerInfoActions,
  volunteerInfoSelector,
  volunteerPendingSelector,
  volunteerSuccessSelector,
} from '../../store/reducers/volunteerInfoSlice';
import {Button} from '../Button';
import {Spinner} from '../Spinner';
import {TextInput} from '../TextInput';
import ToggleSwitch from '../ToggleSwitch';
import {VolunteerInformationFormProps} from './VolunteerInformationForm';
import {format, sub} from 'date-fns';
import {Link} from 'react-router-dom';

export interface VolunteercharteProps {
  goToNextStep?: () => void;
  isRegistrationForm?: boolean;
  access_token?: string | null;
  goToPreviousStep?: () => void;
}

export const VolounteerCharte = ({
  goToNextStep,
  isRegistrationForm,
  access_token,
  goToPreviousStep,
}: VolunteercharteProps): JSX.Element => {
  const volunteerCommitmentCharter: boolean = useAppSelector(volunteerCommitmentCharterSelector);

  const apiName = 'updateVolunteerInfo';
  const errorMsg: string = useAppSelector(volunteerErrorSelector)[apiName] || '';
  const isSuccess: boolean = useAppSelector(volunteerSuccessSelector)[apiName] || false;
  const isLoading: boolean = useAppSelector(volunteerPendingSelector)[apiName] || false;

  const [acceptCommitmentCharter, setAcceptCommitmentCharter] = useState(false);
  const dispatch = useAppDispatch();
  const volunteerId = useAppSelector(volunteerIdSelector);
  const volunteerInformation = useAppSelector(volunteerInfoSelector);

  useEffect(() => {
    if (isRegistrationForm && isSuccess && goToNextStep) {
      dispatch(volunteerInfoActions.resetRequestApiState());
      goToNextStep();
    }
  }, [dispatch, isRegistrationForm, isSuccess, goToNextStep]);

  useEffect(() => {
    if (volunteerCommitmentCharter) {
      setAcceptCommitmentCharter(volunteerCommitmentCharter);
    }
  }, []);

  const btnColor = errorMsg.length > 0 ? 'clDeleteError' : 'mainOrange';
  const handleAgreeToConvention = () => {
    dispatch(volunteerInfoActions.updateVolunteerCommitmentCharter(true));
    dispatch(volunteerInfoActions.resetRequestApiState());
    dispatch(
      updateVolunteerInfo({
        id: volunteerId,
        request: {
          commitmentCharter: true,
          startcharte: volunteerInformation.startcharte,
          endcharte: volunteerInformation.endcharte,
        },
      }),
    );
  };

  const btnTitle = 'Suivant';
  const previous = 'Précédent';
  const previousColor = 'darkcolor';
  // let minBirthdate = Date();

  const [err, seterr] = useState(false);
  const [charte, setcharte] = useState(false);
  const [timeerror, settimeerror] = useState('');
  const [minBirthdate, setminBirthdate] = useState(Date());

  const cancontinue = () => {
    const start = new Date(volunteerInformation.startcharte);
    const end = new Date(volunteerInformation.endcharte);

    if (start >= end) {
      return false;
    }
    return !!acceptCommitmentCharter && !!volunteerInformation.startcharte && !!volunteerInformation.endcharte;
  };

  useEffect(() => {
    if (volunteerInformation.startcharte) {
      const today = new Date(volunteerInformation.startcharte);
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const formattedDate = format(tomorrow, 'yyyy-MM-dd');
      setminBirthdate(formattedDate);
    }
  }, [volunteerInformation.startcharte]);

  const handlesubmit = () => {
    if (cancontinue()) {
      setcharte(false);
      seterr(false);
      handleAgreeToConvention();
      settimeerror('');
    } else {
      const start = new Date(volunteerInformation.startcharte);
      const end = new Date(volunteerInformation.endcharte);

      if (start >= end) {
        settimeerror('Date de début postérieure ou égale à la date de fin');
      } else {
        settimeerror('');
        setcharte(true);
        seterr(true);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newVolunteerInfo: VolunteerInformation = {
      ...volunteerInformation,
      [event.target.name]: event.target.value,
    };

    dispatch(volunteerInfoActions.updateVolunteerInformation(newVolunteerInfo));
    dispatch(volunteerInfoActions.resetRequestApiState());
  };

  return (
    <div className="flex-1 h-full w-85.75 lg:w-200 mx-auto flex flex-col items-center">
      <div className="w-full p-8 mb-3 bg-clWhite rounded-8px shadow-formRectangle">
        <div>
          <h1 className="mb-4 text-2xl text-greyMedium font-bold">Lettre d&apos;engagement</h1>
          <h1 className="mb-4 text-xl text-greyMedium">Je m’engage par la présente lettre à :</h1>
          <ul>
            <li className="mb-1 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-3.5">Être disponible au minimum 6 mois pendant l&apos;année scolaire.</p>
            </li>
            <li className="mb-1 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-3.55">
                Assister aux temps de formation et d’échanges de pratiques organisés par HomeClasse.
              </p>
            </li>
            <li className="mb-1 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-3.5">Effectuer avant le démarrage du tutorat la formation e-learning.</p>
            </li>
            <li className="mb-1 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-3.55">
                Établir pour chaque séance effectuée, un compte-rendu sur l’intranet de HomeClasse, au plus tard 3 jours
                après la fin de la séance.
              </p>
            </li>
            <li className="mb-1 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-3.55">
                Prévenir mon référent HomeClasse en cas d’absence exceptionnelle (De l&apos;élève ou de moi-même)
              </p>
            </li>
            <li className="mb-1 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-3.55">
                Déposer mon extrait de casier judiciaire vierge dans mon espace HomeClasse (Document à demander sur le
                site suivant :{' '}
                <Link
                  className="inhover"
                  target="_blank"
                  to={{pathname: 'https://www.demarches-enligne.com/casier-judiciaire/'}}>
                  https://www.demarches-enligne.com/casier-judiciaire/
                </Link>{' '}
                )
              </p>
            </li>
            <li className="mb-1 text-base flex items-baseline mt-3">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-3.5 ">Respecter la période d’engagement suivante :</p>
            </li>
          </ul>
          <div className="mt-4 mb-6 flex flex-col lg:flex-row">
            <div className="flex flex-col mr-3 my-4">
              <TextInput
                displayName="Date de début"
                className="w-50 lg:w-64 mx-1"
                type="date"
                mandatory="True"
                id="startcharte"
                name="startcharte"
                value={volunteerInformation.startcharte}
                onChange={handleChange}
                // required
              />
              {err && !volunteerInformation.startcharte && (
                <div className="mb-4 text-clStateError">Ce champ est obligatoire</div>
              )}
              {/* {getBirthDayError() && errorApeare && <div className="mt-2 text-clStateError">{getBirthDayError()}</div>} */}
            </div>
            <div className="flex flex-col mr-3 my-4">
              <TextInput
                displayName="Date de fin"
                className="w-50 lg:w-64 mx-1"
                type="date"
                id="endcharte"
                name="endcharte"
                min={minBirthdate}
                mandatory="True"
                value={volunteerInformation.endcharte}
                onChange={handleChange}
                // required
              />
              {err && !volunteerInformation.endcharte && (
                <div className="mb-4 text-clStateError">Ce champ est obligatoire</div>
              )}
              {/* {getBirthDayError() && errorApeare && <div className="mt-2 text-clStateError">{getBirthDayError()}</div>} */}
            </div>
          </div>

          {/* {!volunteerCommitmentCharter && ( */}
          <div className="flex flex-row mb-4">
            <div className="flex mr-3 items-center">
              <ToggleSwitch
                isChecked={acceptCommitmentCharter}
                // required
                onChange={() => {
                  setAcceptCommitmentCharter(!acceptCommitmentCharter);
                  setcharte(acceptCommitmentCharter);
                }}
              />
            </div>
            <label className="align-middle text-greyDark text-base">Signer la lettre d’engagement</label>
          </div>
          {/* )} */}
        </div>
      </div>
      {err && acceptCommitmentCharter && (!volunteerInformation.endcharte || !volunteerInformation.startcharte) ? (
        <div className="mb-4 text-clStateError">Veuillez remplir tous les champs obligatoire</div>
      ) : (
        err &&
        charte && (
          <div className="mb-4 text-clStateError">
            Veuillez signer la lettre d&apos;engagement avant de pouvoir continuer
          </div>
        )
      )}
      {timeerror && <div className="mb-4 text-clStateError">{timeerror}</div>}
      {errorMsg && <div className="mb-4 text-clStateError">{errorMsg}</div>}
      {!isLoading && (
        <div className="mt-auto">
          <button
            onClick={goToPreviousStep}
            className="w-85.75 lg:w-62.5 mt-auto mr-1 py-3 text-xl text-clWhite text-center font-medium rounded-8px disabled:bg-greyMedium disabled:opacity-16 bg-darkcolor">
            {previous}
          </button>
          <Button className="w-85.75 lg:w-62.5 mt-auto ml-1" color={btnColor} onClick={handlesubmit} isRegistrationForm>
            {btnTitle}
          </Button>
        </div>
      )}
      {isLoading && <Spinner className="mt-auto" />}
    </div>
  );
};

const styles = {
  dot: () => ({
    // position: 'absolute',
    // margin: '10px',
    height: '7px',
    width: '7px',
    backgroundColor: 'lightblue',
    borderRadius: '50%',
    display: 'block',
  }),
};
