import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {AvailableStep} from '../components/forms/AvailableStep';
import {ValidationStep} from '../components/forms/ValidationStep';
import {VolounteerCharte} from '../components/forms/VolounteerCharte';
import {VolunteerInformationForm} from '../components/forms/VolunteerInformationForm';
import {HeaderForm} from '../components/HeaderForm';
import {MeetingType} from '../enums/MeetingType';
import {Role} from '../enums/Role';
import {VolunteerInformation} from '../interfaces/VolunteerInformation';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {getStudentProfile, studentProfileLoadedSelector} from '../store/reducers/studentInfoSlice';
import {
  getVolunteerMeetingInfo,
  getVolunteerProfile,
  volunteerInfoSelector,
  volunteerProfileInfoLoadedSelector,
} from '../store/reducers/volunteerInfoSlice';
import {CheckDevices} from '../components/forms/CheckDevices';

export const VolunteerRegistrationMainForm = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const access_token = localStorage.getItem('access_token');
  const role = localStorage.getItem('role');

  const goToNextStep = () => {
    const step = currentStep + 1;
    setCurrentStep(step);
    sessionStorage.setItem('currentStep', JSON.stringify(step));
  };

  const goToPreviousStep = () => {
    const step = currentStep - 1;
    setCurrentStep(step);
    sessionStorage.setItem('currentStep', JSON.stringify(step));
  };

  const handleFinishStepClick = () => {
    sessionStorage.clear();
    dispatch(getVolunteerProfile());
    history.push('/dashboard/volunteer');
  };

  const studentProfileLoad: boolean = useAppSelector(studentProfileLoadedSelector);
  const volunteerProfileLoaded: boolean = useAppSelector(volunteerProfileInfoLoadedSelector);
  const volunteerInformation: VolunteerInformation = useAppSelector(volunteerInfoSelector);
  useEffect(() => {
    if (!studentProfileLoad) {
      if (role === Role.STUDENT) {
        dispatch(getStudentProfile());
      }
    }
    if (!volunteerProfileLoaded) {
      if (role === 'Teacher') {
        dispatch(getVolunteerProfile());
      }
    }
    if (volunteerProfileLoaded) {
      dispatch(
        getVolunteerMeetingInfo({
          [MeetingType.TRAINING]: volunteerInformation.trainingMeetingEvent,
          [MeetingType.INDIVIDUAL]: volunteerInformation.individualMeetingEvent,
          [MeetingType.INFORMATION]: volunteerInformation.informationMeetingEvent,
        }),
      );
    }
    if (sessionStorage.getItem('currentStep') !== null) {
      setCurrentStep(JSON.parse(sessionStorage.currentStep));
    }
  }, [
    dispatch,
    studentProfileLoad,
    volunteerProfileLoaded,
    role,
    volunteerInformation.trainingMeetingEvent,
    volunteerInformation.individualMeetingEvent,
    volunteerInformation.informationMeetingEvent,
  ]);

  const renderFormByStep = (): JSX.Element => {
    switch (currentStep) {
      case 1:
        return <VolunteerInformationForm goToNextStep={goToNextStep} isRegistrationForm access_token={access_token} />;
      case 2:
        return (
          <CheckDevices
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            isRegistrationForm
            access_token={access_token}
          />
        );
      case 3:
        return (
          <VolounteerCharte
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            access_token={access_token}
            isRegistrationForm
          />
        );
      // case 3:
      //   return (
      //     <VolunteerTutorForm
      //       goToNextStep={goToNextStep}
      //       goToPreviousStep={goToPreviousStep}
      //       isRegistrationForm
      //       access_token={access_token}
      //     />
      //   );
      case 4:
        return (
          <AvailableStep
            isStudent={false}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            isRegistrationForm
            access_token={access_token}
          />
        );
      default:
        return (
          <ValidationStep
            textButton="Se connecter"
            description="Vous pouvez consulter votre boîte mail et récupérer vos informations afin de vous connecter
            à votre compte."
            title="Votre inscription
            à bien été prise en compte."
            onBtnClick={handleFinishStepClick}
          />
        );
    }
  };

  return (
    <>
      <HeaderForm title="INSCRIPTION TUTEUR" currentStep={currentStep} nbStep={5} />
      <h1 className="block md:hidden pt-4 text-base text-mainPurple text-center font-semibold bg-clBackground">
        INSCRIPTION TUTEUR
      </h1>
      <div className="flex flex-col items-stretch h-auto min-h-form-content pt-11.5 md:pt-15.5 pb-4 bg-clBackground">
        {renderFormByStep()}
      </div>
    </>
  );
};
