import React from 'react';
import {GeneralPage} from './GeneralPage';
import imgLandingPage from '../assets/img/img_landingpage.png';

export const NotFoundPage = (): JSX.Element => {
  return (
    <GeneralPage bgSrc={imgLandingPage}>
      <div className="absolute left-17% top-18.5">
        <div className="mb-8">
          <h1 className="mb-4 text-3xl text-mainPurple font-bold">{`Cette page n'existe pas`}</h1>
        </div>
      </div>
    </GeneralPage>
  );
};
