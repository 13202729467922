import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from '../components/Button';
import {GeneralPage} from './GeneralPage';
import imgInscription from '../assets/img/img_inscription.png';

export const StudentRegistrationPage = (): JSX.Element => {
  const history = useHistory();

  return (
    <GeneralPage bgSrc={imgInscription}>
      <div className="absolute left-17% top-18.5">
        <div className="mb-8">
          <h1 className="mt-1.5 mb-4 mr-2 text-3xl text-mainPurple font-bold">
            JE SUIS ÉLÈVE,
            <br />
            JE CHERCHE UN TUTEUR
          </h1>
          <h2 className="mb-4 text-2xl text-greyMedium font-bold">
            Homeclasse.org, c&apos;est un site internet avec l&apos;accès
            <br />à des cours de soutien en ligne, des exercices,
            <br />
            des chansons et tout cela gratuitement !
          </h2>
          <p className="text-base text-greyMedium">
            Mais surtout 1h par semaine tu peux te connecter en visio
            <br />
            conférence avec un tuteur qui t&apos;ai dédié. Il a choisi d&apos;être à ton écoute
            <br />
            pour t&apos;aider à trouver des solutions pour mieux apprendre, pour
            <br />
            comprendre in thème que tu n&apos;as pas compris en classe ou pour
            <br />
            réviser avec toi.
          </p>
        </div>
        <div>
          <Button color="mainOrange" onClick={() => history.push('/registration/student/form')}>
            Je m&apos;inscris
          </Button>
        </div>
      </div>
    </GeneralPage>
  );
};
