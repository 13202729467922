import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {StudentSchoolInfo} from '../../interfaces/StudentSchoolInfo';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  schoolInfoSelector,
  studentErrorSelector,
  studentIdSelector,
  studentInfoActions,
  studentPendingSelector,
  studentSuccessSelector,
  updateStudentInfo,
} from '../../store/reducers/studentInfoSlice';
import {Button} from '../Button';
import {TextInput} from '../TextInput';
import {SelectInput} from '../SelectInput';
import {Spinner} from '../Spinner';
import {isZipCode} from '../../utils/utils';
import {ZipCodeInput} from '../ZipCodeInput';
import {Homelink} from '../Homelink';
import {useMediaQuery} from '../../hooks/Hook';
import ReactModal from 'react-modal';
import {userActions} from '../../store/reducers/userSlice';

export interface StudentSchoolInfoFormProps {
  isRegistrationForm?: boolean;
  goToNextStep?: () => void;
  goToPreviousStep?: () => void;
  access_token?: string | null;
}

const renderTitle = (isRegistrationForm?: boolean): JSX.Element => {
  if (isRegistrationForm) {
    return (
      <>
        <h2 className="mb-6.5 text-2xl text-greyMedium font-bold">Établissement de l’élève</h2>
      </>
    );
  } else {
    return (
      <>
        <h2 className="mb-8 lg:mb-4 text-3xl text-mainPurple font-bold">MON ÉTABLISSEMENT</h2>
      </>
    );
  }
};

export const StudentSchoolInfoForm = ({
  goToNextStep,
  goToPreviousStep,
  isRegistrationForm,
  access_token,
}: StudentSchoolInfoFormProps): JSX.Element => {
  const [errorApeare, seterrorApeare] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const schoolInfo: StudentSchoolInfo = useAppSelector(schoolInfoSelector);
  const studentId: string = useAppSelector(studentIdSelector);
  const errorMsg: string = useAppSelector(studentErrorSelector)['updateStudentInfo'] || '';
  const isLoading: boolean = useAppSelector(studentPendingSelector)['updateStudentInfo'] || false;
  const isSuccess: boolean = useAppSelector(studentSuccessSelector)['updateStudentInfo'] || false;

  useEffect(() => {
    dispatch(studentInfoActions.resetRequestApiState());
  }, [dispatch]);

  useEffect(() => {
    if (isRegistrationForm && isSuccess && goToNextStep) {
      dispatch(studentInfoActions.resetRequestApiState());
      goToNextStep();
    }
  }, [dispatch, isRegistrationForm, isSuccess, goToNextStep]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSchoolInfo: StudentSchoolInfo = {
      ...schoolInfo,
      [event.target.name]: event.target.value,
    };

    dispatch(studentInfoActions.saveStudentSchoolInfo(newSchoolInfo));
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(studentInfoActions.resetStudentInfoState());
    dispatch(userActions.resetUserState());
    window.location.href = '/registration/student/form';
  };

  const [close, setclose] = useState(false);

  const sendData = () => {
    if (canContinue()) {
      seterrorApeare(false);
      dispatch(studentInfoActions.resetRequestApiState());
      dispatch(
        updateStudentInfo({
          id: studentId,
          request: {
            schoolName: schoolInfo.schoolName,
            schoolPostalCode: schoolInfo.schoolPostCode,
            schoolCity: schoolInfo.schoolCity,
            origin: schoolInfo.knownHomeClasseBy,
          },
        }),
      );
    } else seterrorApeare(true);
    setclose(false);
  };
  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    sendData();
  };

  const canContinue = (): boolean => {
    return (
      Boolean(schoolInfo.schoolName) &&
      Boolean(schoolInfo.schoolPostCode) &&
      Boolean(schoolInfo.schoolCity) &&
      isZipCode(schoolInfo.schoolPostCode) &&
      schoolInfo.schoolPostCode.length === 5
    );
  };

  const btnColor = !isRegistrationForm && isSuccess ? 'clValidate' : 'mainOrange';
  const btnTitle = isRegistrationForm ? 'Continuer' : !isRegistrationForm && isSuccess ? 'Enregistré !' : 'Enregistrer';
  const isabove1024 = useMediaQuery('(max-width: 1024px)');

  return (
    <form className="flex-1 h-full w-85.75 lg:w-131.25 mx-auto flex flex-col items-center" onSubmit={handleSubmit}>
      <div className="w-full p-8 mb-21.75 bg-clWhite rounded-8px shadow-formRectangle">
        {renderTitle(isRegistrationForm)}
        <TextInput
          type="text"
          name="schoolName"
          displayName="Nom de l’établissement scolaire"
          mandatory="True"
          id="schoolName"
          value={schoolInfo.schoolName}
          onChange={handleChange}
          // required
          className="w-full mb-6"
        />
        {!schoolInfo.schoolName && errorApeare && (
          <div className="mb-4 text-clStateError">Le champ nom de l&apos;établissement n&apos;est pas rempli.</div>
        )}
        <div className="flex flex-col lg:flex-row">
          <div className="flex-col">
            <ZipCodeInput
              name="schoolPostCode"
              displayName="Code postal"
              mandatory="True"
              id="schoolPostCode"
              value={schoolInfo.schoolPostCode}
              onChange={handleChange}
              // required
              className="w-27.5 lg:mr-4"
            />
            {!schoolInfo.schoolPostCode && errorApeare && (
              <div className="mb-4 text-clStateError">Le champ code postal n&apos;est pas rempli.</div>
            )}
          </div>
          <div>
            <TextInput
              type="text"
              name="schoolCity"
              displayName="Ville"
              mandatory="True"
              id="schoolCity"
              value={schoolInfo.schoolCity}
              onChange={handleChange}
              // required
              className="w-full lg:w-80"
            />
            {!schoolInfo.schoolCity && errorApeare && (
              <div className="text-clStateError">Le champ ville n&apos;est pas rempli.</div>
            )}
          </div>
        </div>
      </div>
      {errorMsg && <div className="mb-4 text-clStateError">{errorMsg}</div>}
      {!isLoading && (
        <>
          <Button className="w-85.75 lg:w-115 mt-auto" color={btnColor} type="submit" isRegistrationForm>
            {btnTitle}
          </Button>
        </>
      )}
      {!isRegistrationForm && <Homelink />}
      {isLoading && <Spinner className="mt-auto" />}
      <ReactModal
        className="absolute w-85.75 md:w-auto top-40 inset-x-center-modal-mobile md:inset-x-1/3 p-8 bg-clWhite rounded-8px border-none overflow-auto outline-none"
        overlayClassName="fixed inset-0 bg-greyDark bg-opacity-50 overflow-auto z-50"
        isOpen={close}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}>
        <div>
          <div className="flex flex-col text-greyMedium" style={{alignItems: 'center'}}>
            <h3 className="my-2 text-clStateError" style={{fontSize: 'revert'}}>
              Attention
            </h3>
            <h4 className="mt-2 mb-4 text-greyMedium" style={{fontSize: 'revert'}}>
              vous êtes déjà connecté si vous souhaitez créer un nouveau compte veuillez sélectionner déconnexion si
              vous souhaitez mettre à jour vos informations veuillez sélectionner continuer
            </h4>
            <div className="flex justify-around relative" style={{width: '100%'}}>
              <button
                className="close bg-mainOrange rounded w-27.5"
                style={{padding: '0.7rem 0', color: 'white'}}
                onClick={sendData}>
                Continuer
              </button>
              <button
                className="close bg-mainOrange rounded w-27.5"
                style={{padding: '0.7rem 0', color: 'white'}}
                onClick={handleLogout}>
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </form>
  );
};
