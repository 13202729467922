import React, {ChangeEvent, InputHTMLAttributes} from 'react';
import Cleave from 'cleave.js/react';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  displayName: string;
  prefix?: string;
  mandatory?: string;
}

export const TextInput = ({
  displayName,
  id,
  className,
  prefix,
  type,
  mandatory,
  ...rest
}: TextInputProps): JSX.Element => {
  const defaultClassName = `mt-1 h-11.5 px-4 py-3 border-2 border-greyLight outline-none`;
  const combinedClassName = className ? `${defaultClassName} ${className}` : defaultClassName;

  return (
    <div className="flex flex-col relative">
      <label className="text-base text-greyMedium font-medium" htmlFor={id}>
        {displayName}
        {mandatory == 'True' ? (
          <>
            <span className="mandatory-star">*</span>
          </>
        ) : (
          <></>
        )}
      </label>
      {type === 'date' ? (
        <>
          <input
            className={combinedClassName}
            // placeholder="DD/MM/AAAA"
            type="date"
            // options={{date: true, datePattern: ['d', 'm', 'Y']}}
            {...rest}
          />
        </>
      ) : prefix === '+33' ? (
        <div className="flex">
          <div
            className="mt-1 h-11.5 px-4 py-3 border-2 border-greyLight outline-none w-full lg:w-55.5"
            style={{
              border: 'solid 2px',
              width: '3rem',
              paddingLeft: '0.5rem',
              borderColor: 'rgba(240, 240, 240, var(--tw-border-opacity))',
              borderRight: '0',
            }}>
            {prefix}
          </div>
          <div
            className="mt-1"
            style={{
              width: '10px',
              border: 'solid 2px',
              borderColor: 'rgba(240, 240, 240, var(--tw-border-opacity))',
              borderRight: '0',
              borderLeft: '0',
              padding: '10px 0',
            }}>
            <div
              style={{
                height: '1.3rem',
                width: '2px',
                backgroundColor: 'rgba(240, 240, 240, var(--tw-border-opacity))',
              }}></div>
          </div>
          <input
            className={combinedClassName}
            id={id}
            autoComplete="off"
            style={{borderLeft: 0}}
            type={type}
            {...rest}
          />
        </div>
      ) : (
        <input className={combinedClassName} id={id} autoComplete="off" type={type} {...rest} />
      )}
    </div>
  );
};
