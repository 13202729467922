import React, {useState, useEffect, ChangeEvent, useLayoutEffect} from 'react';
import ReactModal from 'react-modal';
import {Link, useHistory} from 'react-router-dom';
import {
  IconCalendarXL,
  IconCheckXL,
  IconCopy,
  IconDossierOnXL,
  IconInformationsOnXL,
  IconUserBoy,
  IconUserGirl,
  IconUserTeacherMan,
  IconUserTeacherWoman,
  IconCnteRendu,
} from '../assets';
import {Button} from '../components/Button';
import {Checkbox} from '../components/Checkbox';
import {Spinner} from '../components/Spinner';
import {Gender} from '../enums/Gender';
import {MeetingType} from '../enums/MeetingType';
import {VolunteerDocumentType} from '../enums/VolunteerDocumentType';
import {VolunteerValidatedStatus} from '../enums/VolunteerValidatedStatus';
import {StudentAssigned} from '../interfaces/api/StudentAssigned';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {userActions, getUrlMaxicoursMascarade, ssoUrlSelector} from '../store/reducers/userSlice';
import {SelectInput} from '../components/SelectInput';
import {
  updateVolunteerInfo,
  volunteerAvailabilitySelector,
  volunteerCommitmentCharterSelector,
  volunteerDocumentsSelector,
  volunteerErrorSelector,
  volunteerIdSelector,
  volunteerInfoActions,
  volunteerInfoSelector,
  volunteerMeetingInfoSelector,
  volunteerPendingSelector,
  volunteerResponsabilitySelector,
  volunteerStatusSelector,
  volunteerStudentsAssignedSelector,
} from '../store/reducers/volunteerInfoSlice';
import '../styles/IconUser.scss';
import {formatDateMeeting, formatDateMeetingSeance0} from '../utils/dateUtils';
import {copyToClipboard} from '../utils/utils';
import appText from '../assets/intl/fr';
import {AvailabilityCalendar} from '../interfaces/AvailabilityCalendar';
import Select, {components, ControlProps, OptionProps, StylesConfig} from 'react-select';
import {CustomOptions} from '../interfaces/VolunteerStudentOptions';

const informationMeetingUrl = process.env.REACT_APP_MEETING_VOLUNTEER_INFORMATION || '';

const renderStudentBlock = (student?: StudentAssigned, volunteerStatus?: string): JSX.Element => (
  <>
    <div className="flex flex-row items-center">
      {(student && student.gender) === Gender.FEMME ? (
        <IconUserGirl className="w-10 h-10 icon-student" />
      ) : (
        <IconUserBoy className="w-10 h-10 icon-student" />
      )}
      <p className="ml-2.75 text-base text-greyMedium font-bold">Mon élève</p>
    </div>
    <div className="mt-8 flex flex-col h-full items-center justify-center text-center">
      {volunteerStatus === 'Séance 0 planifiée' ? (
        <p className="text-base text-greyMedium font-medium"> Veuillez consulter vos mails ou spams. </p>
      ) : student ? (
        <>
          <p className="text-2xl text-greyDark">
            {student.firstName}{' '}
            <span className="font-bold">
              {student.lastName} - {student.className}
            </span>
          </p>
          <p className="text-base text-greyMedium font-medium">{student.phoneNumber}</p>
          <p className="text-base text-greyMedium font-medium">{student.email}</p>
        </>
      ) : (
        <p className="text-base text-greyMedium font-medium">
          Un peu de patience, nous allons bientôt vous attribuer un élève.
        </p>
      )}
    </div>
  </>
);

const displayTextStatut = (info?: string) => {
  switch (info) {
    case 'Nouveau':
    case 'Incomplet relance 1':
    case 'Incomplet relance 2':
      return (
        <>
          <span className="text-xl pl-5 textcolor_dashboardOZDC">
            Merci de votre inscription ! Pensez à compléter votre dossier :-)
          </span>
        </>
      );
    case 'En attente élève 1':
      return (
        <>
          <span className="text-xl pl-5 textcolor_dashboardOZDC">
            Nous recherchons votre élève ! Merci de suivre la formation.
          </span>
        </>
      );
    case 'Complet':
    case 'OK':
      return (
        <>
          <span className="text-xl pl-5 textcolor_dashboardOZDC">
            Votre dossier est complet ! Nous recherchons votre élève.
          </span>
        </>
      );
    case 'Elève 1 trouvé':
      return (
        <>
          <span className="text-xl pl-5 textcolor_dashboardVert">
            Nous vous avons trouvé un élève, nous vous le présenterons bientôt !
          </span>
        </>
      );
    case 'Séance 0 planifiée':
      return (
        <>
          <span className="text-xl pl-5 textcolor_dashboardVert">
            Votre séance 0 est planifiée ! Regardez dans vos emails :-)
          </span>
        </>
      );
    case 'Tutorat 1 en cours':
      return (
        <>
          <span className="text-xl pl-5 textcolor_dashboardVert">Vos séances de tutorat suivent leur cours !</span>
        </>
      );
    case 'Rentrée 23/24':
    case 'Ne donne pas suite':
    case 'Non':
      return (
        <>
          <span className="text-xl pl-5 textcolor_dashboardRouge">Votre tutorat est annulé</span>
        </>
      );
    case 'Arret':
    case 'Arrêt':
    case 'Pause':
    case 'A contacter':
      return (
        <>
          <span className="text-xl pl-5 textcolor_dashboardRouge">Votre tutorat est interrompu.</span>
        </>
      );
    case 'A renouveler':
      return (
        <>
          <span className="text-xl pl-5 textcolor_dashboardOZDC">Merci d&apos;actualiser votre profil</span>
        </>
      );
    default:
      break;
  }
};

const showNextMeeting = (info?: string) => {
  switch (info) {
    default:
    case 'Nouveau':
    case 'Incomplet relance 1':
    case 'Incomplet relance 2':
    case 'En attente élève 1':
    case 'Complet':
    case 'OK':
    case 'Elève 1 trouvé':
      return false;
    case 'Séance 0 planifiée':
    case 'Tutorat 1 en cours':
    case 'Rentrée 23/24':
    case 'Ne donne pas suite':
    case 'Non':
    case 'Arret':
    case 'Arrêt':
    case 'Pause':
    case 'A contacter':
    case 'A renouveler':
      return true;
  }
};

const renderNextMeetingBlock = (student?: StudentAssigned, volunteerStatus?: string): JSX.Element => (
  <>
    <div className="flex flex-row items-center">
      <IconCalendarXL />
      <p className="ml-2.75 text-base text-greyMedium font-bold">Prochain rendez-vous</p>
    </div>
    <div className="mt-8 flex flex-col h-full items-center justify-center text-center">
      {showNextMeeting(volunteerStatus) && student ? (
        <>
          {student.seance0 ? (
            <>
              <p className="mb-3.5 text-xl text-greyDark">{formatDateMeetingSeance0(student.seance0)}</p>
            </>
          ) : (
            <p className="mb-3.5 text-xl text-greyDark">
              {formatDateMeeting(student.nextTutorStart, student.nextTutorEnd)}
            </p>
          )}
          <Button
            className="mb-5.75"
            color="mainOrange"
            onClick={() => {
              const win = window.open(student.tutorLink, '_blank');
              win && win.focus();
            }}>
            Je rejoins mon élève
          </Button>
          <div className="flex flex-row items-center">
            <Link to={{pathname: student.tutorLink}} target="_blank" className="mr-4 text-xs text-greyMedium">
              {student.tutorLink}
            </Link>
            <IconCopy className="cursor-pointer" onClick={() => copyToClipboard(student.tutorLink)} />
          </div>
        </>
      ) : (
        <p className="text-base text-greyMedium font-medium">
          Les rendez-vous hebdomadaires s’afficheront ici dès qu’un élève vous sera attribué.
        </p>
      )}
    </div>
  </>
);

const renderCompteRendu = (student?: StudentAssigned): JSX.Element => (
  <>
    <div className="flex flex-row items-center">
      <IconCnteRendu />
      <p className="ml-2.75 text-base text-greyMedium font-bold">Compte rendu</p>
    </div>
    <div className="mt-8 flex flex-col h-full items-center justify-center text-center">
      {student ? (
        <>
          <p className="mb-3.5 text-xl text-greyDark">Rédiger un compte rendu</p>
          <Button
            className="mb-5.75 w-72.5"
            color="mainOrange"
            onClick={() => {
              const win = window.open('/compteRendu/volunteer', '_self');
              win && win.focus();
            }}>
            Accéder
          </Button>
        </>
      ) : (
        <p className="text-base text-greyMedium font-medium">
          Accédez et rédigez vos comptes rendu ici dès qu’un élève vous sera attribué.
        </p>
      )}
    </div>
  </>
);

export const DashboardVolunteer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const volunteerInfo = useAppSelector(volunteerInfoSelector);
  const volunteerId = useAppSelector(volunteerIdSelector);
  const volunteerMeetingInfo = useAppSelector(volunteerMeetingInfoSelector);
  const volunteerDocuments = useAppSelector(volunteerDocumentsSelector);
  const volunteerStatus = useAppSelector(volunteerStatusSelector);
  const volunteerCommitmentCharter: boolean = useAppSelector(volunteerCommitmentCharterSelector);
  const volunteerStudentsAssigned = useAppSelector(volunteerStudentsAssignedSelector);
  const volunteerAvailablility = useAppSelector(volunteerAvailabilitySelector);
  const volunteerResponsability = useAppSelector(volunteerResponsabilitySelector);

  const [currentStudentIndex, setCurrentStudentIndex] = useState(0);
  const [acceptCommitmentCharter, setAcceptCommitmentCharter] = useState(false);
  const [studentsMaxicoursConnect, setStudentsMaxicoursConnect] = useState<Array<string>>([]);
  const [studentMaxicoursConnect, setStudentMaxicoursConnect] = useState<StudentAssigned>();

  const apiName = 'updateVolunteerInfo';
  const errorMsg: string = useAppSelector(volunteerErrorSelector)[apiName] || '';
  const isLoading: boolean = useAppSelector(volunteerPendingSelector)[apiName] || false;
  const ssoUrl = useAppSelector(ssoUrlSelector);

  const isAvailabilityCompleted: boolean =
    Boolean(
      volunteerAvailablility.availabilitiesCalendar.forEach((elem: AvailabilityCalendar) => {
        if (elem.three) return true;
        if (elem.four) return true;
        if (elem.five) return true;
        if (elem.six) return true;
        if (elem.seven) return true;
        return false;
      }),
    ) && true;

  const isResponsabilityCompleted: boolean =
    Boolean(volunteerResponsability.nbStudentAccompanied) && volunteerResponsability.levelStudent.length > 0;

  const isDocumentsCompleted: boolean = volunteerDocuments[VolunteerDocumentType.CRIMINAL_RECORDS]?.length > 0;

  const isInfoCompleted: boolean =
    Boolean(volunteerInfo.firstName) &&
    Boolean(volunteerInfo.lastName) &&
    Boolean(volunteerInfo.email) &&
    Boolean(volunteerInfo.gender) &&
    Boolean(volunteerInfo.phoneNumber) &&
    Boolean(volunteerInfo.situation);

  const handleSignOut = () => {
    localStorage.clear();
    dispatch(volunteerInfoActions.resetVolunteerInfoState());
    dispatch(userActions.resetUserState());
    window.location.href = 'https://zupdeco.homeclasse.org/';
  };

  const trainingMeetingEvent = volunteerMeetingInfo[MeetingType.TRAINING];

  const handleAgreeToConvention = () => {
    dispatch(volunteerInfoActions.updateVolunteerCommitmentCharter(true));
    dispatch(volunteerInfoActions.resetRequestApiState());
    dispatch(
      updateVolunteerInfo({
        id: volunteerId,
        request: {
          commitmentCharter: true,
        },
      }),
    );
  };

  const isValidated = Object.values(VolunteerValidatedStatus).some(
    (status) => status.localeCompare(volunteerStatus || '', 'en', {sensitivity: 'base'}) === 0,
  );
  const btnColor = errorMsg.length > 0 ? 'clDeleteError' : 'mainOrange';

  const handleCompleteInfoClick = () => {
    if (!isInfoCompleted) {
      history.push('/profile/volunteer/informations');
    } else if (!isResponsabilityCompleted) {
      history.push('/profile/volunteer/tutor');
    } else if (!isAvailabilityCompleted) {
      history.push('/profile/volunteer/availability');
    } else {
      history.push('/profile/volunteer/documents');
    }
  };

  useEffect(() => {
    const volunteerStudentsAssignedList: string[] = [];
    volunteerStudentsAssigned.map((student, idx) =>
      volunteerStudentsAssignedList.push(student.firstName.concat(' ').concat(student.lastName)),
    );
    setStudentsMaxicoursConnect(volunteerStudentsAssignedList);
  }, []);

  useLayoutEffect(() => {
    if (ssoUrl) {
      const win = window.open(ssoUrl, '_blank');
      win && win.focus();
      dispatch(userActions.resetUserState());
    }
  }, [dispatch, ssoUrl]);

  const handleStudentMaxicoursConnectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const targetValue = event.target.value;
    const res = volunteerStudentsAssigned.find((element) => {
      return element.firstName.concat(' ').concat(element.lastName) === targetValue;
    });
    setStudentMaxicoursConnect(res);
  };

  const handleOpenMaxicours = () => {
    if (studentMaxicoursConnect) dispatch(getUrlMaxicoursMascarade(studentMaxicoursConnect));
  };

  const renderMaxicoursEleve = (students?: StudentAssigned[]): JSX.Element => (
    <>
      <div className="flex flex-row items-center">
        <IconCnteRendu />
        <p className="ml-2.75 text-base text-greyMedium font-bold">Compte MaxiCours de mes élèves</p>
      </div>
      <div className="mt-8 flex flex-col h-full items-center justify-center text-center">
        {students ? (
          <>
            <SelectInput
              label=""
              name="studentsMaxicoursConnect"
              id="studentsMaxicoursConnect"
              defaultEmptyValueTitle="--Choisissez votre élève--"
              options={studentsMaxicoursConnect}
              onChange={handleStudentMaxicoursConnectChange}
              className="w-full lg:w-55.5"
            />
            <p className="mb-3.5 text-xl text-greyDark"></p>
            {studentMaxicoursConnect && (
              <Button className="mb-5.75 w-72.5" color="mainOrange" onClick={handleOpenMaxicours}>
                Accéder
              </Button>
            )}
            {!studentMaxicoursConnect && (
              <Button className="mb-5.75 w-72.5" color="mainOrange" disabled>
                Accéder
              </Button>
            )}
          </>
        ) : (
          <p className="text-base text-greyMedium font-medium">
            Accédez au compte Maxicours de vos élèves dès qu’un élève vous sera attribué.
          </p>
        )}
      </div>
    </>
  );

  /* Dropdown de sélection des élèves */

  const customOptions: {value: string; label: string; gender: string}[] = [];
  volunteerStudentsAssigned.forEach((student, idx) =>
    customOptions.push({
      label: student.firstName + ' ' + student.lastName + ' - ' + student.className,
      value: String(idx),
      gender: student.gender,
    }),
  );
  /* Gestion de l'affichage de l'icon élève */
  const Control = ({children, ...props}: ControlProps<CustomOptions, false>) => {
    return (
      <div>
        <components.Control {...props}>
          <span>
            {customOptions[currentStudentIndex].gender === Gender.HOMME ? (
              <IconUserBoy className="hidden md:block w-10 h-10 icon-student" />
            ) : (
              <IconUserGirl className="hidden md:block w-10 h-10 icon-student" />
            )}
          </span>
          {children}
        </components.Control>
      </div>
    );
  };
  /* On ajoute ici la checkmark de l'option séléctionnée */
  const Option = ({...props}: OptionProps<CustomOptions, false>) => {
    return (
      <div className="check">
        <components.Option {...props}>
          {props.isSelected ? (
            <form action="">
              <input type="checkbox" checked />
              <label>{props.children}</label>
            </form>
          ) : (
            <form action="">
              <input type="checkbox" style={{visibility: 'hidden', paddingRight: '18px'}} />
              <label>{props.children}</label>
            </form>
          )}
        </components.Option>
      </div>
    );
  };
  /* Custom CSS pour les différents élements de Select*/
  const customStyles: StylesConfig<CustomOptions, false> = {
    option: (css, {isFocused}) => ({
      ...css,
      backgroundColor: isFocused ? '#EEE' : 'white',
      color: 'black',
    }),
    control: (css) => ({
      ...css,
      paddingLeft: '1rem',
      height: '60px',
      border: 0,
      boxShadow: 'none',
      borderRadius: 9,
    }),
    container: (css) => ({
      ...css,
      minWidth: '200px',
    }),
  };
  const content = isValidated ? (
    <>
      <div className="max-w-235 flex flex-row flex-wrap mx-4 mb-4">
        {volunteerStudentsAssigned.length > 0 ? (
          <>
            {/* Dropdown élève */}
            <Select
              options={customOptions}
              defaultValue={customOptions[0]}
              isMulti={false}
              onChange={(selected) => {
                const idx = selected ? +selected.value : 0;
                setCurrentStudentIndex(idx); // Pour update renderStudentBlock
              }}
              styles={customStyles}
              components={{Control, Option, IndicatorSeparator: () => null}}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="max-w-235 flex flex-col md:flex-row md:flex-wrap sm:m-4">
        <div className="flex flex-col md:w-115 bg-clWhite p-8 rounded-8px">
          {volunteerStudentsAssigned
            ? renderStudentBlock(volunteerStudentsAssigned[currentStudentIndex], volunteerStatus)
            : renderStudentBlock()}
        </div>
        <div className="flex flex-col md:w-115 bg-clWhite p-8 mt-5 md:mt-0 md:ml-5 rounded-8px">
          {volunteerStudentsAssigned
            ? renderNextMeetingBlock(volunteerStudentsAssigned[currentStudentIndex], volunteerStatus)
            : renderNextMeetingBlock()}
        </div>
        <div className="flex flex-col md:w-115 bg-clWhite p-8 mt-5 md:mt-0 md:mt-5 rounded-8px">
          {volunteerStudentsAssigned
            ? renderCompteRendu(volunteerStudentsAssigned[currentStudentIndex])
            : renderCompteRendu()}
        </div>
        <div className="flex flex-col md:w-115 bg-clWhite p-8 mt-5 md:mt-0 md:ml-5 md:mt-5 rounded-8px">
          {volunteerStudentsAssigned ? renderMaxicoursEleve(volunteerStudentsAssigned) : renderMaxicoursEleve()}
        </div>
      </div>
      <ReactModal
        className="absolute w-85.75 md:w-auto top-20 inset-x-center-modal-mobile md:inset-x-1/3 p-8 bg-clWhite rounded-8px border-none overflow-auto"
        overlayClassName="fixed inset-0 bg-greyDark bg-opacity-50 overflow-auto z-50"
        isOpen={!volunteerCommitmentCharter}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}>
        <div>
          <h1 className="mb-4 text-2xl text-greyMedium font-bold">
            Sur toute ma période d&apos;engagement, je m&apos;engage à :
          </h1>
          <ul>
            <li className="mb-4 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-4">Participer activement à mes temps de formation</p>
            </li>
            <li className="mb-4 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-4">Être assidu(e) dans la réalisation de mon tutorat</p>
            </li>
            <li className="mb-4 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-4">
                Prendre contact sans délai avec l&apos;équipe HomeClasse pour toute question et pour l&apos;informer de
                toute difficulté (problème personnel, absence….)
              </p>
            </li>
            <li className="mb-4 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-4">Rattraper mes séances annulées</p>
            </li>
            <li className="mb-4 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-4">
                Réaliser mes comptes rendus de séance et bilans trimestriels via les outils à ma disposition
              </p>
            </li>
            <li className="mb-4 text-base flex items-baseline">
              <div style={{width: '10px'}}>
                <div style={styles.dot()}></div>
              </div>
              <p className="text-greyMedium mx-4 ">
                Donner mon accord pour une utilisation de mon image sur internet ou tout autre support de communication
                lié à HomeClasse
              </p>
            </li>
          </ul>
          <Checkbox
            className="mb-13 mt-13"
            name="agreeToConvention"
            value="Je m'engage à respecter la charte HomeClasse."
            checked={acceptCommitmentCharter}
            onCheckBoxClick={() => setAcceptCommitmentCharter(!acceptCommitmentCharter)}
            // isMainColor={true}
            black={true}
          />
          <div className="w-full flex justify-center">
            {errorMsg && <div className="mb-4 text-clStateError">{errorMsg}</div>}
            {!isLoading && (
              <Button
                className="w-full"
                color={btnColor}
                onClick={handleAgreeToConvention}
                disabled={!acceptCommitmentCharter}>
                Valider
              </Button>
            )}
            {isLoading && <Spinner className="mt-auto" />}
          </div>
        </div>
      </ReactModal>
    </>
  ) : (
    <>
      <div className="flex flex-col md:flex-row md:flex-wrap sm:m-4">
        <div className="flex flex-col md:w-115 bg-clWhite p-8 rounded-8px">
          <p className="font-bold text-4xl text-greyMedium mb-4">{appText.volunteerDashboard.welcomeMessage.title}</p>
          <p className="text-xl text-greyMedium mb-1">
            {appText.volunteerDashboard.welcomeMessage.sentence1}
            <br />
            {appText.volunteerDashboard.welcomeMessage.sentence2}
            <br />
            {appText.volunteerDashboard.welcomeMessage.sentence3}
            <br />
            {appText.volunteerDashboard.welcomeMessage.sentence4}
          </p>
        </div>
        <div className="mt-5 sm:mt-0 md:ml-5 flex flex-col">
          <div className="flex flex-col md:w-115 bg-clWhite p-8 mb-4 rounded-8px">
            <div className="flex flex-row items-center">
              <IconInformationsOnXL></IconInformationsOnXL>
              <span className="font-bold text-base text-greyMedium">Mes informations</span>
            </div>
            <div className="flex flex-row items-center justify-center mt-8">
              {isInfoCompleted && isAvailabilityCompleted && isResponsabilityCompleted && isDocumentsCompleted ? (
                <>
                  <span>Tes informations sont complètes</span>
                  <IconCheckXL></IconCheckXL>
                </>
              ) : (
                <Button color="mainOrange" onClick={() => handleCompleteInfoClick()}>
                  Compléter
                </Button>
              )}
            </div>
          </div>
          <div className="flex flex-col md:w-115 bg-clWhite p-8 rounded-8px">
            <div className="flex flex-row items-center">
              <IconDossierOnXL></IconDossierOnXL>
              <span className="font-bold text-base text-greyMedium">Mes documents</span>
            </div>
            <div className="flex flex-row items-center justify-center mt-8">
              {isDocumentsCompleted ? (
                <>
                  <span>Tes documents ont été transmis</span>
                  <IconCheckXL></IconCheckXL>
                </>
              ) : (
                <Button color="mainOrange" onClick={() => history.push('/profile/volunteer/documents')}>
                  Compléter
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mx-0 md:mx-4 mt-8 md:mt-0 md:flex-row">
        <div className="flex flex-col md:flex-row sm:my-4">
          <div className="flex flex-col md:w-115 bg-clWhite p-8 mb-10 rounded-8px">
            <div className="flex flex-row items-center">
              <IconCalendarXL></IconCalendarXL>
              <span className="font-bold text-base text-greyMedium">Réunion d’information</span>
            </div>
            <div className="flex flex-col items-center justify-center mt-8">
              {trainingMeetingEvent ? (
                <>
                  <span className="mb-2">
                    {formatDateMeeting(trainingMeetingEvent.start_time, trainingMeetingEvent.end_time)}
                  </span>
                  <Link to={{pathname: trainingMeetingEvent.url}} target="_blank">
                    <Button className="mb-2 px-6" color="mainOrange">
                      Rejoindre la réunion
                    </Button>
                  </Link>
                  <div className="flex flex-row items-center">
                    <div className="text-center text-xs text-greyMedium">{trainingMeetingEvent.url}</div>
                    <IconCopy
                      className="cursor-pointer"
                      onClick={() => copyToClipboard(trainingMeetingEvent.url)}></IconCopy>
                  </div>
                </>
              ) : (
                <>
                  <span className="mb-2 text-center">
                    {'Si vous n’avez pas encore participé à une réunion d’information'}
                  </span>
                  <Button
                    className="mb-2"
                    color="mainOrange"
                    onClick={() => history.push(`/dashboard/meeting/${MeetingType.INFORMATION}`)}>
                    Choisir une date
                  </Button>
                  <div className="flex flex-row items-center">
                    <div className="text-center text-xs text-greyMedium">{informationMeetingUrl}</div>
                    <IconCopy
                      className="cursor-pointer"
                      onClick={() => copyToClipboard(informationMeetingUrl)}></IconCopy>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="bg-clBackground w-full overflow-hidden">
        <div className="pt-20 m-4 lg:m-0 flex flex-col items-center">
          <div>
            <div className="flex flex-col mb-16">
              <div className="flex flex-row items-center">
                <div className="flex items-center w-14">
                  {volunteerInfo.gender === Gender.FEMME ? (
                    <IconUserTeacherWoman className="h-23 icon-teacher" />
                  ) : (
                    <IconUserTeacherMan className="h-23 icon-teacher" />
                  )}
                </div>
                <div className="ml-2.5 flex flex-col">
                  <p className="text-mainPurple text-4xl" style={{wordBreak: 'break-word'}}>
                    {volunteerInfo.firstName}&nbsp;
                    <span className="ml-0.75 font-bold">{volunteerInfo.lastName} - Tuteur</span>
                  </p>
                  <Link className="text-base text-greyMedium cursor-pointer" to="#" onClick={handleSignOut}>
                    Déconnexion
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex max-w-235 bg-clWhite p-8 md:ml-5 rounded-8px font-bold text-xl align-items-baseline text-greyMedium mb-4 statut">
              Statut d&apos;inscription:&nbsp; {displayTextStatut(volunteerStatus)}
            </div>
            {content}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  dot: () => ({
    // position: 'absolute',
    // margin: '10px',
    height: '7px',
    width: '7px',
    backgroundColor: 'lightblue',
    borderRadius: '50%',
    display: 'block',
  }),
};
