import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from '../components/Button';
import {GeneralPage} from './GeneralPage';
import imgLandingPage from '../assets/img/img_landingpage.png';

export const HomePage = (): JSX.Element => {
  const history = useHistory();
  sessionStorage.clear();

  return (
    <GeneralPage bgSrc={imgLandingPage}>
      <div className="absolute left-17% top-18.5">
        <div className="mb-8">
          <h1 className="mb-4 text-3xl text-mainPurple font-bold">
            HOMECLASSE,
            <br />
            LA RÉUSSITE ENSEMBLE
          </h1>
          <h2 className="mb-4 text-2xl text-greyMedium font-bold">
            Homeclasse.org, c&apos;est un site internet avec l&apos;accès
            <br />à des cours de soutien en ligne, des exercices,
            <br />
            des chansons et tout cela gratuitement !
          </h2>
          <p className="text-base text-greyMedium">
            Mais surtout 1h par semaine tu peux te connecter en visio
            <br />
            conférence avec un tuteur qui t&apos;ai dédié.
          </p>
        </div>
        <div>
          <Button className="mr-4" color="mainPurple" onClick={() => history.push('/registration/student')}>
            Inscription élève
          </Button>
          <Button color="mainOrange" onClick={() => history.push('/registration/volunteer')}>
            Inscription bénévole
          </Button>
        </div>
      </div>
    </GeneralPage>
  );
};
