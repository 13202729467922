export enum OptionYesNo {
  OUI = 'Oui',
  NON = 'Non',
  JSP = 'Je ne sais pas',
  PE = 'Peut-être',
}

export enum ReunionParticipe {
  OUI = 'Oui',
  NON = 'Non',
  NULL = '',
}

// export enum requirementForReunion {
//   OUI = 'Oui',
//   NON = 'Non',
// }
