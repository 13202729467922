import React from 'react';
import {Link} from 'react-router-dom';
import {IconUserBoy, IconUserTeacherMan, IconUserGirl, IconUserTeacherWoman} from '../assets';
import {menuAuthenticatedItems} from '../constants/MenuAuthenticatedItems';
import {Gender} from '../enums/Gender';
import {Role} from '../enums/Role';
import {useAppDispatch} from '../store/hooks';
import {studentInfoActions} from '../store/reducers/studentInfoSlice';
import {userActions} from '../store/reducers/userSlice';
import {volunteerInfoActions} from '../store/reducers/volunteerInfoSlice';
import '../styles/IconUser.scss';
import {ProfileNav} from './ProfileNav';

export interface MenuUserMobileProps {
  firstName: string;
  lastName: string;
  gender?: Gender;
  role: Role;
  closeMenu: () => void;
}

export const MenuUserMobile = ({firstName, lastName, gender, role, closeMenu}: MenuUserMobileProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const renderIconByGenderAndRole =
    gender === Gender.HOMME ? (
      role === Role.STUDENT ? (
        <IconUserBoy className="icon-student" />
      ) : (
        <IconUserTeacherMan className="icon-teacher" />
      )
    ) : role === Role.STUDENT ? (
      <IconUserGirl className="icon-student" />
    ) : (
      <IconUserTeacherWoman className="icon-teacher" />
    );

  const handleLogout = () => {
    localStorage.clear();

    if (role === Role.STUDENT) {
      dispatch(volunteerInfoActions.resetVolunteerInfoState());
    } else {
      dispatch(studentInfoActions.resetStudentInfoState());
    }

    dispatch(userActions.resetUserState());
    window.location.href = 'https://zupdeco.homeclasse.org/';
  };

  return (
    <div className="absolute left-0 top-11 flex flex-col w-full h-menu-mobile py-8 bg-clWhite z-50">
      <div className="flex flex-row items-center mb-8 px-6">
        <div className="flex items-center h-14.75 w-14.75">{renderIconByGenderAndRole}</div>
        <div className="flex flex-col ml-2 text-mainPurple text-xl">
          <p>{firstName}</p>
          <p className="font-bold">
            {lastName} - {role === Role.STUDENT ? 'Élève' : 'Tuteur'}
          </p>
          <div className="text-base text-greyMedium font-bold" onClick={handleLogout}>
            Déconnexion
          </div>
        </div>
      </div>
      <ul>
        {menuAuthenticatedItems(role).map((menuItem, idx) =>
          menuItem.name !== 'MON PROFIL' ? (
            <li key={idx} className="px-6 border-solid border-b-1 border-greyLight">
              <Link
                className="py-3.5 flex items-center text-mainPurple text-xl font-semibold"
                to={menuItem.link}
                onClick={closeMenu}>
                {menuItem.name}
              </Link>
            </li>
          ) : (
            <li key={idx} className="px-6 border-solid border-b-1 border-greyLight">
              <Link
                className="py-3.5 flex items-center text-mainPurple text-xl font-semibold"
                to={menuItem.link}
                onClick={closeMenu}>
                {menuItem.name}
              </Link>
              <div className="px-2">
                <ProfileNav role={role} closeMenu={closeMenu} />
              </div>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};
