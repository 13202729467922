export enum VolunteerStudyOrWorkField {
  Art = 'Art',
  Economie_Gestion = 'Economie Gestion',
  Sciences = 'Sciences',
  Sante = 'Santé ',
  Enseignement = 'Enseignement',
  Droit_SciencesPolitiques = 'Droit et sciences politiques',
  Lettres_et_langues = 'Lettres et langues',
  Sciences_humaies_et_sociales = 'Sciences humaies et sociales',
  Commerce = 'Commerce',
  Communication = 'Communication',
  Informatique = 'Informatique',
  Autre = 'Autre',
}
