import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MediaStudent} from '../../enums/Media';
import {School} from '../../enums/School';
import {StudentProfileResponse} from '../../interfaces/api/StudentProfileResponse';
import {CreateStudentRequest} from '../../interfaces/api/CreateStudentRequest';
import {CreateStudentResponse} from '../../interfaces/api/CreateStudentResponse';
import {UpdateStudentRequest} from '../../interfaces/api/UpdateStudentRequest';
import {EligibilityCriteria} from '../../interfaces/EligibilityCriteria';
import {StudentAvailabilty} from '../../interfaces/StudentAvailability';
import {StudentInformation} from '../../interfaces/StudentInformation';
import {StudentReferent} from '../../interfaces/StudentReferent';
import {StudentSchoolInfo} from '../../interfaces/StudentSchoolInfo';
import ApiService from '../../services/ApiService';
import {RootState} from '../store';
import {OptionYesNo} from '../../enums/OptionYesNo';
import {StudentDocumentType} from '../../enums/StudentDocumentType';
import {UploadFileResponse} from '../../interfaces/api/UploadFileResponse';
import {AirtableFileResponse} from '../../interfaces/api/AirtableFileResponse';
import {TutorAccompagnied} from '../../interfaces/api/TutorAccompangied';
import {MeetingInformation} from '../../interfaces/MeetingInformation';
import {StudentStatus, StudentBinomeStatus} from '../../enums/StudentStatus';

interface StudentInfoSliceState {
  studentId: string;
  eligibilityCriteria: EligibilityCriteria;
  studentInformation: StudentInformation;
  studentSchoolInfo: StudentSchoolInfo;
  studentReferent: StudentReferent;
  studentAvailability: StudentAvailabilty;
  studentDocuments: Record<string, AirtableFileResponse[]>;
  studentStatus?: StudentStatus;
  studentBinomeStatus?: StudentBinomeStatus;
  nextMeeting?: MeetingInformation;
  tutorAccompagnied?: TutorAccompagnied;
  isProfileInfoLoaded: boolean;
  isPending: Record<string, boolean>;
  isSuccess: Record<string, boolean>;
  errorMsg: Record<string, string>;
}

const initialState: StudentInfoSliceState = {
  eligibilityCriteria: {
    hasScholarship: undefined,
    hasCAF: undefined,
    hasSocialMinimaBenefit: undefined,
    gradePointAverage: '',
    isFollowHomeClasseFullYear: undefined,
    requirementForReunion: undefined,
    motivatedChild: undefined,
    tutoring: undefined,
  },
  studentInformation: {
    studentFirstName: '',
    studentLastName: '',
    studentSchool: null,
    studentGender: undefined,
    studentBirthDate: '',
    studentClassNb: '',
    subClassName: '',
    studentEmail: '',
    studentLogin: '',
    studentPhone: '',
    studentAcceptOffer: true,
    studentAcceptNewsletter: false,
    knownHomeClasseBy: '',
  },
  studentSchoolInfo: {
    schoolName: '',
    schoolPostCode: '',
    schoolCity: '',
    knownHomeClasseBy: undefined,
  },
  studentReferent: {
    referentFirstName: '',
    referentLastName: '',
    referentStatus: '',
    referentProfessionalCategoryParent: '',
    referentAddress: '',
    referentPostCode: '',
    referentCity: '',
    referentEmail: '',
    referentPhone: '',
  },
  studentAvailability: {
    hasComputerHardware: undefined,
    availabilitiesCalendar: [
      {
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
      },
    ],
  },
  studentId: '',
  studentStatus: undefined,
  studentBinomeStatus: undefined,
  studentDocuments: {
    [StudentDocumentType.GRADE_BOOKS]: [],
    [StudentDocumentType.ELIGIBILITY_PROOF]: [],
    [StudentDocumentType.OTHER_FILES]: [],
  },
  isPending: {},
  isSuccess: {},
  errorMsg: {},
  isProfileInfoLoaded: false,
};

export const createStudent = createAsyncThunk<CreateStudentResponse, CreateStudentRequest>(
  'student/save',
  async (createStudentRequest: CreateStudentRequest, thunkAPI) => {
    try {
      const response = await ApiService.createStudent(createStudentRequest);
      localStorage.setItem('access_token', response.access_token);
      localStorage.setItem('role', response.user.roles[0]);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue((e as {message: string}).message || 'Une erreur est survenu');
    }
  },
);

export const getStudentProfile = createAsyncThunk<StudentProfileResponse>(
  'student/getProfileInfo',
  async (_, thunkAPI) => {
    try {
      const response: StudentProfileResponse = (await ApiService.getUsersProfile()) as StudentProfileResponse;
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue('Login ou password invalid');
    }
  },
);

interface UploadStudentDocumentThunkRequest {
  documentsFormData: Record<string, FormData[]>;
  studentDocuments: Record<string, AirtableFileResponse[]>;
  studentId: string;
  currentStudentStatus?: StudentStatus;
}

export const uploadStudentDocument = createAsyncThunk<void, UploadStudentDocumentThunkRequest>(
  'upload/studentDocument',
  async (uploadStudentDocumentThunkRequest: UploadStudentDocumentThunkRequest, thunkAPI) => {
    try {
      const updateStudentRequest: UpdateStudentRequest = {};

      await Promise.all(
        Object.values(StudentDocumentType).map(async (documentType) => {
          const existDocument: UploadFileResponse[] = uploadStudentDocumentThunkRequest.studentDocuments[documentType]
            .filter((airtableFile) => Boolean(airtableFile))
            .map((airtableFile) => ({
              url: airtableFile.url,
            }));
          const newDocument: UploadFileResponse[] = await Promise.all(
            uploadStudentDocumentThunkRequest.documentsFormData[documentType].map((formData) =>
              ApiService.uploadFile(formData),
            ),
          );

          switch (documentType) {
            case StudentDocumentType.GRADE_BOOKS:
              updateStudentRequest.gradeBooks = [...existDocument, ...newDocument];
              break;
            case StudentDocumentType.ELIGIBILITY_PROOF:
              updateStudentRequest.eligibleDocuments = [...existDocument, ...newDocument];
              break;
            case StudentDocumentType.OTHER_FILES:
              updateStudentRequest.otherDocuments = [...existDocument, ...newDocument];
              break;
          }
        }),
      );

      await ApiService.updateStudent(updateStudentRequest, uploadStudentDocumentThunkRequest.studentId);
      return;
    } catch (e) {
      thunkAPI.rejectWithValue((e as {message: string}).message || 'Une erreur est survenu');
    }
  },
);

export interface UpdateStudentThunkRequest {
  request: UpdateStudentRequest;
  id: string;
}

export const updateStudentInfo = createAsyncThunk<void, UpdateStudentThunkRequest>(
  'update/student',
  async (updateStudentThunkRequest: UpdateStudentThunkRequest, thunkAPI) => {
    try {
      await ApiService.updateStudent(updateStudentThunkRequest.request, updateStudentThunkRequest.id);
      return;
    } catch (e) {
      thunkAPI.rejectWithValue((e as {message: string}).message || 'Une erreur est survenu');
    }
  },
);

export const studentInfoSlice = createSlice({
  name: 'studentInfo',
  initialState,
  reducers: {
    resetRequestApiState: (state) => {
      state.isPending = {};
      state.isSuccess = {};
      state.errorMsg = {};
      if (sessionStorage.getItem('eligibilityCriteria') !== null) {
        state.eligibilityCriteria = JSON.parse(sessionStorage.eligibilityCriteria);
      }
      if (sessionStorage.getItem('studentInformation') !== null) {
        state.studentInformation = JSON.parse(sessionStorage.studentInformation);
      }
      if (sessionStorage.getItem('studentAvailability') !== null) {
        state.studentAvailability = JSON.parse(sessionStorage.studentAvailability);
      }
      if (sessionStorage.getItem('studentSchoolInfo') !== null) {
        state.studentSchoolInfo = JSON.parse(sessionStorage.studentSchoolInfo);
      }
    },
    resetStudentInfoState: () => initialState,
    saveEligibilityCriteria: (state, {payload}: PayloadAction<EligibilityCriteria>) => {
      state.eligibilityCriteria = payload;
      sessionStorage.setItem('eligibilityCriteria', JSON.stringify(payload));
    },
    saveStudentInformation: (state, {payload}: PayloadAction<StudentInformation>) => {
      state.studentInformation = payload;
      sessionStorage.setItem('studentInformation', JSON.stringify(payload));
    },
    saveStudentSchoolInfo: (state, {payload}: PayloadAction<StudentSchoolInfo>) => {
      state.studentSchoolInfo = payload;
      sessionStorage.setItem('studentSchoolInfo', JSON.stringify(payload));
    },
    saveStudentReferent: (state, {payload}: PayloadAction<StudentReferent>) => {
      state.studentReferent = payload;
    },
    saveStudentAvailability: (state, {payload}: PayloadAction<StudentAvailabilty>) => {
      state.studentAvailability = payload;
      sessionStorage.setItem('studentAvailability', JSON.stringify(payload));
    },
    saveStudentDocuments: (state, {payload}: PayloadAction<Record<string, AirtableFileResponse[]>>) => {
      state.studentDocuments = payload;
    },
    updatescity: (state, {payload}: PayloadAction<string>) => {
      state.studentSchoolInfo.schoolCity = payload;
    },
    updatescode: (state, {payload}: PayloadAction<string>) => {
      state.studentSchoolInfo.schoolPostCode = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createStudent.fulfilled, (state, action) => {
      state.errorMsg.createStudent = '';
      state.isPending.createStudent = false;
      state.isSuccess.createStudent = true;
      state.studentId = action.payload.user.id;
    });
    builder.addCase(createStudent.pending, (state) => {
      state.isPending.createStudent = true;
    });
    builder.addCase(createStudent.rejected, (state, action: any) => {
      state.errorMsg.createStudent = `Une erreur est survenue. Merci de renseigner les bonnes informations.<br />${action.payload
        .split(',')
        .join('<br />')}`;
      state.isPending.createStudent = false;
    });
    // update student
    builder.addCase(updateStudentInfo.fulfilled, (state) => {
      state.errorMsg.updateStudentInfo = '';
      state.isPending.updateStudentInfo = false;
      state.isSuccess.updateStudentInfo = true;
    });
    builder.addCase(updateStudentInfo.pending, (state) => {
      state.isPending.updateStudentInfo = true;
    });
    builder.addCase(updateStudentInfo.rejected, (state, action: any) => {
      state.errorMsg.updateStudentInfo = `Une erreur est survenue. Merci de renseigner les bonnes informations.<br />${action.payload
        .split(',')
        .join('<br />')}`;
      state.isPending.updateStudentInfo = false;
    });

    // upload student document
    builder.addCase(uploadStudentDocument.fulfilled, (state) => {
      state.errorMsg.uploadStudentDocument = '';
      state.isPending.uploadStudentDocument = false;
      state.isSuccess.uploadStudentDocument = true;
    });
    builder.addCase(uploadStudentDocument.pending, (state) => {
      state.isPending.uploadStudentDocument = true;
    });
    builder.addCase(uploadStudentDocument.rejected, (state, action: any) => {
      state.errorMsg.uploadStudentDocument = `Une erreur est survenue. Merci de renseigner les bonnes informations.<br />${action.payload
        .split(',')
        .join('<br />')}`;
      state.isPending.uploadStudentDocument = false;
    });

    // get student Info
    builder.addCase(getStudentProfile.fulfilled, (state, action) => {
      state.errorMsg.getStudentProfile = '';
      state.isPending.getStudentProfile = false;
      state.isSuccess.getStudentProfile = true;
      state.isProfileInfoLoaded = true;

      state.eligibilityCriteria = {
        hasScholarship: action.payload.data.scholarship as OptionYesNo,
        hasCAF: action.payload.data.familyQuotaCAF as OptionYesNo,
        hasSocialMinimaBenefit: action.payload.data.minimumSocialBenefits as OptionYesNo,
        gradePointAverage: action.payload.data.averageNote,
        isFollowHomeClasseFullYear: action.payload.data.homeClasseEngage ? OptionYesNo.OUI : OptionYesNo.NON,
        requirementForReunion: action.payload.data.requirementForReunion as OptionYesNo,
        motivatedChild: action.payload.data.motivatedChild as OptionYesNo,
        tutoring: action.payload.data.tutoring as OptionYesNo,
      };
      state.studentSchoolInfo = {
        schoolCity: action.payload.data.schoolCity,
        schoolName: action.payload.data.schoolName,
        schoolPostCode: action.payload.data.schoolPostalCode,
        knownHomeClasseBy: action.payload.data.origin as MediaStudent,
      };
      state.studentId = action.payload.id;
      state.studentInformation = {
        studentFirstName: action.payload.data.firstName,
        studentLastName: action.payload.data.lastName,
        studentGender: action.payload.data.gender,
        studentSchool: School.COLLEGE, // @TODO ,
        studentBirthDate: action.payload.data.birthday,
        // studentBirthDate: formatDateBirthdayFromApi(action.payload.data.birthday),
        studentClassNb: action.payload.data.className,
        subClassName: action.payload.data.subClassName,
        studentEmail: action.payload.email ? action.payload.email : action.payload.data.login,
        studentLogin: action.payload.data.login,
        studentPhone: action.payload.data.phoneNumber,
        studentAcceptOffer: action.payload.data.partnerOffersConsent,
        studentAcceptNewsletter: action.payload.data.newsletterConsent,
        knownHomeClasseBy: action.payload.data.knownHomeClasseBy,
      };
      state.studentReferent = {
        referentFirstName: action.payload.data.referentFirstName,
        referentLastName: action.payload.data.referentLastName,
        referentAddress: action.payload.data.referentAddress,
        referentCity: action.payload.data.referentCity,
        referentPostCode: action.payload.data.referentPostalCode,
        referentPhone: action.payload.data.referentPhoneNumber,
        referentStatus: action.payload.data.referentStatus,
        referentProfessionalCategoryParent: action.payload.data.referentProfessionalCategoryParent,
        referentEmail: action.payload.data.referentEmail,
      };
      state.studentAvailability = {
        hasComputerHardware: action.payload.data.hasMaterial,
        availabilitiesCalendar: action.payload.data.availabilitiesCalendar,
      };
      state.studentDocuments = {
        [StudentDocumentType.GRADE_BOOKS]: action.payload.data.gradeBooks || [],
        [StudentDocumentType.ELIGIBILITY_PROOF]: action.payload.data.eligibleDocuments || [],
        [StudentDocumentType.OTHER_FILES]: action.payload.data.otherDocuments || [],
      };
      state.studentStatus = action.payload.data.status;
      state.studentBinomeStatus = action.payload.data.binomeSTATUT;
      if (action.payload.data.teachers) {
        state.tutorAccompagnied = action.payload.data.teachers[0];
      }
      if (action.payload.data.nextTutorStart && action.payload.data.nextTutorEnd && action.payload.data.tutorLink) {
        state.nextMeeting = {
          nextTutorStart: action.payload.data.nextTutorStart,
          nextTutorEnd: action.payload.data.nextTutorEnd,
          tutorLink: action.payload.data.tutorLink,
          seance0: action.payload.data.seance0,
        };
      }
    });
    builder.addCase(getStudentProfile.pending, (state) => {
      state.isPending.getStudentProfile = true;
    });
    builder.addCase(getStudentProfile.rejected, (state, action: any) => {
      state.errorMsg.getStudentProfile = `Une erreur est survenue. Merci de renseigner les bonnes informations.<br />${action.payload
        .split(',')
        .join('<br />')}`;
      state.isPending.getStudentProfile = false;
    });
  },
});

export const studentInfoActions = studentInfoSlice.actions;

export const studentProfileLoadedSelector = (state: RootState): boolean => state.studentInfo.isProfileInfoLoaded;
export const studentErrorSelector = (state: RootState): Record<string, string> => state.studentInfo.errorMsg;
export const studentSuccessSelector = (state: RootState): Record<string, boolean> => state.studentInfo.isSuccess;
export const studentPendingSelector = (state: RootState): Record<string, boolean> => state.studentInfo.isPending;

export const eligibilityCriteriaSelector = (state: RootState): EligibilityCriteria =>
  state.studentInfo.eligibilityCriteria;

export const studentInformationSelector = (state: RootState): StudentInformation =>
  state.studentInfo.studentInformation;

export const schoolInfoSelector = (state: RootState): StudentSchoolInfo => state.studentInfo.studentSchoolInfo;

export const studentIdSelector = (state: RootState): string => state.studentInfo.studentId;

export const studentReferentSelector = (state: RootState): StudentReferent => state.studentInfo.studentReferent;

export const studentAvailableSelector = (state: RootState): StudentAvailabilty => state.studentInfo.studentAvailability;

export const studentDocumentsSelector = (state: RootState): Record<string, AirtableFileResponse[]> =>
  state.studentInfo.studentDocuments;

export const studentStatusSelector = (state: RootState): StudentStatus | undefined => state.studentInfo.studentStatus;

export const studentBinomeStatusSelector = (state: RootState): StudentBinomeStatus | undefined =>
  state.studentInfo.studentBinomeStatus;

export const studentNextMeetingSelector = (state: RootState): MeetingInformation | undefined =>
  state.studentInfo.nextMeeting;

export const studentTutorAccompagniedSelector = (state: RootState): TutorAccompagnied | undefined =>
  state.studentInfo.tutorAccompagnied;
