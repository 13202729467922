import React, {ChangeEvent} from 'react';
import {Level} from '../enums/Level';
import '../styles/SelectInput.scss';

export interface SelectInputProps {
  label: string;
  name: string;
  id: string;
  defaultEmptyValueTitle: string;
  options: string[];
  className?: string;
  value?: string | Level[];
  required?: boolean;
  isLabelLeft?: boolean;
  height?: boolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onPress?: (active: boolean) => void;
  multiple?: boolean;
  avcome?: boolean;
  phone?: boolean;
  mandatory?: string;
}

export const SelectInput = ({
  label,
  name,
  id,
  defaultEmptyValueTitle,
  options,
  className,
  phone,
  isLabelLeft,
  value,
  height,
  required,
  mandatory,
  onChange,
  onPress = (active) => false,
  multiple,
  avcome,
}: SelectInputProps): JSX.Element => {
  let defaultClassName = `mt-1 h-11.5 px-4 py-3 border-2 border-greyLight outline-none appearance-none`;
  let wrapper = 'select-wrapper relative';
  if (avcome) {
    defaultClassName = `mt-1 h-8 px-3 border-2 border-greyLight outline-none appearance-none`;
    wrapper = 'select-wrapper-av relative';
  }
  if (phone) {
    defaultClassName = `mt-1 h-8 px-1 border-2 border-greyLight outline-none appearance-none`;
  }
  const combinedClassName = className ? `${defaultClassName} ${className}` : defaultClassName;
  const marginLeft = phone ? '' : 'ml-2';
  const marginRight = phone ? 'mr-1' : 'mr-4';
  const finalWrapper = wrapper;

  return (
    <div className={isLabelLeft ? `flex flex-row ${marginLeft} items-center` : 'flex flex-col'}>
      <label
        className={
          isLabelLeft ? `text-base text-greyMedium font-medium ${marginRight}` : 'text-base text-greyMedium font-medium'
        }
        htmlFor={id}>
        {label}
        {mandatory == 'True' ? (
          <>
            <span className="mandatory-star">*</span>
          </>
        ) : (
          <></>
        )}
      </label>
      <div className={finalWrapper}>
        <select
          className={combinedClassName}
          id={id}
          name={name}
          onChange={onChange}
          onClick={() => onPress(true)}
          value={value}
          {...(required ? {required: required} : {})}>
          <option value="">{defaultEmptyValueTitle}</option>
          {options.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
