import React, {ReactNode} from 'react';

export interface ButtonProps {
  children: ReactNode;
  color: string;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isRegistrationForm?: boolean;
  onClick?: () => void;
}

export const Button = ({
  children,
  color,
  className,
  disabled,
  type,
  isRegistrationForm,
  onClick,
}: ButtonProps): JSX.Element => {
  // because the buid don't generate bg-clValidate css
  const bgColor = color === 'clValidate' ? 'bg-clValidate' : `bg-${color}`;
  const defaultClassName = isRegistrationForm
    ? `py-3 text-xl text-clWhite text-center font-medium ${bgColor} rounded-8px disabled:bg-greyMedium disabled:opacity-16`
    : `min-w-62.5 py-3 text-xl text-clWhite text-center font-medium ${bgColor} rounded-8px disabled:bg-greyMedium disabled:opacity-16`;
  const combinedClassName = className ? `${defaultClassName} ${className}` : defaultClassName;

  return (
    <button
      className={combinedClassName}
      onClick={onClick}
      {...(disabled ? {disabled: disabled} : {})}
      type={type ? type : 'button'}>
      {children}
    </button>
  );
};
