import React, {ReactNode, useEffect} from 'react';
import {HeaderAuthenticated} from '../components/HeaderAuthenticated';
import {MeetingType} from '../enums/MeetingType';
import {Role} from '../enums/Role';
import {StudentInformation} from '../interfaces/StudentInformation';
import {VolunteerInformation} from '../interfaces/VolunteerInformation';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {
  getStudentProfile,
  studentInformationSelector,
  studentProfileLoadedSelector,
} from '../store/reducers/studentInfoSlice';
import {
  getVolunteerMeetingInfo,
  getVolunteerProfile,
  volunteerInfoSelector,
  volunteerProfileInfoLoadedSelector,
} from '../store/reducers/volunteerInfoSlice';

interface AuthenticatedPageProps {
  children: ReactNode;
  role: Role;
}

export const AuthenticatedPage = ({children, role}: AuthenticatedPageProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const studentProfileLoad: boolean = useAppSelector(studentProfileLoadedSelector);
  const volunteerProfileLoaded: boolean = useAppSelector(volunteerProfileInfoLoadedSelector);
  const studentInformation: StudentInformation = useAppSelector(studentInformationSelector);
  const volunteerInformation: VolunteerInformation = useAppSelector(volunteerInfoSelector);
  useEffect(() => {
    if (!studentProfileLoad) {
      if (role === Role.STUDENT) {
        dispatch(getStudentProfile());
      }
    }
    if (!volunteerProfileLoaded) {
      if (role === Role.VOLUNTEER) {
        dispatch(getVolunteerProfile());
      }
    }
    if (volunteerProfileLoaded) {
      dispatch(
        getVolunteerMeetingInfo({
          [MeetingType.TRAINING]: volunteerInformation.trainingMeetingEvent,
          [MeetingType.INDIVIDUAL]: volunteerInformation.individualMeetingEvent,
          [MeetingType.INFORMATION]: volunteerInformation.informationMeetingEvent,
        }),
      );
    }
  }, [
    dispatch,
    studentProfileLoad,
    volunteerProfileLoaded,
    role,
    volunteerInformation.trainingMeetingEvent,
    volunteerInformation.individualMeetingEvent,
    volunteerInformation.informationMeetingEvent,
  ]);

  const firstName = role === Role.STUDENT ? studentInformation.studentFirstName : volunteerInformation.firstName;
  const lastName = role === Role.STUDENT ? studentInformation.studentLastName : volunteerInformation.lastName;
  const gender = role === Role.STUDENT ? studentInformation.studentGender : volunteerInformation.gender;
  return (
    <>
      <HeaderAuthenticated firstName={firstName} lastName={lastName} role={role} gender={gender} />
      <div className="flex flex-col items-stretch h-auto min-h-form-content pt-4 lg:pt-11.5 bg-clBackground">
        {children}
      </div>
    </>
  );
};
