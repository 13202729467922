import {add, format} from 'date-fns';
import {fr} from 'date-fns/locale';
import {capitalize} from 'lodash';

export const dateToString = (date: Date, dateFormat: string): string =>
  capitalize(
    format(date, dateFormat, {
      locale: fr,
    }),
  );

export const formatDateMeeting = (dateStart: string, dateEnd: string): string =>
  `${dateToString(new Date(dateStart), 'EEEE dd MMMM')} de ${dateToString(
    new Date(dateStart),
    'HH:mm',
  )} à ${dateToString(new Date(dateEnd), 'HH:mm')}`;

export const formatDateMeetingSeance0 = (dateStart: string): string => {
  const t = new Date(dateStart);
  const result = new Date();
  result.setTime(t.getTime() + 60 * 60 * 1000);
  return `${dateToString(new Date(dateStart), 'EEEE dd MMMM')} de ${dateToString(
    new Date(dateStart),
    'HH:mm',
  )} à ${dateToString(new Date(result), 'HH:mm')}`;
};
export const makeHoursRange = (to?: string, from?: string): string[] => {
  const hoursRange: string[] = [];
  const dateStart = new Date();
  const dateEnd = new Date();

  dateStart.setHours(8, 0, 0);
  dateEnd.setHours(20, 0, 0);

  if (from) {
    const [h, m] = from.split(':');

    if (!isNaN(+h) && !isNaN(+m)) {
      dateStart.setHours(+h + 1, +m, 0);
    }
  }

  if (to) {
    const [h, m] = to.split(':');

    if (!isNaN(+h) && !isNaN(+m)) {
      dateEnd.setHours(+h - 1, +m, 0);
    }
  }

  let currentDate = dateStart;
  while (currentDate <= dateEnd) {
    hoursRange.push(
      format(currentDate, 'HH:mm', {
        locale: fr,
      }),
    );
    currentDate = add(currentDate, {
      minutes: 30,
    });
  }
  return hoursRange;
};

export const hoursRange = makeHoursRange();
