import React, {InputHTMLAttributes} from 'react';
import '../styles/Checkbox.scss';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  className?: string;
  checked?: boolean;
  onCheckBoxClick: (value: string) => void;
  isMainColor?: boolean;
  black?: boolean;
  error?: boolean;
}

export const Checkbox = ({
  name,
  value,
  className,
  checked,
  onCheckBoxClick,
  isMainColor,
  black,
  error,
}: CheckboxProps): JSX.Element => {
  return (
    <div className={className} onClick={() => onCheckBoxClick(value)}>
      <label htmlFor={`${name}-${value}`} className="wrapper">
        <input
          className="absolute w-0 h-0 opacity-0"
          type="checkbox"
          name={name}
          value={value}
          onChange={() => value}
          checked={checked}
        />
        {black ? (
          <>
            <span className={`checkbox-checkmark-black ${isMainColor ? 'checkbox-checkmark-mainColor' : ''}`} />
            <p className={`checkbox-label ${isMainColor ? 'checkbox-label-mainColor' : black ? 'black1' : ''}`}>
              {value}
            </p>
          </>
        ) : (
          <>
            <p
              className={`checkbox-label ${
                isMainColor ? 'checkbox-label-mainColor' : black ? 'black1' : error ? 'error' : ''
              }`}>
              {value}
            </p>
            <span className={`checkbox-checkmark ${isMainColor ? 'checkbox-checkmark-mainColor' : ''}`} />
          </>
        )}
      </label>
    </div>
  );
};
