import React, {ChangeEvent, InputHTMLAttributes, useEffect, useState} from 'react';
import appText from '../assets/intl/fr';
import {isPhoneNumberEmpty} from '../utils/utils';
import {TextInput} from './TextInput';

export interface PhoneInputProps extends InputHTMLAttributes<HTMLInputElement> {
  displayName: string;
  prefix?: string;
  errorApeare?: boolean;
  mandatory?: string;
  seterr?: (err: boolean) => void;
}

export const PhoneInput = ({onChange, errorApeare, seterr, prefix, ...rest}: PhoneInputProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (rest.value && errorMsg !== '') {
      setErrorMsg(appText.phoneInput.error);
    }
  }, [setErrorMsg, rest.required, rest.value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const strInput = event.target.value;
    const phoneNumberWithoutSpace = strInput.replace(/\s/g, '');
    const re = /^0[1-9](?:[\s.-]*\d{2}){4}$/;
    if (seterr) seterr(false);
    if (strInput.indexOf('.') > -1) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (
      !isFinite(+phoneNumberWithoutSpace) ||
      phoneNumberWithoutSpace.length > 10 ||
      (phoneNumberWithoutSpace.length === 10 && !event.target.validity.valid)
    ) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (event.target.validity.valid) {
      setErrorMsg('');
    } else {
      setErrorMsg(appText.phoneInput.error);
    }
    if (!re.test(strInput)) setErrorMsg(appText.phoneInput.error);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="flex flex-col">
      {prefix ? (
        <div className="flex">
          <TextInput
            prefix={prefix}
            type="tel"
            // pattern="^0[1-9](?:[\s.-]*\d{2}){4}$"
            inputMode="numeric"
            maxLength={14}
            onChange={handleChange}
            {...rest}
          />
        </div>
      ) : (
        <TextInput
          type="tel"
          // pattern="^0[1-9](?:[\s.-]*\d{2}){4}$"
          inputMode="numeric"
          maxLength={14}
          onChange={handleChange}
          {...rest}
        />
      )}
      {/* <TextInput
        type="tel"
        pattern="^0[1-9](?:[\s.-]*\d{2}){4}$"
        inputMode="numeric"
        maxLength={14}
        onChange={handleChange}
        {...rest}
      /> */}
      {errorApeare !== undefined ? (
        errorMsg && errorApeare ? (
          <div className="mt-2 text-clStateError">{errorMsg}</div>
        ) : (
          <></>
        )
      ) : (
        errorMsg && <div className="mt-2 text-clStateError">{errorMsg}</div>
      )}
    </div>
  );
};
