import {MenuItem} from '../interfaces/MenuItem';

export const menuGeneralItems: MenuItem[] = [
  {
    name: 'ÉLÈVE',
    link: 'https://zupdeco.homeclasse.org/eleve',
  },
  {
    name: 'TUTEUR',
    link: 'https://zupdeco.homeclasse.org/tuteur',
  },
  {
    name: 'NOTRE OFFRE',
    link: 'https://zupdeco.homeclasse.org/notre-offre',
  },
  {
    name: 'LE PROJET',
    link: 'https://zupdeco.homeclasse.org/le-projet',
  },
  {
    name: 'NOUS SOUTENIR',
    link: 'https://zupdeco.homeclasse.org/donateur',
  },
  {
    name: 'CONTACT',
    link: 'https://zupdeco.homeclasse.org/contact',
  },
];
