import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Header} from './Header';
import {Menu} from './Menu';
import {MenuUserMobile} from './MenuUserMobile';
import {Gender} from '../enums/Gender';
import {Role} from '../enums/Role';
import {menuAuthenticatedItems} from '../constants/MenuAuthenticatedItems';
import {IconArrowInputSelector, IconUserBoy, IconUserGirl, IconUserTeacherMan, IconUserTeacherWoman} from '../assets';
import '../styles/IconUser.scss';
import Dropdown from './Dropdown';
import {useMediaQuery} from '../hooks/Hook';

export interface HeaderAuthenticatedProps {
  firstName: string;
  lastName: string;
  gender?: Gender;
  role: Role;
}

export const HeaderAuthenticated = (props: HeaderAuthenticatedProps): JSX.Element => {
  const {firstName, lastName, gender, role} = props;
  const location = useLocation();
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);
  const isabove768 = useMediaQuery('(max-width: 768px)');

  const menuItems = menuAuthenticatedItems(role);
  const currentItemIndex = menuItems.findIndex(
    (menuItem) => menuItem.link.split('/')[1] === location.pathname.split('/')[1],
  );

  const renderIconByGender =
    // <Dropdown role={role}>
    gender === Gender.HOMME ? (
      role === Role.STUDENT ? (
        <IconUserBoy className="icon-student" />
      ) : (
        <IconUserTeacherMan className="icon-teacher" />
      )
    ) : role === Role.STUDENT ? (
      <IconUserGirl className="icon-student" />
    ) : (
      <IconUserTeacherWoman className="icon-teacher" />
    );
  // </Dropdown>

  const handleClickOpenMenuMobile = () => {
    setIsOpenMenuMobile(!isOpenMenuMobile);
  };

  return (
    <>
      <Header
        elementMiddle={<Menu menuItems={menuItems} isHighlighted currentItemIndex={currentItemIndex} />}
        elementRight={
          !isabove768 ? (
            <Dropdown role={role}>
              <div className="flex flex-row items-center">
                <Link className="flex flex-row items-center justify-center" to={`/dashboard/${role.toLowerCase()}`}>
                  <div className="flex items-center h-8 w-8">{renderIconByGender}</div>
                  <p className="hidden sm:block ml-2.5 text-mainPurple text-base">
                    {firstName}
                    <span className="ml-0.75 font-bold">{lastName}</span>
                  </p>
                </Link>
                <div className="block md:hidden">
                  <IconArrowInputSelector
                    className={`h-5 w-5 transition duration-300 ease-in-out ${
                      isOpenMenuMobile ? 'transform -rotate-180' : ''
                    }`}
                    onClick={handleClickOpenMenuMobile}
                  />
                  {isOpenMenuMobile && <MenuUserMobile {...props} closeMenu={handleClickOpenMenuMobile} />}
                </div>
              </div>
            </Dropdown>
          ) : (
            <>
              <div className="flex flex-row items-center">
                <Link className="flex flex-row items-center justify-center" to={`/dashboard/${role.toLowerCase()}`}>
                  <div className="flex items-center h-8 w-8">{renderIconByGender}</div>
                  <p className="hidden sm:block ml-2.5 text-mainPurple text-base">
                    {firstName}
                    <span className="ml-0.75 font-bold">{lastName}</span>
                  </p>
                </Link>
                <div className="block md:hidden">
                  <IconArrowInputSelector
                    className={`h-5 w-5 transition duration-300 ease-in-out ${
                      isOpenMenuMobile ? 'transform -rotate-180' : ''
                    }`}
                    onClick={handleClickOpenMenuMobile}
                  />
                  {isOpenMenuMobile && <MenuUserMobile {...props} closeMenu={handleClickOpenMenuMobile} />}
                </div>
              </div>
            </>
          )
        }
      />
    </>
  );
};
