import React, {ReactNode} from 'react';

export interface ButtonProps {
  children: ReactNode;
  color: string;
  className?: string;
  onClick: () => void;
}

export const ButtonSmall = ({children, color, className, onClick}: ButtonProps): JSX.Element => {
  const defaultClassName = `px-3 py-1 text-base text-clWhite font-semibold bg-${color} rounded-full`;
  const combinedClassName = className ? `${defaultClassName} ${className}` : defaultClassName;

  return (
    <button className={combinedClassName} onClick={onClick}>
      {children}
    </button>
  );
};
