import axios, {AxiosRequestConfig} from 'axios';
import {CalendlyResponse} from '../interfaces/api/CalendlyResponse';
import {ContactRequest} from '../interfaces/api/ContactRequest';
import {CompteRenduRequest} from '../interfaces/api/CompteRenduRequest';
import {CreateStudentRequest} from '../interfaces/api/CreateStudentRequest';
import {CreateStudentResponse} from '../interfaces/api/CreateStudentResponse';
import {CreateTeacherRequest} from '../interfaces/api/CreateTeacherRequest';
import {CreateTeacherResponse} from '../interfaces/api/CreateTeacherResponse';
import {ForgotPasswordRequest} from '../interfaces/api/ForgotPasswordRequest';
import {LoginRequest} from '../interfaces/api/LoginRequest';
import {LoginResponse} from '../interfaces/api/LoginResponse';
import {ResetPasswordRequest} from '../interfaces/api/ResetPasswordRequest';
import {SsoUrlResponse} from '../interfaces/api/SsoUrlResponse';
import {UpdateStudentRequest} from '../interfaces/api/UpdateStudentRequest';
import {UpdateTeacherRequest} from '../interfaces/api/UpdateTeacherRequest';
import {UploadFileResponse} from '../interfaces/api/UploadFileResponse';
import {UserProfileResponse} from '../interfaces/api/UserProfileResponse';
import {StudentAssigned} from '../interfaces/api/StudentAssigned';

export default class ApiService {
  private static getHeaders(isUpload?: boolean) {
    const headerContentType = isUpload ? 'multipart/form-data' : 'application/json';
    if (localStorage.getItem('access_token'))
      return {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        accept: '*/*',
        'Content-Type': headerContentType,
      };
    return {
      accept: '*/*',
      'Content-Type': headerContentType,
    };
  }

  private static getHeadersForUpload() {
    if (localStorage.getItem('access_token'))
      return {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        accept: '*/*',
        'Content-Type': 'application/json',
      };
    return {
      accept: '*/*',
      'Content-Type': 'application/json',
    };
  }

  private static get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const headers = ApiService.getHeaders();
    return axios
      .get(url, {
        headers,
        ...config,
      })
      .then((response: {data: T}) => response.data);
  }

  private static post<T>(url: string, data?: unknown, isUpload?: boolean, config?: AxiosRequestConfig): Promise<T> {
    const headers = ApiService.getHeaders(isUpload);
    return axios
      .post(url, data, {
        headers,
        ...config,
      })
      .then((response: {data: T}) => response.data)
      .catch((e) => {
        throw new Error(e.response?.data?.message);
      });
  }

  private static put<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
    const headers = ApiService.getHeaders();
    return axios
      .put(url, data, {
        headers,
        ...config,
      })
      .then((response: {data: T}) => response.data);
  }

  private static delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const headers = ApiService.getHeaders();
    return axios
      .delete(url, {
        headers,
        ...config,
      })
      .then((response: {data: T}) => response.data);
  }

  public static login(loginRequest: LoginRequest): Promise<LoginResponse> {
    const loginUrl = `${process.env.REACT_APP_BASE_API_URL}/auth/login`;
    return ApiService.post<LoginResponse>(loginUrl, loginRequest);
  }

  public static forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Promise<void> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/auth/forgot-password`;
    return ApiService.post(url, forgotPasswordRequest);
  }

  public static resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<void> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/auth/reset-password`;
    return ApiService.post(url, resetPasswordRequest);
  }

  public static createStudent(createStudentRequest: CreateStudentRequest): Promise<CreateStudentResponse> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/users/student`;
    return ApiService.post(url, createStudentRequest);
  }

  public static createTeacher(createTeacherRequest: CreateTeacherRequest): Promise<CreateTeacherResponse> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/users/teacher`;
    return ApiService.post(url, createTeacherRequest);
  }

  public static updateStudent(updateStudentRequest: UpdateStudentRequest, studentId: string): Promise<void> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/users/student/${studentId}`;
    return ApiService.put(url, updateStudentRequest);
  }

  public static updateTeacher(updateTeacherRequest: UpdateTeacherRequest, studentId: string): Promise<void> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/users/teacher/${studentId}`;
    return ApiService.put(url, updateTeacherRequest);
  }

  public static getUsersProfile(): Promise<UserProfileResponse> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/users/profile`;
    return ApiService.get<UserProfileResponse>(url);
  }

  public static getUrlProfexpress(): Promise<SsoUrlResponse> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/users/login-prof-express`;
    return ApiService.post<SsoUrlResponse>(url);
  }

  public static getUrlMaxicours(): Promise<SsoUrlResponse> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/users/login-maxi-cours`;
    return ApiService.post<SsoUrlResponse>(url);
  }

  public static getUrlMaxicoursMascarade(studentAssigned: StudentAssigned): Promise<SsoUrlResponse> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/users/login-maxi-cours-mascarade`;
    const dataForm = {
      email: studentAssigned.email,
      id: studentAssigned.airtableId,
    };
    return ApiService.post<SsoUrlResponse>(url, dataForm);
  }

  public static uploadFile(formData: FormData): Promise<UploadFileResponse> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/files/upload`;
    return ApiService.post<UploadFileResponse>(url, formData, true);
  }

  public static contact(contactRequest: ContactRequest): Promise<void> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/contact`;
    return ApiService.post<void>(url, contactRequest, false);
  }

  public static compteRendu(compteRenduRequest: CompteRenduRequest): Promise<void> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/compteRendu`;
    return ApiService.post<void>(url, compteRenduRequest, false);
  }

  public static getCalendlyScheduledEvent(eventId: string): Promise<CalendlyResponse> {
    const url = `${process.env.REACT_APP_BASE_API_URL}/calendly/${eventId}`;
    return ApiService.get<CalendlyResponse>(url);
  }
}
