import {format, parse} from 'date-fns';

export const copyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text);
};

export const getErrorBirth = (value: string): boolean => {
  if (value !== '') return true;
  return false;
};

export const isEmailEmpty = (email: string): boolean => {
  if (!email) return false;
  return true;
};

export const isEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const isPhoneNumberEmpty = (text: string): boolean => {
  const frenchPhoneRegex = /^0[1-9](?:[\s.-]*\d{2}){4}$/;
  if (!text && frenchPhoneRegex.test(text)) return false;
  return true;
};

export const isPhoneNumber = (text: string): boolean => {
  //champ numérique seulement. Doit respecter le format 0X XX XX XX XX
  const frenchPhoneRegex = /^0[1-9](?:[\s.-]*\d{2}){4}$/;
  return frenchPhoneRegex.test(text);
};

export const isDateFormatValid = (text: string): boolean => {
  // Doit respecter le format DD/MM/YYYY
  // eslint-disable-next-line
  const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0[1-9]|1[012])[\/\-]\d{4}$/;
  return dateRegex.test(text);
};

export const formatDateBirthdayFromApi = (birthDate: string): string => {
  return format(parse(birthDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
};

export const isZipCode = (text: string): boolean => {
  const zipCodeRegex = /^\d{5}$/;
  return zipCodeRegex.test(text);
};

export const formatFileName = (fileNameToFormat: string): string => {
  let fileName = fileNameToFormat.replace(/[^a-zA-Z0-9]/g, '_');
  fileName = fileName.substring(0, fileName.lastIndexOf('_')) + '.' + fileName.substring(fileName.lastIndexOf('_') + 1);
  return fileName;
};
