import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  volunteerInfoSelector,
  volunteerInfoActions,
  createTeacher,
  volunteerErrorSelector,
  volunteerPendingSelector,
  volunteerSuccessSelector,
  updateVolunteerInfo,
  volunteerIdSelector,
} from '../../store/reducers/volunteerInfoSlice';
import {TextInput} from '../TextInput';
import {RadioButton} from '../RadioButton';
import ToggleSwitch from '../ToggleSwitch';
import {Gender} from '../../enums/Gender';
import {Button} from '../Button';
import {Situation} from '../../enums/Situation';
import {VolunteerEducationLevel} from '../../enums/VolunteerEducationLevel';
import {VolunteerStudyOrWorkField} from '../../enums/VolunteerStudyField';
import {OptionYesNo, ReunionParticipe} from '../../enums/OptionYesNo';
import {VolunteerInformation} from '../../interfaces/VolunteerInformation';
import {SelectInput} from '../SelectInput';
import {Spinner} from '../Spinner';
import {VolunteerStatus} from '../../enums/VolunteerStatus';
import {
  getErrorBirth,
  isDateFormatValid,
  isEmail,
  isEmailEmpty,
  isPhoneNumber,
  isPhoneNumberEmpty,
} from '../../utils/utils';
import {Link, useHistory} from 'react-router-dom';
import {format, sub} from 'date-fns';
import {fr, tr} from 'date-fns/locale';
import {PhoneInput} from '../PhoneInput';
import {ZipCodeInput} from '../ZipCodeInput';
import {EmailInput} from '../EmailInput';
import appText from '../../assets/intl/fr';
import {parse} from 'date-fns/esm';
import {Homelink} from '../Homelink';
import ReactModal from 'react-modal';
import {userActions} from '../../store/reducers/userSlice';
import {divide} from 'lodash';
import {StudentRegistrationFirstFormModal} from './StudentRegistrationFirstFormModal';
import {MeetingType} from '../../enums/MeetingType';
import {ClassSchool} from '../../enums/ClassSchool';
import axios from 'axios';
import {MediaReferent} from '../../enums/Media';
import {SchoolInfoInterface} from '../../interfaces/SchoolInfoInterface';

// const birthDate = format(parse(volunteerInformation.birthDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');

const maxBirthdate = format(sub(Date.now(), {years: 16}), 'yyyy-MM-dd', {
  locale: fr,
});

const minBirthdate = format(sub(Date.now(), {years: 85}), 'yyyy-MM-dd', {
  locale: fr,
});

export interface VolunteerInformationFormProps {
  goToNextStep?: () => void;
  isRegistrationForm?: boolean;
  access_token?: string | null;
}

const renderTitle = (isRegistrationForm?: boolean): JSX.Element => {
  if (isRegistrationForm) {
    return (
      <>
        <h2 className="mb-6 text-greyMedium text-2xl font-bold">Informations personnelles</h2>
      </>
    );
  } else {
    return (
      <>
        <h2 className="mb-7 lg:mb-5 text-3xl text-mainPurple font-bold">MES INFORMATIONS</h2>
      </>
    );
  }
};

export const VolunteerInformationForm = ({
  goToNextStep,
  isRegistrationForm,
  access_token,
}: VolunteerInformationFormProps): JSX.Element => {
  const labelClass = 'text-greyMedium font-medium text-base mb-1';
  const dispatch = useAppDispatch();
  const volunteerInformation = useAppSelector(volunteerInfoSelector);
  const volunteerId = useAppSelector(volunteerIdSelector);
  const [errorApeare, seterrorApeare] = useState<boolean>(false);
  const [err, seterr] = useState<boolean>(true);
  const [errPhone, seterrPhone] = useState<boolean>(true);
  const [cities, setcities] = useState<Array<string>>([]);
  const [tosub, settosub] = useState<boolean>(false);
  const [errc, seterrc] = useState<boolean>(false);
  const [clickcity, setclickcity] = useState<boolean>(false);
  const [email, setemail] = useState<string>('');
  const [etablissement, setetab] = useState<SchoolInfoInterface[]>();
  const [schoolInfoChars, setSchoolInfoChars] = useState<boolean>(false);
  const [schoolName, setschool] = useState<string>();

  const apiName = isRegistrationForm && !access_token ? 'createTeacher' : 'updateVolunteerInfo';
  const errorMsg: string = useAppSelector(volunteerErrorSelector)[apiName] || '';
  const isLoading: boolean = useAppSelector(volunteerPendingSelector)[apiName] || false;
  const isSuccess: boolean = useAppSelector(volunteerSuccessSelector)[apiName] || false;
  const history = useHistory();

  useEffect(() => {
    dispatch(volunteerInfoActions.resetRequestApiState());
  }, [dispatch]);

  const handleretourn = () => {
    dispatch(volunteerInfoActions.updatereunionvolunteer());
    dispatch(volunteerInfoActions.resetRequestApiState());
    window.location.href = 'https://zupdeco.homeclasse.org/';
  };

  const retourntoCalendly = () => {
    window.location.href = 'https://zupdeco.homeclasse.org/';
  };

  const handleSelectChangeVolunteerInformation = (event: ChangeEvent<HTMLSelectElement>) => {
    seterrPhone(true);
    const newVolunteerInfo: VolunteerInformation = {
      ...volunteerInformation,
      [event.target.name]: event.target.value,
    };
    switch (newVolunteerInfo.situation) {
      case Situation.SEARCHING_JOB:
      case Situation.RETIREMENT:
        newVolunteerInfo.structureName = undefined;
        newVolunteerInfo.structureCity = undefined;
        break;
      case Situation.ACTIVE:
        newVolunteerInfo.structureCity = undefined;
        break;
      default:
        break;
    }

    dispatch(volunteerInfoActions.updateVolunteerInformation(newVolunteerInfo));
    dispatch(volunteerInfoActions.resetRequestApiState());
  };
  useEffect(() => {
    if (isRegistrationForm && isSuccess && goToNextStep) {
      dispatch(volunteerInfoActions.resetRequestApiState());
      goToNextStep();
    }
  }, [dispatch, isRegistrationForm, isSuccess, goToNextStep]);

  const [close, setclose] = useState(false);
  const handleClick = () => {
    setclose(false);
  };
  useEffect(() => {
    if (sessionStorage.getItem('currentStep') === null) {
      if (access_token) setclose(true);
    }
  }, [access_token]);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(volunteerInfoActions.resetVolunteerInfoState());
    dispatch(userActions.resetUserState());
    window.location.href = '/registration/volunteer/form';
  };

  useEffect(() => {
    setemail(volunteerInformation.email);
  }, []);

  useEffect(() => {
    if (canContinue()) {
      settosub(false);
    }
  }, [
    volunteerInformation.email,
    volunteerInformation.address,
    volunteerInformation.postCode,
    volunteerInformation.city,
    volunteerInformation.birthDate,
    volunteerInformation.situation,
    volunteerInformation.structureName,
    volunteerInformation.structureName,
    volunteerInformation.structureCity,
    volunteerInformation.educationLevel,
    volunteerInformation.appreciateByUniversity,
    volunteerInformation.hoursAppreciateByUniversity,
    volunteerInformation.firstName,
    volunteerInformation.lastName,
    volunteerInformation.gender,
    volunteerInformation.studyOrWorkField,
    volunteerInformation.situationpresi,
    volunteerInformation.phoneNumber,
    volunteerInformation.reunion,
    volunteerInformation.acceptOffer,
    volunteerInformation.acceptNewsletter,
    volunteerInformation.knownHomeClasseBy,
    volunteerInformation.enqueteMentorat,
    VolunteerStatus.WAITING,
  ]);

  const sendData = () => {
    if (canContinue()) {
      dispatch(volunteerInfoActions.resetRequestApiState());
      settosub(false);

      // const birthDate = format(parse(volunteerInformation.birthDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
      if (isRegistrationForm && !access_token) {
        dispatch(
          createTeacher({
            email: volunteerInformation.email.toLowerCase(),
            address: volunteerInformation.address,
            postalCode: volunteerInformation.postCode,
            city: volunteerInformation.city,
            birthday: volunteerInformation.birthDate,
            currentStatus: volunteerInformation.situation?.toString() || '',
            companyName: volunteerInformation.structureName || '',
            schoolName: volunteerInformation.structureName || '',
            schoolCity: volunteerInformation.structureCity || '',
            expectedDegree: volunteerInformation.educationLevel?.toString() || '',
            schoolValued: volunteerInformation.appreciateByUniversity === OptionYesNo.OUI,
            hoursEngage: volunteerInformation.hoursAppreciateByUniversity || '',
            firstName: volunteerInformation.firstName,
            lastName: volunteerInformation.lastName,
            gender: volunteerInformation.gender?.toString() || '',
            studyDomain: volunteerInformation.studyOrWorkField || '',
            situationpresi: volunteerInformation.situationpresi || '',
            phoneNumber: volunteerInformation.phoneNumber,
            reunion: volunteerInformation.reunion?.toString() || '',
            partnerOffersConsent: volunteerInformation.acceptOffer,
            newsletterConsent: volunteerInformation.acceptNewsletter,
            knownHomeClasseBy: volunteerInformation.knownHomeClasseBy,
            // acceptCgu: volunteerInformation.acceptCgu,
            enqueteMentorat: volunteerInformation.enqueteMentorat,
            status: VolunteerStatus.WAITING,
          }),
        );
      } else {
        if (email == volunteerInformation.email) {
          dispatch(
            // email: volunteerInformation.email,
            updateVolunteerInfo({
              id: volunteerId,
              request: {
                // email: '',
                address: volunteerInformation.address,
                postalCode: volunteerInformation.postCode,
                city: volunteerInformation.city,
                birthday: volunteerInformation.birthDate,
                currentStatus: volunteerInformation.situation?.toString() || '',
                activeCompanyName: volunteerInformation.structureName || '',
                schoolName: volunteerInformation.structureName || '',
                schoolCity: volunteerInformation.structureCity || '',
                expectedDegree: volunteerInformation.educationLevel?.toString() || '',
                schoolValued: volunteerInformation.appreciateByUniversity === OptionYesNo.OUI,
                hoursEngage: volunteerInformation.hoursAppreciateByUniversity || '',
                firstName: volunteerInformation.firstName,
                lastName: volunteerInformation.lastName,
                gender: volunteerInformation.gender?.toString() || '',
                reunion: volunteerInformation.reunion?.toString() || '',
                studyDomain: volunteerInformation.studyOrWorkField || '',
                situationpresi: volunteerInformation.situationpresi || '',
                phoneNumber: volunteerInformation.phoneNumber,
                partnerOffersConsent: volunteerInformation.acceptOffer,
                newsletterConsent: volunteerInformation.acceptNewsletter,
                knownHomeClasseBy: volunteerInformation.knownHomeClasseBy,
                enqueteMentorat: volunteerInformation.enqueteMentorat,
              },
            }),
          );
        } else {
          dispatch(
            updateVolunteerInfo({
              id: volunteerId,
              request: {
                email: volunteerInformation.email,
                address: volunteerInformation.address,
                postalCode: volunteerInformation.postCode,
                city: volunteerInformation.city,
                birthday: volunteerInformation.birthDate,
                currentStatus: volunteerInformation.situation?.toString() || '',
                activeCompanyName: volunteerInformation.structureName || '',
                schoolName: volunteerInformation.structureName || '',
                schoolCity: volunteerInformation.structureCity || '',
                expectedDegree: volunteerInformation.educationLevel?.toString() || '',
                schoolValued: volunteerInformation.appreciateByUniversity === OptionYesNo.OUI,
                hoursEngage: volunteerInformation.hoursAppreciateByUniversity || '',
                firstName: volunteerInformation.firstName,
                lastName: volunteerInformation.lastName,
                gender: volunteerInformation.gender?.toString() || '',
                reunion: volunteerInformation.reunion?.toString() || '',
                studyDomain: volunteerInformation.studyOrWorkField || '',
                situationpresi: volunteerInformation.situationpresi || '',
                phoneNumber: volunteerInformation.phoneNumber,
                partnerOffersConsent: volunteerInformation.acceptOffer,
                newsletterConsent: volunteerInformation.acceptNewsletter,
                enqueteMentorat: volunteerInformation.enqueteMentorat,
              },
            }),
          );
        }
      }
    } else {
      settosub(true);
      if (volunteerInformation.postCode && volunteerInformation.postCode.length != 5) seterrc(true);
      if (volunteerInformation.acceptCgu) window.scrollTo({top: 0, behavior: 'smooth'});
    }
    setclose(false);
  };
  const handleSubmit = (): void => {
    seterrorApeare(true);
    sendData();
  };

  const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  useEffect(() => {
    if (volunteerInformation.city) setcities([volunteerInformation.city]);
  }, [volunteerInformation]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    seterrPhone(true);
    if (event.target.name == 'postCode') {
      axios
        .get(`https://vicopo.selfbuild.fr/cherche/${event.target.value}`)
        .then((response) => {
          let ret = response.data.cities.map((element: any) => {
            return element.city;
          });
          ret = ret.filter((element: string) => element != 'ERREUR');
          ret = ret.filter(onlyUnique);
          setcities(ret);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    const newVolunteerInfo: VolunteerInformation = {
      ...volunteerInformation,
      [event.target.name]: event.target.value,
      ['city']: '',
    };
    switch (newVolunteerInfo.situation) {
      case Situation.SEARCHING_JOB:
      case Situation.RETIREMENT:
        newVolunteerInfo.structureName = undefined;
        newVolunteerInfo.structureCity = undefined;
        break;
      case Situation.ACTIVE:
        newVolunteerInfo.structureCity = undefined;
        break;
      case Situation.STUDENT:
        if (event.target.name == 'structureName') {
          newVolunteerInfo.structureName = undefined;
          newVolunteerInfo.structureCity = undefined;
          handleChangeSchoolInfo(event, newVolunteerInfo);
        }
        break;
      default:
        break;
    }

    dispatch(volunteerInfoActions.updateVolunteerInformation(newVolunteerInfo));
    dispatch(volunteerInfoActions.resetRequestApiState());
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newVolunteerInfo: VolunteerInformation = {
      ...volunteerInformation,
      [event.target.name]: event.target.value,
    };

    dispatch(volunteerInfoActions.updateVolunteerInformation(newVolunteerInfo));
    dispatch(volunteerInfoActions.resetRequestApiState());
  };

  const handleToggleChange = (name: string, isChecked: boolean) => {
    const newVolunteerInfo: VolunteerInformation = {
      ...volunteerInformation,
      [name]: isChecked,
    };

    dispatch(volunteerInfoActions.updateVolunteerInformation(newVolunteerInfo));
    dispatch(volunteerInfoActions.resetRequestApiState());
  };

  const getBirthDayError = (): string => {
    if (!volunteerInformation.birthDate) {
      return appText.registrationVolunteer.informations.error.ageEmpty;
    }
    if (volunteerInformation.birthDate) {
      // if (!isDateFormatValid(volunteerInformation.birthDate)) {
      //   return appText.registrationVolunteer.informations.error.ageNotValid;
      // }
      const ValidDate = volunteerInformation.birthDate.split('/').reverse().join('-');
      const BirthDate = new Date(ValidDate);
      const birthDayMin = new Date(maxBirthdate);
      const birthDayMax = new Date(minBirthdate);

      if (BirthDate > birthDayMin) {
        return appText.registrationVolunteer.informations.error.ageNotInInterval;
      }
      if (BirthDate < birthDayMax) {
        return appText.registrationVolunteer.informations.error.ageNotInInterval;
      }
    }
    return '';
  };

  const canContinue = () => {
    if (isRegistrationForm) {
      if (volunteerInformation.postCode) {
        const defaultRequired =
          volunteerInformation.firstName &&
          volunteerInformation.postCode.length == 5 &&
          volunteerInformation.lastName &&
          volunteerInformation.gender &&
          volunteerInformation.email &&
          volunteerInformation.knownHomeClasseBy &&
          volunteerInformation.enqueteMentorat &&
          isEmail(volunteerInformation.email) &&
          volunteerInformation.phoneNumber &&
          isPhoneNumber(volunteerInformation.phoneNumber) &&
          !getErrorBirth(getBirthDayError()) &&
          volunteerInformation.reunion &&
          volunteerInformation.situation &&
          getBirthDayError() === '' &&
          ((isRegistrationForm && volunteerInformation.acceptCgu) || !isRegistrationForm);
        return defaultRequired;
      }
      const defaultRequired =
        volunteerInformation.firstName &&
        // volunteerInformation.postCode.length == 5 &&
        volunteerInformation.lastName &&
        volunteerInformation.gender &&
        volunteerInformation.email &&
        volunteerInformation.knownHomeClasseBy &&
        volunteerInformation.enqueteMentorat &&
        isEmail(volunteerInformation.email) &&
        volunteerInformation.phoneNumber &&
        isPhoneNumber(volunteerInformation.phoneNumber) &&
        !getErrorBirth(getBirthDayError()) &&
        volunteerInformation.reunion &&
        volunteerInformation.situation &&
        getBirthDayError() === '' &&
        ((isRegistrationForm && volunteerInformation.acceptCgu) || !isRegistrationForm);
      return defaultRequired;
    }
    const defaultRequired =
      volunteerInformation.firstName &&
      volunteerInformation.lastName &&
      volunteerInformation.gender &&
      volunteerInformation.email &&
      volunteerInformation.enqueteMentorat &&
      volunteerInformation.knownHomeClasseBy &&
      isEmail(volunteerInformation.email) &&
      volunteerInformation.phoneNumber &&
      isPhoneNumber(volunteerInformation.phoneNumber) &&
      !getErrorBirth(getBirthDayError()) &&
      volunteerInformation.reunion &&
      volunteerInformation.situation &&
      getBirthDayError() === '' &&
      ((isRegistrationForm && volunteerInformation.acceptCgu) || !isRegistrationForm);

    return defaultRequired;
  };

  const renderStudyOrWorkField = (label: string) => (
    <SelectInput
      label={label}
      name="studyOrWorkField"
      id="studyOrWorkField"
      onChange={handleSelectChange}
      options={Object.values(VolunteerStudyOrWorkField)}
      defaultEmptyValueTitle="--Choisissez votre domaine--"
      className="w-full"
      value={volunteerInformation.studyOrWorkField}
    />
  );

  const renderSituation = () => {
    if (volunteerInformation.situation === Situation.STUDENT) {
      return (
        <>
          <div className="flex flex-col mt-6">
            <TextInput
              displayName="Nom de l'établissement"
              type="text"
              id="structureName"
              name="structureName"
              onChange={handleChange}
              value={schoolName ?? volunteerInformation.structureName}
              mandatory="True"
            />
          </div>
          {etablissement && (
            <div className="suggestion_box">
              {etablissement.map((etablissement, i) => (
                <div
                  key={i}
                  className="suggestion col-md-12 justify-content-md-center"
                  onClick={() => onEtablissementHandler(etablissement, volunteerInformation)}>
                  {etablissement.name}
                </div>
              ))}
            </div>
          )}
          {!volunteerInformation.structureName && errorApeare && (
            <div className="mb-6 text-clStateError">
              {appText.registrationStudent.informations.error.schoolNameEmpty}
            </div>
          )}
          {schoolInfoChars && (
            <div className="mb-6 text-clStateError">
              {appText.registrationStudent.informations.error.schoolNameIncomplete}
            </div>
          )}
          <div className="flex flex-col mt-6">
            <TextInput
              displayName="Ville"
              type="text"
              id="structureCity"
              name="structureCity"
              value={volunteerInformation.structureCity}
            />
          </div>
          <div className="flex flex-col mt-6">
            <SelectInput
              label="Votre niveau de cursus"
              name="educationLevel"
              id="educationLevel"
              defaultEmptyValueTitle="--Choisissez votre niveau de cursus--"
              options={Object.values(VolunteerEducationLevel)}
              onChange={handleSelectChange}
              className="w-full"
              value={volunteerInformation.educationLevel}
            />
          </div>
          <div className="flex flex-col mt-6">{renderStudyOrWorkField("Votre domaine d'étude")}</div>
          <div className="flex flex-col mt-6 mb-6">
            <label className={labelClass}>
              Votre engagement est-il valorisé par votre établissement (pris en compte dans votre cursus) ?
            </label>
            <div className="flex" onChange={handleChange}>
              <RadioButton
                value={OptionYesNo.OUI}
                className="mr-4"
                name="appreciateByUniversity"
                checked={volunteerInformation.appreciateByUniversity === OptionYesNo.OUI}
              />
              <RadioButton
                value={OptionYesNo.NON}
                name="appreciateByUniversity"
                checked={volunteerInformation.appreciateByUniversity === OptionYesNo.NON}
              />
            </div>
          </div>
          {volunteerInformation.appreciateByUniversity === OptionYesNo.OUI && (
            <div className="flex flex-col mt-6">
              <TextInput
                displayName="Si oui, combien d'heures devez-vous réaliser dans ce cadre ?"
                type="number"
                id="hoursAppreciateByUniversity"
                name="hoursAppreciateByUniversity"
                min="1"
                onChange={handleChange}
                value={
                  volunteerInformation.hoursAppreciateByUniversity
                    ? volunteerInformation.hoursAppreciateByUniversity
                    : ''
                }
                className="w-20"
              />
            </div>
          )}
        </>
      );
    }
    if (Situation.ACTIVE === volunteerInformation.situation) {
      return (
        <>
          <div className="flex flex-col mt-6 mb-6">
            <TextInput
              displayName="Nom de votre structure / société"
              type="text"
              id="structureName"
              name="structureName"
              value={volunteerInformation.structureName}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col mt-6 mb-6">{renderStudyOrWorkField('Domaine')}</div>
        </>
      );
    }
    if (
      Situation.RETIREMENT === volunteerInformation.situation ||
      Situation.SEARCHING_JOB === volunteerInformation.situation
    ) {
      return (
        <>
          <div className="flex flex-col mt-6 mb-6">
            <TextInput
              displayName="Précisez votre situation actuelle"
              type="text"
              id="situationpresi"
              name="situationpresi"
              value={volunteerInformation.situationpresi}
              onChange={handleChange}
            />
          </div>
        </>
      );
    }
  };

  const onEtablissementHandler = (text: SchoolInfoInterface, volunteerInformation: VolunteerInformation) => {
    const newVolunteerInfo: VolunteerInformation = {
      ...volunteerInformation,
    };
    newVolunteerInfo.structureName = text.name;
    newVolunteerInfo.structureCity = text.commune;
    setschool(text.name);

    dispatch(volunteerInfoActions.updateVolunteerInformation(newVolunteerInfo));
    dispatch(volunteerInfoActions.resetRequestApiState());

    setetab([]);
  };

  const handleChangeSchoolInfo = (event: ChangeEvent<HTMLInputElement>, newVolunteerInfo: VolunteerInformation) => {
    newVolunteerInfo.structureName = event.target.value;
    setschool(event.target.value);
    if (event.target.value.length > 8) {
      setSchoolInfoChars(false);
      if (event.target.value.length % 2) {
        const ecole1: SchoolInfoInterface = {name: '', zip: '', commune: ''};
        const list = [ecole1];
        list.pop();
        axios
          .get(
            `https://data.enseignementsup-recherche.gouv.fr/api/records/1.0/search/?dataset=fr-esr-principaux-etablissements-enseignement-superieur&q=${event.target.value.replaceAll(
              ' ',
              '+',
            )}&facet=uo_lib_officiel&facet=com_code&facet=uo_lib`,
          )
          .then((response) => {
            response.data.records.forEach((element: {fields: any}) => {
              const elt = element.fields;
              const name = elt.uo_lib_officiel ? elt.uo_lib_officiel : elt.uo_lib;
              const ecole: SchoolInfoInterface = {
                name: name + ' ' + elt.com_code + ' ' + elt.com_nom,
                zip: elt.com_code,
                commune: elt.com_nom,
              };
              list.push(ecole);
            });
            setetab(list);
          });
      }
    } else {
      setSchoolInfoChars(true);
    }
  };

  const btnColor = !isRegistrationForm && isSuccess ? 'clValidate' : 'mainOrange';
  const btnTitle = isRegistrationForm ? 'Suivant' : !isRegistrationForm && isSuccess ? 'Enregistré !' : 'Enregistrer';

  return (
    <div className="h-full">
      <div className="flex-1 h-full w-85.75 lg:w-131 mx-auto flex flex-col items-center">
        <div className="w-full p-8 mb-3 bg-clWhite rounded-8px shadow-formRectangle">
          {renderTitle(isRegistrationForm)}
          <div className="flex flex-col mb-6">
            <span className={labelClass}>
              {appText.registrationVolunteer.reunion}
              <span className="mandatory-star">*</span>
            </span>
            <div className="flex mt-2" onChange={handleChange}>
              <RadioButton
                value={ReunionParticipe.OUI}
                className="mr-4"
                name="reunion"
                checked={volunteerInformation.reunion === ReunionParticipe.OUI}
                // required
              />
              <RadioButton
                value={ReunionParticipe.NON}
                name="reunion"
                checked={volunteerInformation.reunion === ReunionParticipe.NON}
                // required
              />
            </div>
          </div>
          {!volunteerInformation.reunion && errorApeare && (
            <div className="mt-2 text-clStateError">
              {appText.registrationVolunteer.informations.error.reunionEmpty}
            </div>
          )}
          {volunteerInformation.reunion === ReunionParticipe.OUI ? (
            <>
              {' '}
              <div className="flex flex-col lg:flex-row mb-6">
                <div className="flex-1 flex flex-col md:mr-4">
                  <TextInput
                    type="text"
                    id="lastName"
                    name="lastName"
                    displayName="Prénom"
                    mandatory="True"
                    onChange={handleChange}
                    value={volunteerInformation.lastName}
                    className="w-full lg:w-55.5"
                    // required
                  />
                  {!volunteerInformation.lastName && errorApeare && (
                    <div className="mt-2 text-clStateError">
                      {appText.registrationVolunteer.informations.error.firstNameEmpty}
                    </div>
                  )}
                </div>
                <div className="flex-1 flex flex-col md:mr-4 mb-6 lg:mb-0 mr-0 lg:mr-4">
                  <TextInput
                    displayName="Nom"
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    mandatory="True"
                    value={volunteerInformation.firstName}
                    className="w-full lg:w-55.5"
                    // required
                  />
                  {!volunteerInformation.firstName && errorApeare && (
                    <div className="mt-2 text-clStateError">
                      {appText.registrationVolunteer.informations.error.lastNameEmpty}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col lg:flex-row mb-6">
                <div className="flex flex-col mr-3">
                  <TextInput
                    displayName="Date de naissance"
                    className="w-50 lg:w-55.5 mx-1"
                    type="date"
                    max={maxBirthdate}
                    min={minBirthdate}
                    mandatory="True"
                    id="birthDate"
                    name="birthDate"
                    value={volunteerInformation.birthDate}
                    onChange={handleChange}
                    // required
                  />
                  {getBirthDayError() && errorApeare && (
                    <div className="mt-2 text-clStateError">{getBirthDayError()}</div>
                  )}
                </div>
                <div className="mx-1 mb-6" onChange={handleChange}>
                  <label className="text-base text-greyMedium font-medium">
                    Sexe
                    <span className="mandatory-star">*</span>
                  </label>
                  <div className="flex flex-row items-center h-4/6 mt-1">
                    <RadioButton
                      value={Gender.HOMME}
                      className="mr-4"
                      name="gender"
                      checked={volunteerInformation.gender === Gender.HOMME}
                      // required
                    />
                    <RadioButton
                      value={Gender.FEMME}
                      name="gender"
                      checked={volunteerInformation.gender === Gender.FEMME}
                      // required
                    />
                  </div>
                  {!volunteerInformation.gender && errorApeare && (
                    <div className="mt-1 text-clStateError">
                      {appText.registrationVolunteer.informations.error.genderEmpty}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <EmailInput
                  disabled={!isRegistrationForm}
                  displayName="Adresse e-mail"
                  id="email"
                  name="email"
                  value={volunteerInformation.email}
                  mandatory="True"
                  onChange={handleChange}
                  errorApeare={errorApeare}
                  seterr={seterr}
                  // required
                />
                {!isEmailEmpty(volunteerInformation.email) && errorApeare && err ? (
                  <div className="mt-2 text-clStateError">
                    {appText.registrationStudent.informations.error.emailNotValid}
                  </div>
                ) : errorMsg ==
                  `Une erreur est survenue. Merci de renseigner les bonnes informations. L'email ${volunteerInformation.email} est déjà enregistrée dans notre base.` ? (
                  <div className="mb-4 text-clStateError">
                    L&apos;email {volunteerInformation.email} est déjà enregistré dans notre base, merci de renseigner
                    un autre email ou de nous contacter sur homeclasse@zupdeco.org.
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col mb-6">
                <PhoneInput
                  displayName="Téléphone"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={handleChange}
                  mandatory="True"
                  value={volunteerInformation.phoneNumber}
                  errorApeare={errorApeare}
                  seterr={seterrPhone}
                  // required
                />
                {!isPhoneNumberEmpty(volunteerInformation.phoneNumber) && errPhone && errorApeare && (
                  <div className="mt-2 text-clStateError">
                    {appText.registrationStudent.informations.error.phoneNotValid}
                  </div>
                )}
              </div>
              <div className="flex flex-col mb-6">
                <TextInput
                  displayName={isRegistrationForm ? 'Adresse' : 'Adresse *'}
                  type="text"
                  id="address"
                  name="address"
                  value={volunteerInformation.address}
                  onChange={handleChange}
                />
              </div>
              {!volunteerInformation.address && errorApeare && !isRegistrationForm && (
                <div className="mt-2 text-clStateError">
                  {appText.registrationVolunteer.informations.error.adressEmpty}
                </div>
              )}
              <div className="flex flex-col lg:flex-row my-6 mb-6">
                <div className="flex-1 flex flex-col md:mr-4 lg:mb-0 mr-0 lg:mr-4">
                  <ZipCodeInput
                    className="w-full lg:w-44"
                    displayName={isRegistrationForm ? 'Code postal' : 'Code postal *'}
                    id="postCode"
                    name="postCode"
                    value={volunteerInformation.postCode}
                    onChange={handleChange}
                    errorApeare={errorApeare}
                    seterrc={seterrc}
                  />
                  {volunteerInformation.postCode && errc && (
                    <div className="mt-2 text-clStateError">Le code postal n&apos;est pas valide.</div>
                  )}
                </div>
                <div className="flex-1 flex flex-col">
                  <SelectInput
                    className="w-full lg:w-60"
                    defaultEmptyValueTitle="--Choisissez votre ville--"
                    label={isRegistrationForm ? 'Ville' : 'Ville *'}
                    // type="text"
                    options={cities}
                    id="city"
                    name="city"
                    onChange={handleSelectChangeVolunteerInformation}
                    onPress={setclickcity}
                    value={volunteerInformation.city}
                  />
                  {!volunteerInformation.city && errorApeare && !isRegistrationForm && (
                    <div className="mt-2 text-clStateError">
                      {appText.registrationVolunteer.informations.error.cityEmpty}
                    </div>
                  )}
                  {!volunteerInformation.postCode && clickcity && !volunteerInformation.city && (
                    <div className="mb-4 mt-2 text-clStateError">Veuillez saisir un code postal au préalable.</div>
                  )}
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <SelectInput
                  label="Situation actuelle"
                  name="situation"
                  id="situation"
                  defaultEmptyValueTitle="--Choisissez votre situation--"
                  options={Object.values(Situation)}
                  mandatory="True"
                  onChange={handleSelectChange}
                  className="w-full"
                  value={volunteerInformation.situation}
                  // required
                />
                {!volunteerInformation.situation && errorApeare && (
                  <div className="mt-2 text-clStateError">
                    {appText.registrationVolunteer.informations.error.situationEmpty}
                  </div>
                )}
              </div>
              {renderSituation()}
              <div className="mb-3">
                <SelectInput
                  label="Comment avez-vous eu connaissance d'HomeClasse ?"
                  mandatory="True"
                  name="knownHomeClasseBy"
                  id="knownHomeClasseBy"
                  defaultEmptyValueTitle="-- Veuillez choisir dans la liste  --"
                  options={Object.values(MediaReferent)}
                  value={volunteerInformation.knownHomeClasseBy}
                  onChange={handleSelectChange}
                  className="w-full"
                />
                {!volunteerInformation.knownHomeClasseBy && errorApeare && (
                  <div className="mt-2 text-clStateError">
                    {appText.registrationStudent.informations.error.knownHomeClasseByEmpty}
                  </div>
                )}
              </div>
            </>
          ) : volunteerInformation.reunion === ReunionParticipe.NON ? (
            <ReactModal
              isOpen={true}
              className="md:w-135 sm:w-full my-auto"
              overlayClassName="fixed inset-0 flex justify-center bg-backgroundModal overflow-auto z-50">
              <div className="bg-clWhite p-8 rounded-lg shadow-sm">
                <div className="mb-8 text-mainPurple text-2xl font-bold">
                  Afin d&apos;avoir toutes les informations avant de vous inscrire, la présence à une réunion
                  d&apos;information est nécessaire.
                </div>
                <Button
                  className="w-full text-base my-2"
                  color="mainOrange"
                  onClick={() => {
                    dispatch(volunteerInfoActions.updatereunionvolunteer());
                    dispatch(volunteerInfoActions.resetRequestApiState());
                    history.push(`/meeting/volunteer/${MeetingType.INFORMATION}`);
                  }}>
                  Assister à une réunion
                </Button>
                <div className="flex justify-center mt-8">
                  <a
                    className="mx-8 font-bold text-base text-greyMedium text-center cursor-pointer"
                    color="greyMedium"
                    onClick={handleretourn}>
                    Quitter et revenir à l&apos;accueil
                  </a>
                </div>
              </div>
            </ReactModal>
          ) : (
            <></>
          )}
        </div>
        {isRegistrationForm && volunteerInformation.reunion === ReunionParticipe.OUI && (
          <>
            <div className="w-full p-8 mb-3 bg-clWhite rounded-8px shadow-formRectangle">
              <h2 className="mb-2 text-2xl text-greyMedium font-bold">Enquête sur le mentorat</h2>
              <div className="flex flex-col mb-4">
                <span className={labelClass}>
                  J’accepte d’être recontacté pour participer à une enquête sur le mentorat.
                  <span className="mandatory-star">*</span>
                </span>
                <div className="flex mt-2" onChange={handleChange}>
                  <RadioButton
                    value={OptionYesNo.OUI}
                    className="mr-4"
                    name="enqueteMentorat"
                    checked={volunteerInformation.enqueteMentorat === OptionYesNo.OUI}
                    required
                  />
                  <RadioButton
                    value={OptionYesNo.NON}
                    name="enqueteMentorat"
                    checked={volunteerInformation.enqueteMentorat === OptionYesNo.NON}
                    required
                  />
                </div>
              </div>
              {!volunteerInformation.enqueteMentorat && errorApeare && (
                <div className="mt-2 text-clStateError">
                  {appText.registrationVolunteer.informations.error.enqueteMentoratEmpty}
                </div>
              )}
            </div>
            <div className="flex-1 h-full w-85.75 lg:w-131 mx-auto flex flex-col items-center">
              <div className="w-full p-8 mb-21.75 bg-clWhite rounded-8px shadow-formRectangle">
                <h2 className="mb-2 text-base text-greyMedium">
                  L&apos;autorisation parentale est obligatoire pour les bénévoles mineurs.
                </h2>
                <div className="flex flex-row mb-4">
                  <div className="flex mr-3 items-center">
                    <ToggleSwitch
                      isChecked={volunteerInformation.acceptCgu}
                      onChange={(isChecked: boolean) => handleToggleChange('acceptCgu', isChecked)}
                      // required
                    />
                  </div>
                  <label className="align-middle text-greyDark text-base">
                    En remplissant ce formulaire, j’accepte{' '}
                    <Link
                      className="inhover"
                      target="_blank"
                      to={{pathname: 'https://zupdeco.homeclasse.org/confidentialite/'}}>
                      les conditions générales et la politique de confidentialité de Homeclasse
                    </Link>
                    .
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        {tosub && <div className="mb-4 text-center text-clStateError">Veuillez renseigner les champs obligatoires</div>}
        {errorMsg !=
        `Une erreur est survenue. Merci de renseigner les bonnes informations. L'email ${volunteerInformation.email} est déjà enregistrée dans notre base.` ? (
          <div className="mb-4 text-center text-clStateError" dangerouslySetInnerHTML={{__html: errorMsg}} />
        ) : (
          <></>
        )}
        {isRegistrationForm && !volunteerInformation.acceptCgu && (
          <div className="mb-4 text-center text-clStateError">
            {appText.registrationVolunteer.informations.error.cguNotAccept}
          </div>
        )}
        {!isLoading && (
          <>
            <div className="w-100 flex mt-auto">
              <Button className="w-85.75 lg:w-115 mx-auto" color={btnColor} onClick={handleSubmit}>
                {btnTitle}
              </Button>
            </div>
          </>
        )}
        {isLoading && (
          <>
            <div className="w-100 flex mt-auto">
              <Spinner className="mt-auto mx-auto" />
            </div>
          </>
        )}
        {!isRegistrationForm && <Homelink />}
        <ReactModal
          className="absolute w-85.75 md:w-auto top-40 inset-x-center-modal-mobile md:inset-x-1/3 p-8 bg-clWhite rounded-8px border-none overflow-auto outline-none"
          overlayClassName="fixed inset-0 bg-greyDark bg-opacity-50 overflow-auto z-50"
          isOpen={close}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}>
          <div>
            <div className="flex flex-col text-greyMedium">
              <h3 className="mb-4 text-2xl text-greyMedium font-bold">Attention</h3>
              <h4 className="text-base text-greyMedium">Vous êtes déjà connecté.</h4>
              <h4 className="text-base text-greyMedium">
                Si vous souhaitez créer un nouveau compte veuillez sélectionner <br /> « Déconnexion ».
              </h4>
              <h4 className="mb-4 text-base text-greyMedium">
                Si vous souhaitez mettre à jour vos informations veuillez sélectionner <br /> « Continuer ».
              </h4>
              <div className="flex justify-around relative" style={{width: '100%'}}>
                <button
                  className="close bg-mainOrange rounded w-85.75 lg:w-115 h-10"
                  style={{padding: '0.7rem 0', color: 'white', marginRight: '5px'}}
                  onClick={handleClick}>
                  Continuer
                </button>
                <button
                  className="close bg-mainOrange rounded w-85.75 lg:w-115 h-10"
                  style={{padding: '0.7rem 0', color: 'white', marginLeft: '5px'}}
                  onClick={handleLogout}>
                  Déconnexion
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    </div>
  );
};
