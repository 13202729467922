import {configureStore} from '@reduxjs/toolkit';
import {studentInfoSlice} from './reducers/studentInfoSlice';
import {userSlice} from './reducers/userSlice';
import {volunteerInfoSlice} from './reducers/volunteerInfoSlice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    studentInfo: studentInfoSlice.reducer,
    volunteerInfo: volunteerInfoSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
