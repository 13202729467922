import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {VolunteerInformation} from '../../interfaces/VolunteerInformation';
import {VolunteerResponsibility} from '../../interfaces/VolunteerResponsibility';
import {RootState} from '../store';
import {VolunteerAvailability} from '../../interfaces/VoluntererAvailability';
import ApiService from '../../services/ApiService';
import {CreateTeacherResponse} from '../../interfaces/api/CreateTeacherResponse';
import {UpdateTeacherRequest} from '../../interfaces/api/UpdateTeacherRequest';
import {CreateTeacherRequest} from '../../interfaces/api/CreateTeacherRequest';
import {VolunteerEducationLevel} from '../../enums/VolunteerEducationLevel';
import {OptionYesNo, ReunionParticipe} from '../../enums/OptionYesNo';
import {VolunteerStudyOrWorkField} from '../../enums/VolunteerStudyField';
import {Situation} from '../../enums/Situation';
import {Gender} from '../../enums/Gender';
import {VolunteerDocumentType} from '../../enums/VolunteerDocumentType';
import {VolunteerProfileResponse} from '../../interfaces/api/VolunteerProfileResponse';
import {AirtableFileResponse} from '../../interfaces/api/AirtableFileResponse';
import {UploadFileResponse} from '../../interfaces/api/UploadFileResponse';
import {CalendlyResponse} from '../../interfaces/api/CalendlyResponse';
import {MeetingType} from '../../enums/MeetingType';
import {VolunteerStatus} from '../../enums/VolunteerStatus';
import {StudentAssigned} from '../../interfaces/api/StudentAssigned';

interface VolunteerInfoSliceState {
  teacherId: string;
  volunteerInformation: VolunteerInformation;
  volunteerMeetingInfo: Record<string, CalendlyResponse | null>;
  volunteerResponsibility: VolunteerResponsibility;
  volunteerAvailability: VolunteerAvailability;
  volunteerDocuments: Record<string, AirtableFileResponse[]>;
  studentsAccompagnied: StudentAssigned[];
  isProfileInfoLoaded: boolean;
  isPending: Record<string, boolean>;
  isSuccess: Record<string, boolean>;
  errorMsg: Record<string, string>;
  volunteerStatus?: VolunteerStatus;
  commitmentCharter: boolean;
}

const initialState: VolunteerInfoSliceState = {
  volunteerInformation: {
    firstName: '',
    lastName: '',
    gender: undefined,
    email: '',
    address: '',
    postCode: '',
    city: '',
    situation: undefined,
    birthDate: '',
    structureName: '',
    phoneNumber: '',
    structureCity: '',
    studyOrWorkField: undefined,
    educationLevel: undefined,
    appreciateByUniversity: undefined,
    acceptOffer: true,
    acceptNewsletter: false,
    acceptCgu: true,
    trainingMeetingEvent: '',
    individualMeetingEvent: '',
    informationMeetingEvent: '',
    startcharte: '',
    endcharte: '',
    knownHomeClasseBy: '',
  },
  volunteerResponsibility: {
    nbStudentAccompanied: 1,
    levelStudent: [],
  },
  volunteerAvailability: {
    availableSchoolYear: undefined,
    availabilitiesCalendar: [
      {
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
      },
    ],
  },
  volunteerMeetingInfo: {
    [MeetingType.INDIVIDUAL]: null,
    [MeetingType.TRAINING]: null,
    [MeetingType.INFORMATION]: null,
  },
  volunteerDocuments: {
    [VolunteerDocumentType.CRIMINAL_RECORDS]: [],
    [VolunteerDocumentType.OTHER_FILES]: [],
  },
  volunteerStatus: undefined,
  commitmentCharter: true,
  studentsAccompagnied: [],
  isPending: {},
  isSuccess: {},
  errorMsg: {},
  teacherId: '',
  isProfileInfoLoaded: false,
};

// TODO: async save volunteer info example
export interface UpdateTeacherThunkRequest {
  request: UpdateTeacherRequest;
  id: string;
}

export const updateVolunteerInfo = createAsyncThunk<void, UpdateTeacherThunkRequest>(
  'volunteer/update',
  async (updateTeacherThunkRequest: UpdateTeacherThunkRequest, thunkAPI) => {
    try {
      await ApiService.updateTeacher(updateTeacherThunkRequest.request, updateTeacherThunkRequest.id);
      return;
    } catch (e) {
      thunkAPI.rejectWithValue((e as {message: string}).message || 'Une erreur est survenu');
    }
  },
);

interface UploadVolunteerDocumentThunkRequest {
  documentsFormData: Record<string, FormData[]>;
  volunteerDocuments: Record<string, AirtableFileResponse[]>;
  volunteerId: string;
  currentVolunteerStatus?: VolunteerStatus;
}

export const uploadVolunteerDocument = createAsyncThunk<void, UploadVolunteerDocumentThunkRequest>(
  'upload/volunteerDocument',
  async (uploadVolunteerDocumentThunkRequest: UploadVolunteerDocumentThunkRequest, thunkAPI) => {
    try {
      const updateTeacherRequest: UpdateTeacherRequest = {};

      await Promise.all(
        Object.values(VolunteerDocumentType).map(async (documentType) => {
          const existDocument: UploadFileResponse[] = uploadVolunteerDocumentThunkRequest.volunteerDocuments[
            documentType
          ]
            .filter((airtableFile) => Boolean(airtableFile))
            .map((airtableFile) => ({
              url: airtableFile.url,
            }));
          const newDocument: UploadFileResponse[] = await Promise.all(
            uploadVolunteerDocumentThunkRequest.documentsFormData[documentType].map((formData) =>
              ApiService.uploadFile(formData),
            ),
          );

          switch (documentType) {
            case VolunteerDocumentType.CRIMINAL_RECORDS:
              updateTeacherRequest.criminalRecords = [...existDocument, ...newDocument];
              break;
            case VolunteerDocumentType.OTHER_FILES:
              updateTeacherRequest.otherDocuments = [...existDocument, ...newDocument];
              break;
          }
        }),
      );

      await ApiService.updateTeacher(updateTeacherRequest, uploadVolunteerDocumentThunkRequest.volunteerId);
    } catch (e) {
      thunkAPI.rejectWithValue((e as {message: string}).message || 'Une erreur est survenu');
    }
  },
);

// TODO: async save student info example
export const createTeacher = createAsyncThunk<CreateTeacherResponse, CreateTeacherRequest>(
  'volunteer/create',
  async (createTeacherRequest: CreateTeacherRequest, thunkAPI) => {
    try {
      const response = await ApiService.createTeacher(createTeacherRequest);
      localStorage.setItem('access_token', response.access_token);
      localStorage.setItem('role', response.user.roles[0]);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue((e as {message: string}).message || 'Une erreur est survenu');
    }
  },
);

export const getVolunteerProfile = createAsyncThunk<VolunteerProfileResponse>(
  'volunteer/getProfileInfo',
  async (_, thunkAPI) => {
    try {
      const response: VolunteerProfileResponse = (await ApiService.getUsersProfile()) as VolunteerProfileResponse;
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue('Login ou password invalid');
    }
  },
);

export const getVolunteerMeetingInfo = createAsyncThunk<Record<string, CalendlyResponse>, Record<string, string>>(
  'volunteer/getMeetingInfo',
  async (volunteerMeetingThunkRequest: Record<string, string>, thunkAPI) => {
    try {
      let volunteerMeetingInfo: Record<string, CalendlyResponse> = {};

      const meetings = Object.values(MeetingType);

      await Promise.all(
        meetings.map(async (item) => {
          if (volunteerMeetingThunkRequest[item]) {
            const eventId = volunteerMeetingThunkRequest[item].split('/').pop();
            if (eventId) {
              const meetingResponse: CalendlyResponse = await ApiService.getCalendlyScheduledEvent(eventId);
              volunteerMeetingInfo = {
                ...volunteerMeetingInfo,
                [item]: meetingResponse,
              };
            }
          }
        }),
      );
      return volunteerMeetingInfo;
    } catch (e) {
      return thunkAPI.rejectWithValue('Login ou password invalid');
    }
  },
);

export const volunteerInfoSlice = createSlice({
  name: 'volunteerInfo',
  initialState,
  reducers: {
    resetRequestApiState: (state) => {
      state.isPending = {};
      state.isSuccess = {};
      state.errorMsg = {};
      if (sessionStorage.getItem('volunteerInformation') !== null) {
        state.volunteerInformation = JSON.parse(sessionStorage.volunteerInformation);
      }
      if (sessionStorage.getItem('volunteerAvailability') !== null) {
        state.volunteerAvailability = JSON.parse(sessionStorage.volunteerAvailability);
      }
      if (sessionStorage.getItem('volunteerResponsibility') !== null) {
        state.volunteerResponsibility = JSON.parse(sessionStorage.volunteerResponsibility);
      }
    },
    resetVolunteerInfoState: () => initialState,
    updateVolunteerInformation: (state, {payload}: PayloadAction<VolunteerInformation>) => {
      state.volunteerInformation = payload;
      if (payload.reunion !== ReunionParticipe.NON) {
        sessionStorage.setItem('volunteerInformation', JSON.stringify(payload));
      }
    },
    updateVolunteerCommitmentCharter: (state, {payload}: PayloadAction<boolean>) => {
      state.commitmentCharter = payload;
    },
    saveVolunteerResponsibility: (state, {payload}: PayloadAction<VolunteerResponsibility>) => {
      state.volunteerResponsibility = payload;
      sessionStorage.setItem('volunteerResponsibility', JSON.stringify(payload));
    },
    saveVolunteerAvailability: (state, {payload}: PayloadAction<VolunteerAvailability>) => {
      state.volunteerAvailability = payload;
      sessionStorage.setItem('volunteerAvailability', JSON.stringify(payload));
    },
    saveVolunteerDocuments: (state, {payload}: PayloadAction<Record<string, AirtableFileResponse[]>>) => {
      state.volunteerDocuments = payload;
    },
    updatereunionvolunteer: (state) => {
      state.volunteerInformation.reunion = ReunionParticipe.NULL;
    },
  },
  extraReducers: (builder) => {
    // create teacher api
    builder.addCase(createTeacher.fulfilled, (state, action) => {
      state.errorMsg.createTeacher = '';
      state.isPending.createTeacher = false;
      state.isSuccess.createTeacher = true;
      state.teacherId = action.payload.user.id;
    });
    builder.addCase(createTeacher.pending, (state) => {
      state.isPending.createTeacher = true;
    });
    builder.addCase(createTeacher.rejected, (state, action: any) => {
      state.errorMsg.createTeacher = `Une erreur est survenue. Merci de renseigner les bonnes informations.<br />${action.payload
        .split(',')
        .join('<br />')}`;
      state.isPending.createTeacher = false;
    });

    //
    // update volunteer info
    builder.addCase(updateVolunteerInfo.fulfilled, (state, action) => {
      state.errorMsg.updateVolunteerInfo = '';
      state.isPending.updateVolunteerInfo = false;
      state.isSuccess.updateVolunteerInfo = true;
    });
    builder.addCase(updateVolunteerInfo.pending, (state) => {
      state.isPending.updateVolunteerInfo = true;
    });
    builder.addCase(updateVolunteerInfo.rejected, (state, action: any) => {
      state.errorMsg.updateVolunteerInfo = `Une erreur est survenue. Merci de renseigner les bonnes informations.<br />${action.payload
        .split(',')
        .join('<br />')}`;
      state.isPending.updateVolunteerInfo = false;
    });

    // getVolunteerMeetingInfo
    builder.addCase(getVolunteerMeetingInfo.fulfilled, (state, action) => {
      state.errorMsg.getVolunteerMeetingInfo = '';
      state.isPending.getVolunteerMeetingInfo = false;
      state.isSuccess.getVolunteerMeetingInfo = true;
      state.volunteerMeetingInfo = action.payload;
    });
    builder.addCase(getVolunteerMeetingInfo.pending, (state) => {
      state.isPending.getVolunteerMeetingInfo = true;
    });
    builder.addCase(getVolunteerMeetingInfo.rejected, (state, action: any) => {
      state.errorMsg.getVolunteerMeetingInfo = `Une erreur est survenue. Merci de renseigner les bonnes informations.<br />${action.payload
        .split(',')
        .join('<br />')}`;
      state.isPending.getVolunteerMeetingInfo = false;
    });

    // upload volunteer document
    builder.addCase(uploadVolunteerDocument.fulfilled, (state, action) => {
      state.errorMsg.uploadVolunteerDocument = '';
      state.isPending.uploadVolunteerDocument = false;
      state.isSuccess.uploadVolunteerDocument = true;
    });
    builder.addCase(uploadVolunteerDocument.pending, (state) => {
      state.isPending.uploadVolunteerDocument = true;
    });
    builder.addCase(uploadVolunteerDocument.rejected, (state, action: any) => {
      state.errorMsg.uploadVolunteerDocument = `Une erreur est survenue. Merci de renseigner les bonnes informations.<br />${action.payload
        .split(',')
        .join('<br />')}`;
      state.isPending.uploadVolunteerDocument = false;
    });
    // get voluunterInfoProfile
    builder.addCase(getVolunteerProfile.fulfilled, (state, action) => {
      state.errorMsg.getVolunteerProfile = '';
      state.isPending.getVolunteerProfile = false;
      state.isSuccess.getVolunteerProfile = true;
      state.isProfileInfoLoaded = true;

      state.teacherId = action.payload.id;
      state.volunteerInformation = {
        firstName: action.payload.data.firstName,
        lastName: action.payload.data.lastName,
        gender: action.payload.data.gender as Gender,
        reunion: action.payload.data.reunion as ReunionParticipe,
        email: action.payload.data.email,
        address: action.payload.data.address,
        postCode: action.payload.data.postalCode,
        city: action.payload.data.city,
        // birthDate: formatDateBirthdayFromApi(action.payload.data.birthday),
        birthDate: action.payload.data.birthday,
        situation: action.payload.data.currentStatus as Situation,
        structureName: action.payload.data.schoolName,
        structureCity: action.payload.data.schoolCity,
        phoneNumber: action.payload.data.phoneNumber,
        situationpresi: action.payload.data.situationpresi,
        educationLevel: action.payload.data.expectedDegree as VolunteerEducationLevel,
        studyOrWorkField: action.payload.data.studyDomain as VolunteerStudyOrWorkField,
        appreciateByUniversity: action.payload.data.schoolValued ? OptionYesNo.OUI : OptionYesNo.NON,
        hoursAppreciateByUniversity: action.payload.data.hoursEngage,
        acceptOffer: action.payload.data.partnerOffersConsent,
        acceptCgu: action.payload.data.partnerOffersConsent,
        acceptNewsletter: action.payload.data.newsletterConsent,
        trainingMeetingEvent: action.payload.data.trainingMeetingEvent,
        informationMeetingEvent: action.payload.data.informationMeetingEvent,
        individualMeetingEvent: action.payload.data.individualMeetingEvent,
        startcharte: action.payload.data.startcharte,
        endcharte: action.payload.data.endcharte,
        knownHomeClasseBy: action.payload.data.knownHomeClasseBy,
      };
      state.volunteerAvailability = {
        availabilitiesCalendar: action.payload.data.availabilitiesCalendar,
        availableSchoolYear: action.payload.data.yearEngageConsent ? OptionYesNo.OUI : OptionYesNo.NON,
      };

      const nbStudentAccompanied =
        action.payload.data.acceptManyStudent === '3' ? 3 : action.payload.data.acceptManyStudent === '2' ? 2 : 1;
      state.volunteerResponsibility = {
        nbStudentAccompanied,
        levelStudent: action.payload.data.studentLevel || [],
      };
      state.volunteerDocuments = {
        [VolunteerDocumentType.CRIMINAL_RECORDS]: action.payload.data.criminalRecords || [],
        [VolunteerDocumentType.OTHER_FILES]: action.payload.data.otherDocuments || [],
      };
      state.volunteerStatus = action.payload.data.status;
      state.studentsAccompagnied = action.payload.data.students;
      state.commitmentCharter = action.payload.data.commitmentCharter ? action.payload.data.commitmentCharter : false;
    });
    builder.addCase(getVolunteerProfile.pending, (state) => {
      state.isPending.getVolunteerProfile = true;
    });
    builder.addCase(getVolunteerProfile.rejected, (state, action: any) => {
      state.errorMsg.getVolunteerProfile = `Une erreur est survenue. Merci de renseigner les bonnes informations.<br />${action.payload
        .split(',')
        .join('<br />')}`;
      state.isPending.getVolunteerProfile = false;
    });
  },
});

export const volunteerInfoActions = volunteerInfoSlice.actions;

export const volunteerProfileInfoLoadedSelector = (state: RootState): boolean =>
  state.volunteerInfo.isProfileInfoLoaded;

export const volunteerInfoSelector = (state: RootState): VolunteerInformation =>
  state.volunteerInfo.volunteerInformation;

export const volunteerMeetingInfoSelector = (state: RootState): Record<string, CalendlyResponse | null> =>
  state.volunteerInfo.volunteerMeetingInfo;

export const volunteerStatusSelector = (state: RootState): VolunteerStatus | undefined =>
  state.volunteerInfo.volunteerStatus;

export const volunteerCommitmentCharterSelector = (state: RootState): boolean => state.volunteerInfo.commitmentCharter;

export const volunteerIdSelector = (state: RootState): string => state.volunteerInfo.teacherId;

export const volunteerPendingSelector = (state: RootState): Record<string, boolean> => state.volunteerInfo.isPending;
export const volunteerSuccessSelector = (state: RootState): Record<string, boolean> => state.volunteerInfo.isSuccess;

export const volunteerErrorSelector = (state: RootState): Record<string, string> => state.volunteerInfo.errorMsg;

export const volunteerResponsabilitySelector = (state: RootState): VolunteerResponsibility =>
  state.volunteerInfo.volunteerResponsibility;

export const volunteerAvailabilitySelector = (state: RootState): VolunteerAvailability =>
  state.volunteerInfo.volunteerAvailability;

export const volunteerDocumentsSelector = (state: RootState): Record<string, AirtableFileResponse[]> =>
  state.volunteerInfo.volunteerDocuments;

export const volunteerStudentsAssignedSelector = (state: RootState): StudentAssigned[] =>
  state.volunteerInfo.studentsAccompagnied;
