//CM1, CM2, 6ème, 5ème, 4ème, 3ème, seconde, première et terminale
export enum ClassSchool {
  CM1 = 'CM1',
  CM2 = 'CM2',
  c6 = '6ème',
  c5 = '5ème',
  c4 = '4ème',
  c3 = '3ème',
  seconde = '2nde',
  premiere = '1ère',
  terminale = 'Terminale',
}

export enum subClass {
  generale = 'Générale',
  stl = 'STL',
  st2s = 'ST2S',
  tmd = 'TMD',
  stav = 'STAV',
  sti2d = 'STI2D',
  std2a = 'STD2A',
  stmg = 'STMG',
  sthr = 'STHR',
}
