import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {IconUserBoy, IconUserGirl, IconUserTeacherMan, IconUserTeacherWoman} from '../assets';
import {profileStudentItems} from '../constants/ProfileStudentItems';
import {profileVolunteerItems} from '../constants/ProfileVolunteerItems';
import {Gender} from '../enums/Gender';
import {Role} from '../enums/Role';
import {ProfileItem} from '../interfaces/ProfileItem';
import {ProfileParams} from '../interfaces/ProfileParms';
import {StudentInformation} from '../interfaces/StudentInformation';
import {VolunteerInformation} from '../interfaces/VolunteerInformation';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {studentInfoActions, studentInformationSelector} from '../store/reducers/studentInfoSlice';
import {userActions} from '../store/reducers/userSlice';
import {volunteerInfoActions, volunteerInfoSelector} from '../store/reducers/volunteerInfoSlice';

export interface ProfileNavProps {
  role: Role;
  closeMenu?: () => void;
}

const renderProfileItem = (
  profileItem: ProfileItem,
  idx: number,
  currentTab: string,
  closeMenu?: () => void,
): JSX.Element => {
  const linkSplitted = profileItem.link.split('/');
  const tabId = linkSplitted[linkSplitted.length - 1];

  return (
    <li key={idx} className={`flex flex-row items-center py-2 ${currentTab === tabId ? '' : 'opacity-50'}`}>
      {currentTab === tabId ? profileItem.iconOn : profileItem.iconOff}
      <Link
        className={`ml-2.75 text-base ${currentTab === tabId ? 'text-mainPurple' : 'text-greyMedium'} font-bold`}
        to={profileItem.link}
        onClick={closeMenu}>
        {profileItem.name}
      </Link>
    </li>
  );
};

const renderUserBlock = (
  role: Role,
  studentInfo: StudentInformation,
  volunteerInfo: VolunteerInformation,
  handleSignOut: () => void,
): JSX.Element => {
  const firstName = role === Role.STUDENT ? studentInfo.studentFirstName : volunteerInfo.firstName;
  const lastName =
    role === Role.STUDENT ? `${studentInfo.studentLastName} - Élève` : `${volunteerInfo.lastName} - Tuteur`;
  let iconUser;

  if (role === Role.STUDENT) {
    iconUser =
      studentInfo.studentGender === Gender.FEMME ? (
        <IconUserGirl className="h-23 icon-student" />
      ) : (
        <IconUserBoy className="h-23 icon-student" />
      );
  } else {
    iconUser =
      volunteerInfo.gender === Gender.FEMME ? (
        <IconUserTeacherWoman className="h-23 icon-teacher" />
      ) : (
        <IconUserTeacherMan className="h-23 icon-teacher" />
      );
  }

  return (
    <div className="hidden md:flex md:flex-row md:items-center">
      <div className="flex items-center w-14">{iconUser}</div>
      <div className="ml-2.5 flex flex-col">
        <p className="text-mainPurple text-2xl" style={{wordBreak: 'break-word'}}>
          {firstName}
          <span className="ml-0.75 font-bold">{lastName}</span>
        </p>
        <Link className="text-base text-greyMedium cursor-pointer" to="#" onClick={handleSignOut}>
          Déconnexion
        </Link>
      </div>
    </div>
  );
};

export const ProfileNav = ({role, closeMenu}: ProfileNavProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {tabId} = useParams<ProfileParams>();
  const studentInfo = useAppSelector(studentInformationSelector);
  const volunteerInfo = useAppSelector(volunteerInfoSelector);

  const handleSignOut = () => {
    localStorage.clear();

    if (role === Role.STUDENT) {
      dispatch(studentInfoActions.resetStudentInfoState());
    } else {
      dispatch(volunteerInfoActions.resetVolunteerInfoState());
    }
    dispatch(userActions.resetUserState());
    window.location.href = 'https://zupdeco.homeclasse.org/';
  };

  return (
    <ul>
      {renderUserBlock(role, studentInfo, volunteerInfo, handleSignOut)}
      {role === Role.STUDENT
        ? profileStudentItems.map((profileItem, idx) => renderProfileItem(profileItem, idx, tabId, closeMenu))
        : profileVolunteerItems.map((profileItem, idx) => renderProfileItem(profileItem, idx, tabId, closeMenu))}
    </ul>
  );
};
