import React, {useEffect, useLayoutEffect} from 'react';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {
  IconCalendarXL,
  IconCheckXL,
  IconCnteRendu,
  IconCopy,
  IconDossierOnXL,
  IconInformationsOnXL,
  IconRessourceXL,
  IconUserBoy,
  IconUserGirl,
  IconUserTeacherMan,
  IconUserTeacherWoman,
} from '../assets';
import {Button} from '../components/Button';
import {Gender} from '../enums/Gender';
import {StudentDocumentType} from '../enums/StudentDocumentType';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {
  studentDocumentsSelector,
  studentInfoActions,
  studentInformationSelector,
  studentStatusSelector,
  studentBinomeStatusSelector,
  studentNextMeetingSelector,
  studentTutorAccompagniedSelector,
  studentReferentSelector,
} from '../store/reducers/studentInfoSlice';
import '../styles/IconUser.scss';
import logoMaxicours from '../assets/img/logos-logo-maxicours.png';
import logoProfExpress from '../assets/img/logos-logo-profexpress.png';
import logoStudyTracks from '../assets/img/logos-logo-studytrack.png';
import {copyToClipboard} from '../utils/utils';
import {
  getUrlMaxicours,
  getUrlProfexpress,
  ssoUrlSelector,
  userActions,
  userSelector,
} from '../store/reducers/userSlice';
import {StudentValidatedStatus} from '../enums/StudentValidatedStatus';
import {MeetingInformation} from '../interfaces/MeetingInformation';
import {formatDateMeeting, dateToString, formatDateMeetingSeance0} from '../utils/dateUtils';
import {TutorAccompagnied} from '../interfaces/api/TutorAccompangied';
import appText from '../assets/intl/fr';

const renderTutorBlock = (tutor?: TutorAccompagnied, studentStatus?: string): JSX.Element => (
  <>
    <div className="flex flex-row items-center">
      {(tutor && tutor.gender) === Gender.FEMME ? (
        <IconUserTeacherWoman className="w-10 h-10 icon-teacher" />
      ) : (
        <IconUserTeacherMan className="w-10 h-10 icon-teacher" />
      )}
      <p className="ml-2.75 text-base text-greyMedium font-bold">
        {(tutor && tutor.gender) === Gender.FEMME ? 'Ma tutrice' : 'Mon tuteur'}
      </p>
    </div>
    <div className="mt-8 flex flex-col h-full items-center justify-center text-center">
      {studentStatus === 'Séance 0 planifiée' ||
      studentStatus === 'Séance 0 à organiser' ||
      studentStatus === 'En attente de tuteur' ? (
        <p className="text-base text-greyMedium font-medium"> Veuillez consulter vos mails ou spams. </p>
      ) : tutor ? (
        <>
          <p className="text-2xl text-greyDark">
            {tutor.firstName}&nbsp; <span className="font-bold">{tutor.lastName}</span>
          </p>
          {tutor.phoneNumber && <p className="text-base text-greyMedium font-medium">{tutor.phoneNumber}</p>}
          <p className="text-base text-greyMedium font-medium">{tutor.email}</p>
        </>
      ) : (
        <p className="text-base text-greyMedium font-medium">
          Un peu de patience, nous allons bientôt t’attribuer un tuteur.
        </p>
      )}
    </div>
  </>
);

const renderCompteRendu = (tutor?: TutorAccompagnied, gender?: Gender): JSX.Element => (
  <>
    <div className="flex flex-row items-center">
      <IconCnteRendu />
      <p className="ml-2.75 text-base text-greyMedium font-bold">Compte rendu</p>
    </div>
    <div className="mt-8 flex flex-col h-full items-center justify-center text-center">
      {!tutor ? (
        <>
          <p className="mb-3.5 text-xl text-greyDark">
            {gender === Gender.HOMME
              ? 'Consulte les comptes rendu de ton tuteur.'
              : 'Consulte les comptes rendu de ta tutrice.'}
          </p>
          <Button
            className="mb-5.75 w-72.5"
            color="mainOrange"
            onClick={() => {
              const win = window.open('https://intranet.zupdeco.org/sessions', '_blank');
              win && win.focus();
            }}>
            Consulter
          </Button>
        </>
      ) : (
        <p className="text-base text-greyMedium font-medium">
          {gender === Gender.HOMME
            ? 'Les comptes rendu s’afficheront ici dès que tu auras un tuteur.'
            : 'Les comptes rendu s’afficheront ici dès que tu auras une tutrice.'}
        </p>
      )}
    </div>
  </>
);

const showNextMeeting = (info?: string) => {
  switch (info) {
    default:
    case 'Nouveau':
    case 'Rentrée 23/24':
    case 'Profil incomplet':
    case 'En attente de tuteur':
    case 'Séance 0 à organiser':
    case 'A matcher / Séance 0':
      return false;
    case 'Séance 0 planifiée':
    case 'SEANCE 0 confirmée':
    case 'Tutorat en cours':
    case 'OK':
    case 'Non équipé':
    case 'Ne donne pas suite':
    case 'Non':
    case 'Arret':
    case 'Arrêt':
    case 'Pause':
    case 'A contacter':
      return true;
  }
};

const renderNextMeetingBlock = (
  nextMeeting?: MeetingInformation,
  tutorGender?: Gender,
  studentStatus?: string,
  studentBinomeStatus?: string,
): JSX.Element => (
  <>
    <div className="flex flex-row items-center">
      <IconCalendarXL />
      <p className="ml-2.75 text-base text-greyMedium font-bold">Ton prochain rendez-vous</p>
    </div>
    <div className="mt-8 flex flex-col h-full items-center justify-center text-center">
      {(showNextMeeting(studentStatus) || showNextMeeting(studentBinomeStatus)) && nextMeeting ? (
        <>
          {nextMeeting.seance0 ? (
            <>
              <p className="mb-3.5 text-xl text-greyDark">{formatDateMeetingSeance0(nextMeeting.seance0)}</p>
            </>
          ) : (
            <p className="mb-3.5 text-xl text-greyDark">
              {formatDateMeeting(nextMeeting.nextTutorStart, nextMeeting.nextTutorEnd)}
            </p>
          )}
          <Button
            className="mb-5.75"
            color="mainOrange"
            onClick={() => {
              const win = window.open(nextMeeting.tutorLink, '_blank');
              win && win.focus();
            }}>
            Je rejoins {tutorGender === Gender.FEMME ? 'ma tutrice' : 'mon tuteur'}
          </Button>
          <div className="flex flex-row items-center">
            <Link to={{pathname: nextMeeting.tutorLink}} target="_blank" className="mr-4 text-xs text-greyMedium">
              {nextMeeting.tutorLink}
            </Link>
            <IconCopy className="cursor-pointer" onClick={() => copyToClipboard(nextMeeting.tutorLink)} />
          </div>
        </>
      ) : studentStatus === 'Séance 0 planifiée' ||
        studentStatus === 'Séance 0 à organiser' ||
        studentStatus === 'En attente de tuteur' ? (
        <p className="text-base text-greyMedium font-medium"> Veuillez consulter vos mails ou spams. </p>
      ) : (
        <p className="text-base text-greyMedium font-medium">
          Tes rendez-vous hebdomadaires s’afficheront ici dès que tu auras un tuteur.
        </p>
      )}
    </div>
  </>
);

export const DashboardStudent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userState = useAppSelector(userSelector);
  const studentInfo = useAppSelector(studentInformationSelector);
  const studentReferent = useAppSelector(studentReferentSelector);
  const studentDocuments = useAppSelector(studentDocumentsSelector);
  const studentStatus = useAppSelector(studentStatusSelector);
  const studentBinomeStatus = useAppSelector(studentBinomeStatusSelector);
  const nextMeeting = useAppSelector(studentNextMeetingSelector);
  const tutorAccompagnied = useAppSelector(studentTutorAccompagniedSelector);

  const isDocumentsCompleted: boolean =
    studentDocuments[StudentDocumentType.GRADE_BOOKS]?.length > 0 &&
    studentDocuments[StudentDocumentType.ELIGIBILITY_PROOF]?.length > 0;
  const ssoUrl = useAppSelector(ssoUrlSelector);

  useLayoutEffect(() => {
    if (ssoUrl) {
      const win = window.open(ssoUrl, '_blank');
      win && win.focus();
      dispatch(userActions.resetUserState());
    }
  }, [dispatch, ssoUrl]);

  const isReferentInfoCompleted: boolean =
    Boolean(studentReferent.referentFirstName) &&
    Boolean(studentReferent.referentLastName) &&
    Boolean(studentReferent.referentStatus) &&
    Boolean(studentReferent.referentEmail) &&
    Boolean(studentReferent.referentPhone);

  const isStudentInfoCompleted: boolean =
    Boolean(studentInfo.studentLastName) &&
    Boolean(studentInfo.studentLastName) &&
    Boolean(studentInfo.studentGender) &&
    Boolean(studentInfo.studentBirthDate) &&
    Boolean(studentInfo.studentClassNb) &&
    Boolean(studentInfo.studentEmail) &&
    Boolean(studentInfo.studentPhone);

  const isInfoCompleted = isReferentInfoCompleted && isStudentInfoCompleted;

  const handleSignOut = () => {
    localStorage.clear();
    dispatch(studentInfoActions.resetStudentInfoState());
    dispatch(userActions.resetUserState());
    window.location.href = 'https://zupdeco.homeclasse.org/';
  };

  const displayTextStatut = (info?: string) => {
    switch (info) {
      case 'Nouveau':
      case 'Rentrée 23/24':
      case 'Profil incomplet':
        return (
          <>
            <span className="text-2xl textcolor_dashboardOZDC">Étape 1 - Votre demande est en cours de traitement</span>
          </>
        );
      case 'En attente de tuteur':
      case 'Séance 0 à organiser':
      case 'A matcher / Séance 0':
        return (
          <>
            <span className="text-2xl textcolor_dashboardOZDC">Étape 2 - Nous recherchons un tuteur</span>
          </>
        );
      case 'Séance 0 planifiée':
        return (
          <>
            <span className="text-2xl textcolor_dashboardVert">Étape 3 - Nous avons un tuteur à vous présenter !</span>
          </>
        );
      case 'Tutorat en cours':
      case 'OK':
        return (
          <>
            <span className="text-2xl textcolor_dashboardVert">Étape 4 - Séances de tutorat en cours</span>
          </>
        );
      case 'Non équipé':
      case 'Ne donne pas suite':
      case 'Non':
        return (
          <>
            <span className="text-2xl textcolor_dashboardRouge">Tutorat annulé</span>
          </>
        );
      case 'Arret':
      case 'Arrêt':
      case 'Pause':
      case 'A contacter':
        return (
          <>
            <span className="text-2xl textcolor_dashboardRouge">Tutorat interrompu</span>
          </>
        );
      default:
        break;
    }
  };

  const isValidated = Object.values(StudentValidatedStatus).some(
    (status) => status.localeCompare(studentStatus || '', 'en', {sensitivity: 'base'}) === 0,
  );
  const handleOpenProfexpress = () => {
    dispatch(getUrlProfexpress());
  };

  const handleOpenMaxicours = () => {
    dispatch(getUrlMaxicours());
  };

  const content = isValidated ? (
    <>
      <div className="flex flex-col md:w-115 bg-clWhite p-8 rounded-8px">
        {renderTutorBlock(tutorAccompagnied, studentStatus)}
      </div>
      <div className="flex flex-col md:w-115 bg-clWhite p-8 mt-5 md:mt-0 md:ml-5 rounded-8px">
        {renderNextMeetingBlock(nextMeeting, tutorAccompagnied?.gender, studentStatus, studentBinomeStatus)}
      </div>
      <div className="flex flex-col md:w-115 bg-clWhite p-8 mt-5 rounded-8px">
        <div className="flex flex-row items-center">
          <IconRessourceXL />
          <p className="ml-2.75 text-base text-greyMedium font-bold">Mes ressources</p>
        </div>
        {userState.errorMsg && <div className="mt-2 text-center text-clStateError">{userState.errorMsg}</div>}
        <div className="flex flex-row h-full items-center justify-center text-center mt-8">
          <div onClick={handleOpenMaxicours} className="mr-2.5 cursor-pointer">
            <img className="w-29 h-29" alt="Logo Maxicours" src={logoMaxicours} />
          </div>
          <div onClick={handleOpenProfexpress} className="mr-2.5 cursor-pointer">
            <img className="w-29 h-29" alt="Logo ProfExpress" src={logoProfExpress} />
          </div>
          <Link
            to={{pathname: 'https://www.studytracks.fr/'}}
            target="_blank"
            className="invisible mr-2.5 cursor-pointer">
            <img className="w-29 h-29" alt="Logo StudyTracks" src={logoStudyTracks} />
          </Link>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="flex flex-col md:w-115 bg-clWhite p-8 rounded-8px">
        <p className="font-bold text-4xl text-greyMedium mb-4">{appText.studentDashboard.welcomeMessage.title}</p>
        <p className="text-xl text-greyMedium mb-1">{appText.studentDashboard.welcomeMessage.sentence1}</p>
        <p className="text-xl text-greyMedium mb-1">{appText.studentDashboard.welcomeMessage.sentence2}</p>
        <p className="text-xl text-greyMedium mb-1">{appText.studentDashboard.welcomeMessage.sentence3}</p>
        {isInfoCompleted && (
          <p className="text-xl text-greyMedium mb-1">
            {appText.studentDashboard.welcomeMessage.sentence4MeetingInformation}
          </p>
        )}
      </div>
      <div className="mt-5 sm:mt-0 md:ml-5 flex flex-col">
        <div className="flex flex-col md:w-115 bg-clWhite p-8 mb-10 rounded-8px">
          <div className="flex flex-row items-center">
            <IconInformationsOnXL></IconInformationsOnXL>
            <span className="ml-2.75 font-bold text-base text-greyMedium">Mes informations</span>
          </div>
          <div className="flex flex-row items-center justify-center mt-8">
            {isInfoCompleted ? (
              <>
                <p className="mr-1 text-base text-greyMedium font-medium">Tes informations sont complètes</p>
                <IconCheckXL />
              </>
            ) : (
              <Button
                color="mainOrange"
                onClick={() =>
                  !isStudentInfoCompleted
                    ? history.push('/profile/student/informations')
                    : history.push('/profile/student/referent')
                }>
                Compléter
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col md:w-115 bg-clWhite p-8 rounded-8px">
          <div className="flex flex-row items-center">
            <IconDossierOnXL></IconDossierOnXL>
            <span className="ml-2.75 font-bold text-base text-greyMedium">Mes documents</span>
          </div>
          <div className="flex flex-row items-center justify-center mt-8">
            {isDocumentsCompleted ? (
              <>
                <p className="mr-1 text-base text-greyMedium font-medium">Tes documents ont été transmis</p>
                <IconCheckXL />
              </>
            ) : (
              <Button color="mainOrange" onClick={() => history.push('/profile/student/documents')}>
                Compléter
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="bg-clBackground w-full overflow-hidden">
        <div className="pt-20 m-4 lg:m-0 flex flex-col items-center">
          <div>
            <div className="flex flex-col mb-16">
              <div className="flex flex-row items-center">
                <div className="flex items-center w-14">
                  {studentInfo.studentGender === Gender.FEMME ? (
                    <IconUserGirl className="h-23 icon-student" />
                  ) : (
                    <IconUserBoy className="h-23 icon-student" />
                  )}
                </div>
                <div className="ml-2.5 flex flex-col">
                  <p className="text-mainPurple text-4xl" style={{wordBreak: 'break-word'}}>
                    {studentInfo.studentFirstName}&nbsp;
                    <span className="ml-0.75 font-bold">{studentInfo.studentLastName} - Élève</span>
                  </p>
                  <Link className="text-base text-greyMedium cursor-pointer" to="#" onClick={handleSignOut}>
                    Déconnexion
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex max-w-235 bg-clWhite p-8 md:ml-5 rounded-8px font-bold text-3xl text-greyMedium mb-4 statut">
              Statut d&apos;inscription:&nbsp; {displayTextStatut(studentStatus)}
            </div>
            <div className="max-w-235 flex flex-col md:flex-row md:flex-wrap sm:m-4">{content}</div>
          </div>
        </div>
      </div>
    </>
  );
};
