import React from 'react';
import {
  IconInformationsOffXL,
  IconInformationsOnXL,
  IconTutoratOffXL,
  IconTutoratOnXL,
  IconDisponibilityOffXL,
  IconDisponibilityOnXL,
  IconDossierOffXL,
  IconDossierOnXL,
} from '../assets';
import appText from '../assets/intl/fr';
import {ProfileItem} from '../interfaces/ProfileItem';

export const profileVolunteerItems: ProfileItem[] = [
  {
    name: 'Mes informations',
    link: '/profile/volunteer/informations',
    iconOff: <IconInformationsOffXL className="h-8.5 w-8.5" />,
    iconOn: <IconInformationsOnXL className="h-8.5 w-8.5" />,
  },
  {
    name: appText.menu.availability,
    link: '/profile/volunteer/availability',
    iconOff: <IconDisponibilityOffXL className="h-8.5 w-8.5" />,
    iconOn: <IconDisponibilityOnXL className="h-8.5 w-8.5" />,
  },
  {
    name: 'Mes documents',
    link: '/profile/volunteer/documents',
    iconOff: <IconDossierOffXL className="h-8.5 w-8.5" />,
    iconOn: <IconDossierOnXL className="h-8.5 w-8.5" />,
  },
];
