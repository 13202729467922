export enum StudentStatus {
  WAITING = 'Rentrée 23/24',
  INCOMPLET = 'Profil incomplet',
  TUTEUR_WAIT = 'En attente de tuteur',
  SEANCE_0_ORG = 'Séance 0 à organiser',
  SEANCE_0_PLAN = 'Séance 0 planifiée',
  A_MATCHER = 'A matcher / Séance 0',
  NON_EQUIPE = 'Non équipé',
  PAS_SUITE = 'Ne donne pas suite',
  Pause = 'Pause',
  ARRET = 'Arrêt',
  OK = 'Tutorat en cours',
}

export enum StudentBinomeStatus {
  S0_PLANIFIER = 'SEANCE 0 à planifier',
  S0_PROPOSEE = 'SEANCE 0 proposée',
  S0_RELANCEE = 'SEANCE 0 relancée',
  S0_CONFIRMEE = 'SEANCE 0 confirmée',
  OK = 'BINÔME OK',
  ARRET = 'ARRÊT',
  PAUSE = 'PAUSE',
  S0_ABANDONNEE = 'SEANCE 0 abandonnée',
}

/*
"Non" -> Dashboard non validé .
"Nouveau" -> Dashboard non validé .
"A matcher / Séance 0" -> Dashboard validé .
"OK" -> Dashboard validé .
"Pause" -> Dashboard en état (évolution). Pour le moment, étant donné que ce statut est une évolution , nous laisserons le Dashboard en non validé .
"Arrêt" -> Dashboard en arrêt (évolution). Pour le moment, étant donné que ce statut est une évolution , nous laisserons le Dashboard en non validé .
"A contacter
*/
