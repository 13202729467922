import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {Button} from '../Button';
import {RadioButton} from '../RadioButton';
import {Link} from 'react-router-dom';
import '../../styles/Available.scss';
import {AvailableDays, AvailableHours} from '../../enums/AvailableDay';
import {OptionYesNo} from '../../enums/OptionYesNo';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  updateVolunteerInfo,
  volunteerAvailabilitySelector,
  volunteerErrorSelector,
  volunteerIdSelector,
  volunteerInfoActions,
  volunteerInfoSelector,
  volunteerPendingSelector,
  volunteerResponsabilitySelector,
  volunteerSuccessSelector,
} from '../../store/reducers/volunteerInfoSlice';
import {
  studentAvailableSelector,
  studentErrorSelector,
  studentIdSelector,
  studentInfoActions,
  studentPendingSelector,
  studentSuccessSelector,
  updateStudentInfo,
} from '../../store/reducers/studentInfoSlice';
import {Available} from '../Available';
import {Availability} from '../../interfaces/Availability';
import {VolunteerAvailability} from '../../interfaces/VoluntererAvailability';
import {StudentAvailabilty} from '../../interfaces/StudentAvailability';
import {Spinner} from '../Spinner';
import appText from '../../assets/intl/fr';
import {Homelink} from '../Homelink';
import {useMediaQuery} from '../../hooks/Hook';
import ReactModal from 'react-modal';
import {userActions} from '../../store/reducers/userSlice';
import {Role} from '../../enums/Role';
import {VolunteerResponsibility} from '../../interfaces/VolunteerResponsibility';
import {SelectInput} from '../SelectInput';
import {Level} from '../../enums/Level';
import {VolunteerInformation} from '../../interfaces/VolunteerInformation';
import {AvailableCalendar} from '../AvailableCalendar';
import {AvailabilityCalendar} from '../../interfaces/AvailabilityCalendar';
import {Checkbox} from '../Checkbox';

export interface AvailableStepProps {
  isStudent: boolean;
  isRegistrationForm?: boolean;
  goToNextStep?: () => void;
  goToPreviousStep?: () => void;
  access_token?: string | null;
}

const renderTitle = (isStudent: boolean, isRegistrationForm?: boolean): JSX.Element => {
  if (isRegistrationForm) {
    return (
      <>
        <h2 className="text-2xl text-greyMedium font-bold">{appText.profile.availability.title}</h2>
        {isStudent ? (
          <>
            <p className="text-greyMedium text-base mb-1">{appText.profile.availability.student.description2}</p>
          </>
        ) : (
          <p className="text-base text-greyMedium">{appText.profile.availability.volunteer.description}</p>
        )}
      </>
    );
  } else {
    return (
      <>
        {isStudent ? (
          <>
            <h2 className="mb-2 lg:mb-4.5 text-3xl text-greyMedium font-bold">Mes disponibilités</h2>
            <p className="text-greyMedium text-base mb-1">{appText.profile.availability.student.description2}</p>
          </>
        ) : (
          <>
            <h2 className="mb-2 lg:mb-4.5 text-3xl text-greyMedium font-bold">Mes disponibilités</h2>
          </>
        )}
      </>
    );
  }
};

export const AvailableStep = ({
  isStudent,
  isRegistrationForm,
  goToNextStep,
  goToPreviousStep,
  access_token,
}: AvailableStepProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const volunteerAvailability = useAppSelector(volunteerAvailabilitySelector);
  const studentAvailability = useAppSelector(studentAvailableSelector);

  const studentId: string = useAppSelector(studentIdSelector);
  const teacherId: string = useAppSelector(volunteerIdSelector);
  const apiName = isStudent ? 'updateStudentInfo' : 'updateVolunteerInfo';
  const errorMsg: string = useAppSelector(isStudent ? studentErrorSelector : volunteerErrorSelector)[apiName] || '';
  const isLoading: boolean =
    useAppSelector(isStudent ? studentPendingSelector : volunteerPendingSelector)[apiName] || false;
  const isSuccess: boolean =
    useAppSelector(isStudent ? studentSuccessSelector : volunteerSuccessSelector)[apiName] || false;

  useEffect(() => {
    if (isRegistrationForm && isSuccess && goToNextStep) {
      goToNextStep();
    }
  }, [dispatch, isRegistrationForm, isSuccess, goToNextStep]);

  useEffect(() => {
    if (isStudent) {
      dispatch(studentInfoActions.resetRequestApiState());
    } else {
      dispatch(volunteerInfoActions.resetRequestApiState());
    }
  }, [dispatch, isStudent]);

  const role = localStorage.getItem('role');

  const [close, setclose] = useState(false);
  const [error, seterror] = useState('');
  const [errmsg, seterrormsg] = useState('');

  const handleLogout = () => {
    localStorage.clear();
    if (role === Role.STUDENT) dispatch(studentInfoActions.resetStudentInfoState());
    else dispatch(volunteerInfoActions.resetVolunteerInfoState());
    dispatch(userActions.resetUserState());
    if (role === Role.STUDENT) window.location.href = '/registration/student/form';
    else window.location.href = '/registration/volunteer/form';
  };

  const updateLevelStudent = () => {
    const newlevel: Level[] = [];
    volunteerResponsability.levelStudent.forEach((elem: Level) => {
      if (Object.values(Level).includes(elem)) {
        newlevel.push(elem);
      }
    });
    return newlevel;
  };

  const sendData = () => {
    seterrormsg('');
    if (canContinue() && error === '') {
      if (isStudent) {
        dispatch(
          updateStudentInfo({
            id: studentId,
            request: {
              availabilitiesCalendar: studentAvailability.availabilitiesCalendar,
            },
          }),
        );
      } else {
        const acceptManyStudent =
          volunteerResponsability.nbStudentAccompanied === 3
            ? '3'
            : volunteerResponsability.nbStudentAccompanied === 2
            ? '2'
            : '1';

        dispatch(
          updateVolunteerInfo({
            id: teacherId,
            request: {
              studentLevel: updateLevelStudent(),
              availabilitiesCalendar: volunteerAvailability.availabilitiesCalendar,
              acceptManyStudent,
            },
          }),
        );
      }
    } else if (error != '') {
      seterrormsg('Plusieurs créneaux se chevauchent, veuillez mettre à jour vos choix');
      seterror('');
    }
    setclose(false);
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    sendData();
  };

  const handleAddAvailable = (): void => {
    if (isStudent) handleAddStudentAvailable();
    else handleAddVolunteerAvailable();
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const handleAddVolunteerAvailable = (): void => {
    const availabilitiesCalendar = [...cloneDeep(volunteerAvailability.availabilitiesCalendar)];

    const newVolunteerAvailability = {
      availabilitiesCalendar,
      availableSchoolYear: volunteerAvailability.availableSchoolYear,
    };
    dispatch(volunteerInfoActions.saveVolunteerAvailability(newVolunteerAvailability));
  };

  const handleAddStudentAvailable = (): void => {
    const availabilitiesCalendar: AvailabilityCalendar[] = [
      {
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
      },
    ];
    const newAvailability: StudentAvailabilty = {
      hasComputerHardware: studentAvailability.hasComputerHardware,
      availabilitiesCalendar,
    };
    dispatch(studentInfoActions.saveStudentAvailability(newAvailability));
  };

  const handleDeleteAvailable = (index: number, studentnumber: number): void => {
    if (isStudent) handleDeleteStudentAvailable(index);
    else handleDeleteVolunteerAvailable(index, studentnumber);
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const handleDeleteVolunteerAvailable = (index: number, studentnumber: number): void => {
    const availabilitiesCalendar: AvailabilityCalendar[] = [
      {
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
      },
    ];
    const newVolunteerAvailability: VolunteerAvailability = {
      availabilitiesCalendar,
      availableSchoolYear: volunteerAvailability.availableSchoolYear,
    };
    dispatch(volunteerInfoActions.saveVolunteerAvailability(newVolunteerAvailability));
  };

  const handleDeleteStudentAvailable = (index: number): void => {
    const availabilitiesCalendar: AvailabilityCalendar[] = [
      {
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
      },
    ];
    const newAvailability: StudentAvailabilty = {
      availabilitiesCalendar,
      hasComputerHardware: studentAvailability.hasComputerHardware,
    };
    dispatch(studentInfoActions.saveStudentAvailability(newAvailability));
  };

  const handleAvailableDayChange = (index: number, value: number): void => {
    if (isStudent) handleStudentAvailableDayChange(index + 1, value);
    else handleVolunteerAvailableDayChange(index + 1, value);
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const handleVolunteerAvailableDayChange = (index: number, value: number): void => {
    const newVolunteerAvailability: VolunteerAvailability = cloneDeep(volunteerAvailability);
    const availabilitiesCalendar = newVolunteerAvailability.availabilitiesCalendar;
    const availablecal: AvailabilityCalendar = availabilitiesCalendar[index];
    switch (value) {
      case 1:
        availablecal.three ? (availablecal.three = false) : (availablecal.three = true);
        break;
      case 2:
        availablecal.four ? (availablecal.four = false) : (availablecal.four = true);
        break;
      case 3:
        availablecal.five ? (availablecal.five = false) : (availablecal.five = true);
        break;
      case 4:
        availablecal.six ? (availablecal.six = false) : (availablecal.six = true);
        break;
      case 5:
        availablecal.seven ? (availablecal.seven = false) : (availablecal.seven = true);
        break;
      default:
        break;
    }
    availabilitiesCalendar[index] = availablecal;
    newVolunteerAvailability.availabilitiesCalendar = availabilitiesCalendar;

    dispatch(volunteerInfoActions.saveVolunteerAvailability(newVolunteerAvailability));
  };

  const handleStudentAvailableDayChange = (index: number, value: number): void => {
    const newAvailability: StudentAvailabilty = cloneDeep(studentAvailability);
    const availabilitiesCalendar = newAvailability.availabilitiesCalendar;

    const availablecal: AvailabilityCalendar = availabilitiesCalendar[index];
    switch (value) {
      case 1:
        availablecal.three ? (availablecal.three = false) : (availablecal.three = true);
        break;
      case 2:
        availablecal.four ? (availablecal.four = false) : (availablecal.four = true);
        break;
      case 3:
        availablecal.five ? (availablecal.five = false) : (availablecal.five = true);
        break;
      case 4:
        availablecal.six ? (availablecal.six = false) : (availablecal.six = true);
        break;
      case 5:
        availablecal.seven ? (availablecal.seven = false) : (availablecal.seven = true);
        break;
      default:
        break;
    }
    availabilitiesCalendar[index] = availablecal;
    newAvailability.availabilitiesCalendar = availabilitiesCalendar;
    dispatch(studentInfoActions.saveStudentAvailability(newAvailability));
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isStudent) {
      dispatch(
        studentInfoActions.saveStudentAvailability({
          ...studentAvailability,
          hasComputerHardware: event.target.value as OptionYesNo,
        }),
      );
    } else {
      dispatch(
        volunteerInfoActions.saveVolunteerAvailability({
          ...volunteerAvailability,
          availableSchoolYear: event.target.value as OptionYesNo,
        }),
      );
    }
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const isabove500 = useMediaQuery('(max-width: 500px)');

  const changehours = (to?: string, from?: string) => {
    let dateStart;
    let dateEnd;
    if (from) {
      const [h, m] = from.split(':');

      if (!isNaN(+h) && !isNaN(+m)) {
        dateStart = parseInt(h) + parseInt(m) / 100;
      }
    }

    if (to) {
      const [h, m] = to.split(':');

      if (!isNaN(+h) && !isNaN(+m)) {
        dateEnd = parseInt(h) + parseInt(m) / 100;
      }
    }
    return [dateStart, dateEnd];
  };

  const selectavailability = (): number => {
    let pass = 0;
    if (isStudent) {
      studentAvailability.availabilitiesCalendar.forEach((elem: AvailabilityCalendar) => {
        if (elem.three) pass++;
        if (elem.four) pass++;
        if (elem.five) pass++;
        if (elem.six) pass++;
        if (elem.seven) pass++;
      });
    } else {
      volunteerAvailability.availabilitiesCalendar.forEach((elem: AvailabilityCalendar) => {
        if (elem.three) pass++;
        if (elem.four) pass++;
        if (elem.five) pass++;
        if (elem.six) pass++;
        if (elem.seven) pass++;
      });
    }
    return pass;
  };

  const canContinue = (): boolean => {
    if (isStudent) {
      if (selectavailability() == 0) {
        seterrormsg('Veuillez remplir le(s) jour(s) de disponibilité');
        return false;
      }
      return Boolean(studentAvailability.hasComputerHardware);
    }
    if (volunteerResponsability.levelStudent.length === 0 || volunteerResponsability.levelStudent[0].length === 0) {
      seterrormsg('Veuillez remplir le niveau des élèves');
      return false;
    }
    if (!selectavailability()) {
      seterrormsg('Veuillez remplir le(s) jour(s) de disponibilité');
      return false;
    }
    const nbrstudent = volunteerResponsability.nbStudentAccompanied ? volunteerResponsability.nbStudentAccompanied : 1;
    if (selectavailability() < nbrstudent) {
      seterrormsg('Veuillez remplir au moins un jour de disponibilité pour chaque élève');
      return false;
    }
    return true;
  };

  const availabilitiesCalendar = isStudent
    ? studentAvailability.availabilitiesCalendar
    : volunteerAvailability.availabilitiesCalendar;
  const btnColor = !isRegistrationForm && isSuccess ? 'clValidate' : 'mainOrange';
  const btnTitle = isSuccess ? 'Enregistré !' : 'Enregistrer';
  const isabove1024 = useMediaQuery('(max-width: 1024px)');
  const labelClass = 'text-greyMedium font-bold text-base mb-1';
  const volunteerResponsability = useAppSelector(volunteerResponsabilitySelector);
  const volunteerInformation = useAppSelector(volunteerInfoSelector);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newVolunteerResponsability: VolunteerResponsibility = {
      ...volunteerResponsability,
      [event.target.name]: value,
    };
    if (event.target.name === 'nbStudentAccompanied') {
      newVolunteerResponsability.nbStudentAccompanied = Number(value);
    }
    dispatch(volunteerInfoActions.saveVolunteerResponsibility(newVolunteerResponsability));
  };

  const handleCheckBoxChange = (levelStudentName: string, level: Level) => {
    const newVolunteerResponsability: VolunteerResponsibility = cloneDeep(volunteerResponsability);
    if (newVolunteerResponsability.levelStudent.includes(level)) {
      newVolunteerResponsability.levelStudent = newVolunteerResponsability.levelStudent.filter(
        (item) => item !== level,
      );
    } else {
      newVolunteerResponsability.levelStudent.push(level);
    }
    dispatch(volunteerInfoActions.saveVolunteerResponsibility(newVolunteerResponsability));
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const level = event.target.value as Level;
    const newVolunteerResponsability: VolunteerResponsibility = cloneDeep(volunteerResponsability);
    newVolunteerResponsability.levelStudent = [];
    newVolunteerResponsability.levelStudent.push(level);
    dispatch(volunteerInfoActions.saveVolunteerResponsibility(newVolunteerResponsability));
  };
  return (
    <form className="flex-1 h-full w-85.75 lg:w-131 mx-auto flex flex-col items-center" onSubmit={handleSubmit}>
      <div className="w-full p-8 mb-21.75 bg-clWhite rounded-8px shadow-formRectangle">
        {renderTitle(isStudent, isRegistrationForm)}
        {isStudent ? (
          <></>
        ) : (
          <>
            <p className="text-greyMedium font-bold text-base mb-1 mt-2 tutoratspace">
              {appText.profile.availability.student.disponibiliteSubTitle}
            </p>
            <div className="flex flex-col mt-6 tutorat">
              <div className="flex flex-row" onChange={handleChange}>
                <RadioButton
                  value="1"
                  className="mr-4"
                  name="nbStudentAccompanied"
                  checked={volunteerResponsability.nbStudentAccompanied === 1}
                  required
                />
                <RadioButton
                  value="2"
                  className="mr-4"
                  name="nbStudentAccompanied"
                  checked={volunteerResponsability.nbStudentAccompanied === 2}
                  required
                />
                <RadioButton
                  value="3"
                  className="mr-4"
                  name="nbStudentAccompanied"
                  checked={volunteerResponsability.nbStudentAccompanied === 3}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col mt-6">
              <span className="text-greyMedium font-bold text-base mb-1">Niveau des élèves</span>
              <div className="flex flex-col">
                {Object.values(Level).map((level, key) => (
                  <Checkbox
                    value={level}
                    key={key}
                    className="mt-2"
                    name="levelStudent"
                    onCheckBoxClick={(value: string) => handleCheckBoxChange('levelStudent', value as Level)}
                    checked={volunteerResponsability.levelStudent?.includes(level)}
                    required
                  />
                ))}
              </div>
            </div>
            <br />
          </>
        )}
        <p className="mt-4 mb-6 text-greyMedium subquest font-bold text-base mb-1">
          {appText.profile.availability.student.disponibiliteQuestion}
        </p>
        <div className="flex align-items-center">
          <div className="column-disponibilite w-full text-align-center items-center">
            {Object.values(AvailableHours).map((hour, id) => {
              return (
                <div className="Rectangle-Calendar-Horaire text-greyMedium font-bold text-base" key={id}>
                  {hour}
                </div>
              );
            })}
          </div>
          {Object.values(AvailableDays).map((day, id) => {
            if (isStudent) {
              return (
                <AvailableCalendar
                  key={id}
                  title={day}
                  availabilities={studentAvailability.availabilitiesCalendar[id + 1]}
                  onAvailableDayChange={(value) => handleAvailableDayChange(id, value)}></AvailableCalendar>
              );
            } else {
              return (
                <>
                  <AvailableCalendar
                    key={id}
                    title={day}
                    availabilities={volunteerAvailability.availabilitiesCalendar[id + 1]}
                    onAvailableDayChange={(value) => handleAvailableDayChange(id, value)}></AvailableCalendar>
                </>
              );
            }
          })}
        </div>
      </div>
      {errorMsg && <div className="mb-4 text-clStateError">{errorMsg}</div>}
      {errmsg && <div className="mb-4 text-clStateError">{errmsg}</div>}
      <div className="flex justify-around" style={{width: '100%'}}>
        {isRegistrationForm && !isLoading ? (
          <>
            <Button
              className="w-85.75 lg:w-64 mt-auto mr-1 py-3 text-xl text-clWhite text-center font-bold rounded-8px disabled:bg-greyMedium disabled:opacity-16 bg-darkcolor"
              onClick={goToPreviousStep}
              color={btnColor}
              isRegistrationForm>
              Précédent
            </Button>
            <Button className="w-85.75 lg:w-64 mt-auto ml-1" color={btnColor} type="submit" isRegistrationForm>
              {btnTitle}
            </Button>
          </>
        ) : (
          !isLoading && (
            <>
              <Button
                className={isRegistrationForm && isabove500 ? 'w-27.5 lg:w-50 mt-auto' : 'w-85.75 lg:w-115 mt-auto'}
                color={btnColor}
                type="submit"
                isRegistrationForm>
                {btnTitle}
              </Button>
            </>
          )
        )}
      </div>
      {!isRegistrationForm && <Homelink />}
      {isLoading && <Spinner className="mt-auto" />}
      <ReactModal
        className="absolute w-85.75 md:w-auto top-40 inset-x-center-modal-mobile md:inset-x-1/3 p-8 bg-clWhite rounded-8px border-none overflow-auto outline-none"
        overlayClassName="fixed inset-0 bg-greyDark bg-opacity-50 overflow-auto z-50"
        isOpen={close}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}>
        <div>
          <div className="flex flex-col text-greyMedium" style={{alignItems: 'center'}}>
            <h3 className="my-2 text-clStateError" style={{fontSize: 'revert'}}>
              Attention
            </h3>
            <h4 className="mt-2 mb-4 text-greyMedium" style={{fontSize: 'revert'}}>
              vous êtes déjà connecté si vous souhaitez créer un nouveau compte veuillez sélectionner déconnexion si
              vous souhaitez mettre à jour vos informations veuillez sélectionner continuer
            </h4>
            <div className="flex justify-around relative" style={{width: '100%'}}>
              <button
                className="close bg-mainOrange rounded w-27.5"
                style={{padding: '0.7rem 0', color: 'white'}}
                onClick={sendData}>
                Continuer
              </button>
              <button
                className="close bg-mainOrange rounded w-27.5"
                style={{padding: '0.7rem 0', color: 'white'}}
                onClick={handleLogout}>
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </form>
  );
};
