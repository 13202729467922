import React from 'react';
import {useHistory} from 'react-router-dom';
import {IconRessourceXL} from '../assets';
import {Button} from '../components/Button';
import {GeneralPage} from './GeneralPage';
import imgInscription from '../assets/img/img_inscription.png';
import {MeetingType} from '../enums/MeetingType';

export const VolunteerRegistrationPage = (): JSX.Element => {
  const history = useHistory();

  return (
    <GeneralPage bgSrc={imgInscription}>
      <div className="absolute left-17% top-18.5">
        <div className="mb-8">
          <h1 className="mt-1.5 mb-4 mr-2 text-3xl text-mainPurple font-bold">
            JE SUIS BÉNÉVOLE, JE VEUX
            <br /> ACCOMPAGNER UN ÉLÈVE
          </h1>
          <h2 className="mb-4 text-2xl text-greyMedium font-bold">
            En tant devenant tuteur bénévole, vous aidez un
            <br /> enfant en difficulté à réussir à l&apos;école pendant 1<br /> heure hebdomadaire sur une durée de 6
            mois
            <br /> minimum. Pour en savoir plus vous pouvez
            <br /> participer à une réunion d’information avant de
            <br /> vous engager.
          </h2>
        </div>
        <div className="flex mb-8">
          <Button className="mr-4" color="mainOrange" onClick={() => history.push('/registration/volunteer/form')}>
            Je m&apos;inscris
          </Button>
          <Button color="mainPurple" onClick={() => history.push(`/meeting/volunteer/${MeetingType.INFORMATION}`)}>
            Réunion d’information
          </Button>
        </div>
        <button
          className="flex items-center h-18 w-56.5 p-4.75 bg-clWhite border-solid border-1 border-greyLight rounded-8px"
          type="button"
          onClick={() => {
            return;
          }}>
          <IconRessourceXL /> <span className="ml-2.75 text-base text-greyMedium font-bold">Documentation</span>
        </button>
      </div>
    </GeneralPage>
  );
};
