import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {LoginRequest} from '../../interfaces/api/LoginRequest';
import {LoginResponse} from '../../interfaces/api/LoginResponse';
import {RootState} from '../store';
import {history} from '../../App';
import {Role} from '../../enums/Role';
import {ResetPasswordRequest} from '../../interfaces/api/ResetPasswordRequest';
import {ForgotPasswordRequest} from '../../interfaces/api/ForgotPasswordRequest';
import ApiService from '../../services/ApiService';
import {UploadFileResponse} from '../../interfaces/api/UploadFileResponse';
import {SsoUrlResponse} from '../../interfaces/api/SsoUrlResponse';
import {StudentAssigned} from '../../interfaces/api/StudentAssigned';

interface UserSliceState {
  isPending: boolean;
  isSuccess: boolean;
  errorMsg: string;
  ssoUrl: string;
}

const initialState: UserSliceState = {
  isPending: false,
  isSuccess: false,
  errorMsg: '',
  ssoUrl: '',
};

export const login = createAsyncThunk<LoginResponse, LoginRequest>(
  'user/login',
  async (loginData: LoginRequest, thunkAPI) => {
    try {
      const response: LoginResponse = await ApiService.login(loginData);
      localStorage.setItem('access_token', response.access_token);
      localStorage.setItem('role', response.user.roles[0]);
      if (response.user.roles[0] === Role.STUDENT) {
        history.push('/dashboard/student');
      } else {
        history.push('/dashboard/volunteer');
      }
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue('Login ou password invalid');
    }
  },
);

export const resetPassword = createAsyncThunk<void, ResetPasswordRequest>(
  'user/resetPassword',
  async (resetPasswordRequest: ResetPasswordRequest, thunkAPI) => {
    try {
      await ApiService.resetPassword(resetPasswordRequest);
      return;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        (e as {message: string}).message || 'Une erreur est survenue. Merci de réessayer ou contacter le support.',
      );
    }
  },
);

export const forgotPassword = createAsyncThunk<void, ForgotPasswordRequest>(
  'user/forgotPassword',
  async (forgotPasswordRequest: ForgotPasswordRequest, thunkAPI) => {
    try {
      await ApiService.forgotPassword(forgotPasswordRequest);
      return;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        (e as {message: string}).message || 'Une erreur est survenue. Merci de réessayer ou contacter le support.',
      );
    }
  },
);

interface ContactThunkRequest {
  files: FormData[];
  title: string;
  message: string;
}

export const contactHomeClass = createAsyncThunk<void, ContactThunkRequest>(
  'contact',
  async (contactThunkRequest: ContactThunkRequest, thunkAPI) => {
    try {
      const updateFilesResponses: UploadFileResponse[] = await Promise.all(
        contactThunkRequest.files.map((formData) => ApiService.uploadFile(formData)),
      );

      await ApiService.contact({
        title: contactThunkRequest.title,
        message: contactThunkRequest.message,
        attachments:
          updateFilesResponses?.map((file) => {
            return {
              url: file.url,
            };
          }) || [],
      });
      return;
    } catch (e) {
      thunkAPI.rejectWithValue((e as {message: string}).message || 'Une erreur est survenue');
    }
  },
);

interface CompteRenduThunkRequest {
  files: FormData[];
  date: string;
  student: string;
  implicationEleve: string;
  noteDeLaSeance: string;
  message: string;
}
export const compteRenduForm = createAsyncThunk<void, CompteRenduThunkRequest>(
  'compteRendu',
  async (compteRenduThunkRequest: CompteRenduThunkRequest, thunkAPI) => {
    try {
      const updateFilesResponses: UploadFileResponse[] = await Promise.all(
        compteRenduThunkRequest.files.map((formData) => ApiService.uploadFile(formData)),
      );
      await ApiService.compteRendu({
        message: compteRenduThunkRequest.message,
        date: compteRenduThunkRequest.date,
        student: compteRenduThunkRequest.student,
        implicationEleve: compteRenduThunkRequest.implicationEleve,
        noteDeLaSeance: compteRenduThunkRequest.noteDeLaSeance,
        attachments:
          updateFilesResponses?.map((file) => {
            return {
              url: file.url,
            };
          }) || [],
      });
      return;
    } catch (e) {
      return thunkAPI.rejectWithValue((e as {message: string}).message || 'Une erreur est survenue');
    }
  },
);

export const getUrlProfexpress = createAsyncThunk<SsoUrlResponse>('student/getUrlProfexpress', async (_, thunkAPI) => {
  try {
    const response: SsoUrlResponse = await ApiService.getUrlProfexpress();
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(
      'Une erreur de connexion avec Profexpress est survenue. Merci de contacter le support.',
    );
  }
});

export const getUrlMaxicours = createAsyncThunk<SsoUrlResponse>('student/getUrlMaxicours', async (_, thunkAPI) => {
  try {
    const response: SsoUrlResponse = await ApiService.getUrlMaxicours();
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(
      'Une erreur de connexion avec Maxicours est survenue. Merci de contacter le support.',
    );
  }
});

export const getUrlMaxicoursMascarade = createAsyncThunk(
  'volunteer/getUrlMaxicours',
  async (studentAssignedRequest: StudentAssigned, thunkAPI) => {
    try {
      const response: SsoUrlResponse = await ApiService.getUrlMaxicoursMascarade(studentAssignedRequest);
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue((e as {message: string}).message || 'Une erreur est survenu');
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.errorMsg = '';
      state.isPending = false;
      state.isSuccess = true;
    });
    builder.addCase(login.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isPending = false;
      state.errorMsg = action.payload as string;
    });

    //contactHomeClass
    builder.addCase(contactHomeClass.fulfilled, (state, action) => {
      state.errorMsg = '';
      state.isPending = false;
      state.isSuccess = true;
    });
    builder.addCase(contactHomeClass.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(contactHomeClass.rejected, (state, action) => {
      state.isPending = false;
      state.errorMsg = action.payload as string;
    });

    //compteRenduForm
    builder.addCase(compteRenduForm.fulfilled, (state, action) => {
      state.errorMsg = '';
      state.isPending = false;
      state.isSuccess = true;
    });
    builder.addCase(compteRenduForm.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(compteRenduForm.rejected, (state, action) => {
      state.isPending = false;
      state.errorMsg = action.payload as string;
    });

    // rejet password
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.errorMsg = '';
      state.isPending = false;
      state.isSuccess = true;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.errorMsg = action.payload as string;
    });
    // forgot password
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.errorMsg = '';
      state.isPending = false;
      state.isSuccess = true;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.errorMsg = action.payload as string;
    });
    // get sso url
    builder.addCase(getUrlProfexpress.fulfilled, (state, action) => {
      state.errorMsg = '';
      state.isPending = false;
      state.ssoUrl = action.payload.url;
    });
    builder.addCase(getUrlProfexpress.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getUrlProfexpress.rejected, (state, action) => {
      state.errorMsg = action.payload as string;
    });
    builder.addCase(getUrlMaxicours.fulfilled, (state, action) => {
      state.errorMsg = '';
      state.isPending = false;
      state.ssoUrl = action.payload.url;
    });
    builder.addCase(getUrlMaxicours.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getUrlMaxicours.rejected, (state, action) => {
      state.errorMsg = action.payload as string;
    });
    builder.addCase(getUrlMaxicoursMascarade.fulfilled, (state, action) => {
      state.errorMsg = '';
      state.isPending = false;
      state.ssoUrl = action.payload?.url as string;
    });
    builder.addCase(getUrlMaxicoursMascarade.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getUrlMaxicoursMascarade.rejected, (state, action) => {
      state.errorMsg = action.payload as string;
    });
  },
});

export const userActions = userSlice.actions;

export const userSelector = (state: RootState): UserSliceState => state.user;

export const ssoUrlSelector = (state: RootState): string => state.user.ssoUrl;
