import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {ClassSchool, subClass} from '../../enums/ClassSchool';
import {Gender} from '../../enums/Gender';
import {EligibilityCriteria} from '../../interfaces/EligibilityCriteria';
import {StudentInformation} from '../../interfaces/StudentInformation';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  studentInfoActions,
  studentInformationSelector,
  createStudent,
  eligibilityCriteriaSelector,
  studentErrorSelector,
  studentPendingSelector,
  updateStudentInfo,
  studentIdSelector,
  studentSuccessSelector,
  getStudentProfile,
  studentReferentSelector,
  schoolInfoSelector,
} from '../../store/reducers/studentInfoSlice';
import {Button} from '../Button';
import {TextInput} from '../TextInput';
import {RadioButton} from '../RadioButton';
import {SelectInput} from '../SelectInput';
import {ToggleSwitch} from '../ToggleSwitch';
import {OptionYesNo} from '../../enums/OptionYesNo';
import {Spinner} from '../Spinner';
import {StudentStatus} from '../../enums/StudentStatus';
import {
  isPhoneNumber,
  isEmail,
  isDateFormatValid,
  getErrorBirth,
  isEmailEmpty,
  isPhoneNumberEmpty,
} from '../../utils/utils';
import {Link} from 'react-router-dom';
import {EmailInput} from '../EmailInput';
import {PhoneInput} from '../PhoneInput';
import appText from '../../assets/intl/fr';
import {format, parse, sub} from 'date-fns';
import fr from 'date-fns/locale/fr';
import {isDate} from 'lodash';
import {Homelink} from '../Homelink';
import {useMediaQuery} from '../../hooks/Hook';
import ReactModal from 'react-modal';
import {userActions} from '../../store/reducers/userSlice';
import {ZipCodeInput} from '../ZipCodeInput';
import {StudentSchoolInfo} from '../../interfaces/StudentSchoolInfo';
import axios from 'axios';
import {StudentReferent} from '../../interfaces/StudentReferent';
import {MediaStudent} from '../../enums/Media';
import {Checkbox} from '../Checkbox';
import {SchoolInfoInterface} from '../../interfaces/SchoolInfoInterface';

const maxBirthdate = format(sub(Date.now(), {years: 7}), 'yyyy-MM-dd', {
  locale: fr,
});

const minBirthdate = format(sub(Date.now(), {years: 22}), 'yyyy-MM-dd', {
  locale: fr,
});

export interface StudentInformationFormProps {
  isRegistrationForm?: boolean;
  goToNextStep?: () => void;
  goToPreviousStep?: () => void;
  access_token?: string | null;
}

const renderTitle = (isRegistrationForm?: boolean): JSX.Element => {
  if (isRegistrationForm) {
    return (
      <>
        <h2 className="mb-2 text-2xl text-greyMedium font-bold">{appText.registrationStudent.informations.title}</h2>
      </>
    );
  } else {
    return (
      <>
        <h2 className="mb-2 text-3xl text-mainPurple font-bold">MES INFORMATIONS</h2>
      </>
    );
  }
};

export const StudentInformationForm = ({
  isRegistrationForm,
  goToNextStep,
  goToPreviousStep,
  access_token,
}: StudentInformationFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const studentId: string = useAppSelector(studentIdSelector);
  const studentInformation: StudentInformation = useAppSelector(studentInformationSelector);
  const referent = useAppSelector(studentReferentSelector);
  const schoolInfo: StudentSchoolInfo = useAppSelector(schoolInfoSelector);
  const studentEligibility: EligibilityCriteria = useAppSelector(eligibilityCriteriaSelector);
  const apiName = isRegistrationForm && !access_token ? 'createStudent' : 'updateStudentInfo';
  const errorMsg: string = useAppSelector(studentErrorSelector)[apiName] || '';
  const isLoading: boolean = useAppSelector(studentPendingSelector)[apiName] || false;
  const isSuccess: boolean = useAppSelector(studentSuccessSelector)[apiName] || false;
  const [acceptCGU, setAcceptCGU] = useState<boolean>(true);
  const [errorApeare, seterrorApeare] = useState<boolean>(false);
  const [studentAcceptOffer, setstudentAcceptOffer] = useState<boolean>(true);
  const [err, seterr] = useState<boolean>(true);
  const [referr, setreferr] = useState<boolean>(true);
  const [errPhone, seterrPhone] = useState<boolean>(true);
  const [tosub, settosub] = useState<boolean>(false);
  const [clickcity, setclickcity] = useState<boolean>(false);
  const [etablissement, setetab] = useState<SchoolInfoInterface[]>();
  const [schoolName, setschool] = useState<string>();
  const [cities, setcities] = useState<string>();
  const [zip, setzip] = useState<string>();
  const [schoolInfoChars, setSchoolInfoChars] = useState<boolean>(false);

  useEffect(() => {
    dispatch(studentInfoActions.resetRequestApiState());
  }, [dispatch]);

  useEffect(() => {
    if (isRegistrationForm && isSuccess && goToNextStep) {
      dispatch(studentInfoActions.resetRequestApiState());
      goToNextStep();
    }
  }, [dispatch, isRegistrationForm, isSuccess, goToNextStep]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    seterrPhone(true);
    const newStudentInformation: StudentInformation = {
      ...studentInformation,
      [event.target.name]: event.target.value,
    };
    dispatch(studentInfoActions.saveStudentInformation(newStudentInformation));
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const [otherStatus, setOtherStatus] = useState('');
  const handleChangeStudentReferent = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name !== 'otherStatus') {
      const newReferent: StudentReferent = {
        ...referent,
        [event.target.name]: event.target.value,
      };

      dispatch(studentInfoActions.saveStudentReferent(newReferent));
    } else {
      setOtherStatus(event.target.value);
    }
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const handleSelectChangeSchoolInfo = (event: ChangeEvent<HTMLSelectElement>) => {
    const newSchoolInfo: StudentSchoolInfo = {
      ...schoolInfo,
      [event.target.name]: event.target.value,
    };

    dispatch(studentInfoActions.saveStudentSchoolInfo(newSchoolInfo));
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  const onEtablissementHandler = (text: SchoolInfoInterface) => {
    const newSchoolInfo: StudentSchoolInfo = {
      ...schoolInfo,
      schoolPostCode: text.zip,
      schoolCity: text.commune,
      schoolName: text.name,
    };
    setcities(text.commune);
    setzip(text.zip);
    setschool(text.name);

    dispatch(studentInfoActions.saveStudentSchoolInfo(newSchoolInfo));
    dispatch(studentInfoActions.resetRequestApiState());
    setetab([]);
  };

  const handleChangeSchoolInfo = (event: ChangeEvent<HTMLInputElement>) => {
    setschool(event.target.value);
    if (event.target.value.length > 8) {
      setSchoolInfoChars(false);
      if (event.target.value.length % 2) {
        const ecole1: SchoolInfoInterface = {name: '', zip: '', commune: ''};
        const list = [ecole1];
        list.pop();
        axios
          .get(
            `https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-adresse-et-geolocalisation-etablissements-premier-et-second-degre&q=${event.target.value.replaceAll(
              ' ',
              '+',
            )}&facet=appellation_officielle&facet=code_commune&facet=libelle_academie`,
          )
          .then((response) => {
            response.data.records.forEach((element: {fields: any}) => {
              const elt = element.fields;
              const ecole: SchoolInfoInterface = {
                name: elt.appellation_officielle + ' ' + elt.code_commune + ' ' + elt.libelle_commune,
                zip: elt.code_commune,
                commune: elt.libelle_commune,
              };
              list.push(ecole);
            });
            setetab(list);
          });
      }
    } else {
      setSchoolInfoChars(true);
    }
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newStudentInformation: StudentInformation = {
      ...studentInformation,
      [event.target.name]: event.target.value,
    };
    dispatch(studentInfoActions.saveStudentInformation(newStudentInformation));
    dispatch(studentInfoActions.resetRequestApiState());
  };

  const handleToggleChange = (name: string, isChecked: boolean) => {
    if (name === 'acceptCGU') {
      setAcceptCGU(isChecked);
      return;
    }
    if (name === 'studentAcceptOffer') {
      setstudentAcceptOffer(isChecked);
      // return;
    }

    const newStudentInformation: StudentInformation = {
      ...studentInformation,
      [name]: isChecked,
    };

    dispatch(studentInfoActions.saveStudentInformation(newStudentInformation));
    dispatch(studentInfoActions.resetRequestApiState());
  };

  useEffect(() => {
    if (canContinue()) {
      settosub(false);
    }
  }, [
    referent.referentFirstName,
    referent.referentLastName,
    referent.referentEmail,
    referent.referentPhone,
    schoolInfo.schoolPostCode,
    schoolInfo.schoolCity,
    schoolInfo.schoolName,
    studentEligibility.requirementForReunion,
    studentEligibility.motivatedChild,
    studentEligibility.tutoring,
    studentInformation.studentEmail,
    studentInformation.studentFirstName,
    studentInformation.studentLastName,
    studentInformation.studentGender,
    studentInformation.studentBirthDate,
    studentInformation.studentClassNb,
    studentInformation.studentPhone,
    studentInformation.studentAcceptOffer,
    studentInformation.studentAcceptNewsletter,
    studentInformation.knownHomeClasseBy,
    studentInformation.enqueteMentorat,
    StudentStatus.WAITING,
  ]);

  const sendData = () => {
    setclose(false);
    if (canContinue()) {
      settosub(false);
      let studentSubClass = studentInformation.subClassName;
      if (
        studentInformation.studentClassNb !== ClassSchool.premiere &&
        studentInformation.studentClassNb !== ClassSchool.terminale
      ) {
        studentSubClass = '';
      }
      if (isRegistrationForm && !access_token) {
        dispatch(
          createStudent({
            referentFirstName: referent.referentFirstName,
            referentLastName: referent.referentLastName,
            referentEmail: referent.referentEmail ? referent.referentEmail.toLowerCase() : undefined,
            referentPhoneNumber: referent.referentPhone,
            schoolPostalCode: zip ? zip : '00000',
            schoolCity: cities ? cities : 'Paris',
            schoolName: schoolName ? schoolName : 'Inconnu',
            requirementForReunion: studentEligibility.requirementForReunion,
            motivatedChild: studentEligibility.motivatedChild,
            tutoring: studentEligibility.tutoring,
            email: studentInformation.studentEmail.toLowerCase(),
            firstName: studentInformation.studentFirstName,
            lastName: studentInformation.studentLastName,
            gender: studentInformation.studentGender as Gender,
            birthday: studentInformation.studentBirthDate,
            className: studentInformation.studentClassNb,
            subClassName: studentSubClass as string,
            phoneNumber: studentInformation.studentPhone,
            partnerOffersConsent: studentInformation.studentAcceptOffer,
            newsletterConsent: studentInformation.studentAcceptNewsletter,
            knownHomeClasseBy: studentInformation.knownHomeClasseBy,
            enqueteMentorat: studentInformation.enqueteMentorat,
            status: StudentStatus.WAITING,
          }),
        );
      } else {
        dispatch(
          updateStudentInfo({
            id: studentId,
            request: {
              referentFirstName: referent.referentFirstName,
              referentLastName: referent.referentLastName,
              referentEmail: referent.referentEmail ? referent.referentEmail.toLowerCase() : '',
              referentPhoneNumber: referent.referentPhone,
              schoolPostalCode: zip ? zip : '00000',
              schoolCity: cities ? cities : 'Paris',
              schoolName: schoolName ? schoolName : 'Inconnu',
              email: studentInformation.studentEmail.toLowerCase(),
              firstName: studentInformation.studentFirstName,
              lastName: studentInformation.studentLastName,
              gender: studentInformation.studentGender as Gender,
              birthday: studentInformation.studentBirthDate,
              className: studentInformation.studentClassNb?.toString() as string,
              subClassName: studentSubClass?.toString() as string,
              phoneNumber: studentInformation.studentPhone,
              partnerOffersConsent: studentInformation.studentAcceptOffer,
              newsletterConsent: studentInformation.studentAcceptNewsletter,
              knownHomeClasseBy: studentInformation.knownHomeClasseBy,
              enqueteMentorat: studentInformation.enqueteMentorat,
            },
          }),
        );
      }
    } else {
      if (acceptCGU) window.scrollTo({top: 0, behavior: 'smooth'});
      settosub(true);
    }
  };

  const [close, setclose] = useState(false);
  const handleClick = () => {
    setclose(false);
  };
  const handleSubmit = () => {
    // event.preventDefault();
    seterrorApeare(true);
    seterrPhone(true);
    sendData();
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch(studentInfoActions.resetStudentInfoState());
    dispatch(userActions.resetUserState());
    window.location.href = '/registration/student/form';
  };

  const getBirthDayError = (): string => {
    if (studentInformation.studentBirthDate) {
      const birthDate = new Date(studentInformation.studentBirthDate);
      const birthDayMin = new Date(maxBirthdate);
      const birthDayMax = new Date(minBirthdate);

      if (birthDate > birthDayMin) {
        return appText.registrationStudent.informations.error.ageNotInInterval;
      }
      if (birthDate < birthDayMax) {
        return appText.registrationStudent.informations.error.ageNotInInterval;
      }
    }
    if (!studentInformation.studentBirthDate) {
      return appText.registrationStudent.informations.error.ageEmpty;
    }
    return '';
  };

  const canContinue = (): boolean => {
    let subClassSelected = true;
    if (
      studentInformation.studentClassNb === ClassSchool.premiere ||
      studentInformation.studentClassNb === ClassSchool.terminale
    ) {
      subClassSelected = !!studentInformation.subClassName;
    }
    return (
      !!referent.referentFirstName &&
      !!referent.referentLastName &&
      !!referent.referentPhone &&
      !!zip &&
      zip.length == 5 &&
      !!cities &&
      !!schoolInfo &&
      !!studentInformation.studentFirstName &&
      !!studentInformation.studentLastName &&
      !!studentInformation.studentGender &&
      !!studentInformation.studentBirthDate &&
      !!studentInformation.studentClassNb &&
      !!subClassSelected &&
      !!studentInformation.knownHomeClasseBy &&
      !!studentInformation.enqueteMentorat &&
      (isEmailEmpty(studentInformation.studentEmail) ? isEmail(studentInformation.studentEmail) : true) &&
      (isEmailEmpty(referent.referentEmail) ? isEmail(referent.referentEmail) : true) &&
      !getErrorBirth(getBirthDayError()) &&
      isPhoneNumber(referent.referentPhone) &&
      ((isRegistrationForm && acceptCGU) || !isRegistrationForm)
    );
  };
  const isabove500 = useMediaQuery('(max-width: 500px)');

  const btnColor = !isRegistrationForm && isSuccess ? 'clValidate' : 'mainOrange';
  const btnTitle = isRegistrationForm ? 'Suivant' : !isRegistrationForm && isSuccess ? 'Enregistré !' : 'Enregistrer';
  const previous = 'Précédent';
  const previousColor = 'darkcolor';
  const isabove1024 = useMediaQuery('(max-width: 1024px)');

  return (
    <div>
      <div className="flex-1 h-full w-85.75 lg:w-131 mx-auto flex flex-col items-center">
        <div className="w-full p-8 mb-3 bg-clWhite rounded-8px shadow-formRectangle">
          {renderTitle(isRegistrationForm)}
          <div className="flex flex-col lg:flex-row mb-6">
            <div className="flex-1 flex flex-col md:mr-4 mb-6 lg:mb-0 mr-0 lg:mr-4">
              <TextInput
                type="text"
                name="studentLastName"
                displayName="Prénom"
                id="studentLastName"
                mandatory="True"
                value={studentInformation.studentLastName}
                onChange={handleChange}
                // required
                className="w-full lg:w-55.5"
              />
              {!studentInformation.studentLastName && errorApeare && (
                <div className="mt-2 text-clStateError">
                  {appText.registrationStudent.informations.error.firstNameEmpty}
                </div>
              )}
            </div>
            <div className="flex-1 flex flex-col md:mr-4">
              <TextInput
                type="text"
                name="studentFirstName"
                displayName="Nom"
                id="studentFirstName"
                mandatory="True"
                value={studentInformation.studentFirstName}
                onChange={handleChange}
                // required
                className="w-full lg:w-55.5"
              />
              {!studentInformation.studentFirstName && errorApeare && (
                <div className="mt-2 text-clStateError">
                  {appText.registrationStudent.informations.error.lastNameEmpty}
                </div>
              )}
            </div>
          </div>
          <div className="mb-6 flex flex-col lg:flex-row">
            <div className="flex flex-col mr-3">
              <TextInput
                displayName="Date de naissance"
                className="w-50 lg:w-55.5 mx-1"
                type="date"
                max={maxBirthdate}
                min={minBirthdate}
                id="studentBirthDate"
                mandatory="True"
                name="studentBirthDate"
                value={studentInformation.studentBirthDate}
                onChange={handleChange}
                // required
              />
              {getBirthDayError() && errorApeare && <div className="mt-2 text-clStateError">{getBirthDayError()}</div>}
            </div>

            <div onChange={handleChange} className="mx-1">
              <label className="text-base text-greyMedium font-medium">
                Sexe
                <span className="mandatory-star">*</span>
              </label>
              <div className="flex flex-row items-center mt-4 mb-5">
                <RadioButton
                  name="studentGender"
                  value={Gender.HOMME}
                  checked={studentInformation.studentGender === Gender.HOMME}
                  // required
                />
                <RadioButton
                  className="ml-4"
                  name="studentGender"
                  value={Gender.FEMME}
                  checked={studentInformation.studentGender === Gender.FEMME}
                  // required
                />
              </div>
              {!studentInformation.studentGender && errorApeare && (
                <div className="mb-2 text-clStateError">
                  {appText.registrationStudent.informations.error.genderEmpty}
                </div>
              )}
            </div>
          </div>
          <TextInput
            type="text"
            name="schoolName"
            displayName="Nom de l’établissement scolaire"
            id="schoolName"
            mandatory="True"
            value={schoolName ?? schoolInfo.schoolName}
            onChange={handleChangeSchoolInfo}
            className="w-full mb-2"
          />
          {etablissement && (
            <div className="suggestion_box">
              {etablissement.map((etablissement, i) => (
                <div
                  key={i}
                  className="suggestion col-md-12 justify-content-md-center"
                  onClick={() => onEtablissementHandler(etablissement)}>
                  {etablissement.name}
                </div>
              ))}
            </div>
          )}
          {!schoolInfo.schoolName && errorApeare && (
            <div className="mb-6 text-clStateError">
              {appText.registrationStudent.informations.error.schoolNameEmpty}
            </div>
          )}
          {schoolInfoChars && (
            <div className="mb-6 text-clStateError">
              {appText.registrationStudent.informations.error.schoolNameIncomplete}
            </div>
          )}
          <div className="flex flex-col lg:flex-row mb-6 mt-4">
            <div className="flex-1 flex flex-col md:mr-4 mb-6 lg:mb-0 mr-0 lg:mr-4">
              {(zip !== undefined || schoolInfo.schoolPostCode) && (
                <ZipCodeInput
                  name="schoolPostCode"
                  displayName="Code postal"
                  id="schoolPostCode"
                  mandatory="True"
                  value={zip ?? schoolInfo.schoolPostCode}
                  errorApeare={errorApeare}
                  className="w-full lg:w-55.5"
                  readOnly
                />
              )}
              {zip !== undefined && !schoolInfo.schoolPostCode && errorApeare && (
                <div className="mt-2 text-clStateError">
                  {appText.registrationStudent.informations.error.postalCodeEmpty}
                </div>
              )}
            </div>
            <div className="flex-1 flex flex-col md:mr-4">
              {(cities !== undefined || schoolInfo.schoolCity) && (
                <TextInput
                  displayName="Ville"
                  name="schoolCity"
                  id="schoolCity"
                  mandatory="True"
                  className="w-full lg:w-55.5"
                  value={cities ?? schoolInfo.schoolCity}
                  readOnly
                />
              )}
              {cities !== undefined && !schoolInfo.schoolCity && errorApeare && (
                <div className="mt-2 text-clStateError">
                  {appText.registrationStudent.informations.error.cityNameEmpty}
                </div>
              )}
              {!schoolInfo.schoolPostCode && clickcity && !schoolInfo.schoolCity && (
                <div className="mt-2 text-clStateError">Veuillez saisir un code postal au préalable.</div>
              )}
            </div>
          </div>

          <div className="mb-6">
            <SelectInput
              label="Classe"
              name="studentClassNb"
              id="studentClassNb"
              mandatory="True"
              defaultEmptyValueTitle="--Choisissez votre classe--"
              options={Object.values(ClassSchool)}
              onChange={handleSelectChange}
              className="w-full"
              value={studentInformation.studentClassNb}
              // required
            />
            {!studentInformation.studentClassNb && errorApeare && (
              <div className="mt-2 mb-2 text-clStateError">
                {appText.registrationStudent.informations.error.studentClassNbEmpty}
              </div>
            )}
          </div>
          {(studentInformation.studentClassNb === ClassSchool.premiere ||
            studentInformation.studentClassNb === ClassSchool.terminale) && (
            <div className="mb-6">
              <SelectInput
                label="Spécialité"
                name="subClassName"
                id="subClassName"
                mandatory="True"
                defaultEmptyValueTitle="-- Choisissez votre spécialité --"
                options={Object.values(subClass)}
                onChange={handleSelectChange}
                className="w-full"
                value={studentInformation.subClassName as string}
              />
              {!studentInformation.subClassName && errorApeare && (
                <div className="mt-2 mb-2 text-clStateError">
                  {appText.registrationStudent.informations.error.subClassNameEmpty}
                </div>
              )}
            </div>
          )}
          <div className="mb-3">
            <EmailInput
              type="email"
              disabled={!isRegistrationForm}
              name="studentEmail"
              displayName={"Adresse e-mail de l'élève ou parent"}
              id="studentEmail"
              mandatory="True"
              value={studentInformation.studentEmail}
              onChange={handleChange}
              errorApeare={errorApeare}
              className="w-full"
              seterr={seterr}
            />
            {(!isEmailEmpty(studentInformation.studentEmail) || !isEmail(studentInformation.studentEmail)) &&
            errorApeare &&
            err ? (
              <div className="mt-2 mb-2 text-clStateError">
                {appText.registrationStudent.informations.error.emailStudentNotValid}
              </div>
            ) : errorMsg ==
              `Une erreur est survenue. Merci de renseigner les bonnes informations. L'email ${studentInformation.studentEmail} est déjà enregistrée dans notre base.` ? (
              <div className="mb-4 text-clStateError">
                L&apos;utilisateur {studentInformation.studentEmail} est déjà enregistré dans notre base, merci de
                renseigner un autre email ou de nous contacter sur homeclasse@zupdeco.org.
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="w-full p-8 mb-3 bg-clWhite rounded-8px shadow-formRectangle">
          <h2 className="mb-2 text-2xl text-greyMedium font-bold">Informations parent / responsable</h2>
          <div className="flex flex-col lg:flex-row mb-6">
            <div className="felx flex-col">
              <TextInput
                type="text"
                name="referentFirstName"
                displayName="Prénom"
                mandatory="True"
                id="referentFirstName"
                value={referent.referentFirstName}
                onChange={handleChangeStudentReferent}
                // required
                className="w-full lg:w-55.5 mb-6 lg:mb-0 mr-0 lg:mr-4"
              />
              {!referent.referentFirstName && errorApeare && (
                <div className="mt-2 text-clStateError">
                  {appText.registrationStudent.informations.error.firstNameEmpty}
                </div>
              )}
            </div>
            <div className="felx flex-col">
              <TextInput
                type="text"
                name="referentLastName"
                displayName="Nom"
                id="referentLastName"
                mandatory="True"
                value={referent.referentLastName}
                onChange={handleChangeStudentReferent}
                // required
                className="w-full lg:w-55.5"
              />
              {!referent.referentLastName && errorApeare && (
                <div className="mt-2 text-clStateError">
                  {appText.registrationStudent.informations.error.lastNameEmpty}
                </div>
              )}
            </div>
          </div>
          <div className="felx flex-col mb-6">
            <EmailInput
              type="email"
              disabled={!isRegistrationForm}
              name="referentEmail"
              displayName="Adresse e-mail"
              id="referentEmail"
              value={referent.referentEmail}
              onChange={handleChangeStudentReferent}
              className="w-full"
              errorApeare={errorApeare}
              seterr={setreferr}
            />
          </div>
          <div className="flex flex-col mb-6">
            <PhoneInput
              name="referentPhone"
              displayName="Téléphone"
              id="referentPhone"
              value={referent.referentPhone}
              onChange={handleChangeStudentReferent}
              prefix="+33"
              mandatory="True"
              // required
              className="w-full"
              errorApeare={errorApeare}
              seterr={seterrPhone}
            />
            {!isPhoneNumber(referent.referentPhone) && errPhone && errorApeare && (
              <div className="mt-2 mb-2 text-clStateError">
                <span>{appText.registrationStudent.informations.error.phoneNotValid}</span>
                <br />
                <span>{appText.registrationStudent.informations.error.incompletePhoneNum}</span>
              </div>
            )}
          </div>
          <div className="mb-3">
            <SelectInput
              label="Comment avez-vous eu connaissance d'HomeClasse ?"
              mandatory="True"
              name="knownHomeClasseBy"
              id="knownHomeClasseBy"
              defaultEmptyValueTitle="-- Veuillez choisir dans la liste --"
              options={Object.values(MediaStudent)}
              value={studentInformation.knownHomeClasseBy}
              onChange={handleSelectChange}
              className="w-full"
            />
            {!studentInformation.knownHomeClasseBy && errorApeare && (
              <div className="mt-2 text-clStateError">
                {appText.registrationStudent.informations.error.knownHomeClasseByEmpty}
              </div>
            )}
          </div>
        </div>
        {isRegistrationForm && (
          <>
            <div className="w-full p-8 mb-3 bg-clWhite rounded-8px shadow-formRectangle">
              <h2 className="mb-2 text-2xl text-greyMedium font-bold">Enquête sur le mentorat</h2>
              <div className="flex flex-col mb-4">
                <span className="text-greyMedium font-medium text-base mb-1">
                  J’accepte d’être recontacté pour participer à une enquête sur le mentorat.
                  <span className="mandatory-star">*</span>
                </span>
                <div className="flex mt-2" onChange={handleChange}>
                  <RadioButton
                    value={OptionYesNo.OUI}
                    className="mr-4"
                    name="enqueteMentorat"
                    checked={studentInformation.enqueteMentorat === OptionYesNo.OUI}
                    required
                  />
                  <RadioButton
                    value={OptionYesNo.NON}
                    name="enqueteMentorat"
                    checked={studentInformation.enqueteMentorat === OptionYesNo.NON}
                    required
                  />
                </div>
              </div>
              {!studentInformation.enqueteMentorat && errorApeare && (
                <div className="mt-2 text-clStateError">
                  {appText.registrationStudent.informations.error.enqueteMentoratEmpty}
                </div>
              )}
            </div>
            <div className="w-full p-8 mb-21.75 bg-clWhite rounded-8px shadow-formRectangle">
              <h2 className="mb-2 text-2xl text-greyMedium font-bold">Conditions générales</h2>
              <div className="flex flex-row mb-4">
                <div className="flex mr-3 items-center">
                  <ToggleSwitch
                    isChecked={acceptCGU}
                    // required
                    onChange={(isChecked: boolean) => handleToggleChange('acceptCGU', isChecked)}
                  />
                </div>
                <label className="align-middle text-greyDark text-base">
                  En remplissant ce formulaire, j’accepte{' '}
                  <Link
                    className="inhover"
                    target="_blank"
                    to={{pathname: 'https://zupdeco.homeclasse.org/confidentialite/'}}>
                    les conditions générales et la politique de confidentialité de Homeclasse
                  </Link>
                  .
                </label>
              </div>
            </div>
          </>
        )}
      </div>
      {!acceptCGU && (
        <div className="mb-4 text-center text-clStateError">
          {appText.registrationStudent.informations.error.cguNotAccept}
        </div>
      )}
      {tosub && <div className="mb-4 text-center text-clStateError">Veuillez remplir tous les champs obligatoires</div>}
      {errorMsg !=
      `Une erreur est survenue. Merci de renseigner les bonnes informations. L'email ${studentInformation.studentEmail} est déjà enregistrée dans notre base.` ? (
        <div className="mb-4 text-center text-clStateError" dangerouslySetInnerHTML={{__html: errorMsg}} />
      ) : (
        <></>
      )}
      <div className="flex justify-center" style={{width: '100%'}}>
        {!isLoading &&
          (isRegistrationForm ? (
            <>
              <button
                onClick={goToPreviousStep}
                className="w-85.75 lg:w-64 mt-auto mr-1 py-3 text-xl text-clWhite text-center font-medium rounded-8px disabled:bg-greyMedium disabled:opacity-16 bg-darkcolor">
                {previous}
              </button>
              <Button
                className="w-85.75 lg:w-64 mt-auto ml-1"
                color={btnColor}
                onClick={handleSubmit}
                isRegistrationForm>
                {btnTitle}
              </Button>
            </>
          ) : (
            <Button
              className="w-85.75 lg:w-115 mt-auto mr-1"
              color={btnColor}
              onClick={handleSubmit}
              isRegistrationForm>
              {btnTitle}
            </Button>
          ))}
      </div>
      <div className="flex justify-around" style={{width: '100%'}}>
        {isLoading && <Spinner className="mx-auto" />}
      </div>
      {!isRegistrationForm && <Homelink />}
      <ReactModal
        className="absolute w-85.75 md:w-auto top-40 inset-x-center-modal-mobile md:inset-x-1/3 p-8 bg-clWhite rounded-8px border-none overflow-auto outline-none"
        overlayClassName="fixed inset-0 bg-greyDark bg-opacity-50 overflow-auto z-50"
        isOpen={close}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}>
        <div>
          <div className="flex flex-col text-greyMedium" style={{alignItems: 'center'}}>
            <h3 className="my-2 text-clStateError" style={{fontSize: 'revert'}}>
              Attention
            </h3>
            <h4 className="mt-2 mb-4 text-greyMedium" style={{fontSize: 'revert'}}>
              vous êtes déjà connecté si vous souhaitez créer un nouveau compte veuillez sélectionner déconnexion si
              vous souhaitez mettre à jour vos informations veuillez sélectionner continuer
            </h4>
            <div className="flex justify-around relative" style={{width: '100%'}}>
              <button
                className="close bg-mainOrange rounded w-27.5"
                style={{padding: '0.7rem 0', color: 'white'}}
                onClick={sendData}>
                Continuer
              </button>
              <button
                className="close bg-mainOrange rounded w-27.5"
                style={{padding: '0.7rem 0', color: 'white'}}
                onClick={handleLogout}>
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
